import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { StoryTelling } from "../../components/Storytelling";
import {
  requestAllPages,
  requestPageById,
  requestPageWidgets,
  requestPublicPageById,
  requestPublicPageWidgets,
  requestAllPublicPages,
} from "../../store/slices/projectPages";
import {
  getAllPages,
  getCurrentPageWidgetsItems,
  getCurrentProjectData,
  getLayout,
  getPageSettings,
} from "../../store/selectors/projects";
import {
  requestCreateWidgets,
  requestWidgetsSuggestions,
} from "../../store/slices/widgets";
import { getIsPublicMode } from "../../store/selectors/main";
import { Layout } from "../../components/ProjectPageLayouts/Layout";
import { LAYOUTS } from "../../components/ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../helpers/firstCommonElement";
import { adjustArray } from "../../helpers/ajustArray";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../components/Widgets/LineChart/utils/getLineChartMarkers";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../components/Widgets/Lollipop/utils/getLollipopChartMarkers";
import { getBarChartFormatting } from "../../components/Widgets/GroupedBarChart/utils/getKeys";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../components/Widgets/AreaChart/utils/getGroupData";
import { defaultApparitionConfig, defaultNewWidget } from "./constants";
import { extractBlockIds, LayoutI } from "../../helpers/extractBlockIds";
import { Layout5 } from "../../components/ProjectPageLayouts/Layout5";
import { PageSettingsDTO } from "../../models/Pages";
import TablePage from "../../components/TypePages/TablePage";
import {
  CreateAreaChartWidgetDto,
  CreateBarWidgetDto,
  CreateBubbleWidgetDto,
  CreateLineChartWidgetDto,
  CreateSparkLineChartWidgetDto,
  CreateSparkAreaChartWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateMatrixChartWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateSankeyChartWidgetDto,
  CreateScatterplotWidgetDto,
} from "../../models/Widgets";
import LayoutRealEstate from "../../components/ProjectPageLayouts/LayoutRealEstate";
import { getSparkLineChartColors } from "../../components/Widgets/SparkLineChart/utils/getSparkLineChartColors";
import { getSparkAreaChartColors } from "../../components/Widgets/SparkAreaChart/utils/getSparkAreaChartColors";
import NoDataWarning from "../../components/Modals/SelectedDatasetOverview/components/NoDataWarning";
import { validateDataset } from "../../api/projects";
import { setActiveModal } from "../../store/slices/modals";
import {
  requestGetAllDatasets,
  setDatasetPreview,
  setNewProjectDetails,
  setNewProjectSelectedFiles,
  setNewProjectSelectedFilesColumns,
  updateSelectedFiles,
} from "../../store/slices/datasets";
import { FileLibraryItem } from "../../models/Files";
import { DatasetsResponseListDto } from "../../models/Datasets";
import { Loader } from "../../components/Loader";

interface IProjectPage {
  downloadScreenshot?: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const ProjectPage = ({
  downloadScreenshot = () => { },
}: IProjectPage) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const { id: queryId, pageId: queryPageId } = useParams();

  const pages = useSelector(getAllPages);
  const templateId = useSelector(getLayout);
  const currentPage = useSelector(getPageSettings);
  const widgets = useSelector(getCurrentPageWidgetsItems);

  const { datasets, projectType, datasetColumnKeys, name, description } =
    useSelector(getCurrentProjectData);

  const [warningNoData, setWarningNoData] = useState<string | null>(null);

  const pageItems = useMemo(() => pages?.items, [pages?.items]);
  const pageId = useMemo(() => queryPageId, [queryPageId]);
  const id = useMemo(() => queryId, [queryId]);

  useEffect(() => {
    if (isPublicRoute) {
      dispatch(requestAllPublicPages({ projectId: id }));
    } else {
      dispatch(requestAllPages({ projectId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (typeof isPublicRoute === "undefined" || !pageId) {
      return;
    }

    if (isPublicRoute) {
      if (!!pageItems?.find((page) => page.id === pageId)) {
        dispatch(requestPublicPageById({ id: pageId }));
        dispatch(
          requestPublicPageWidgets({ pageId: pageId, includeData: true })
        );
      } else if (pageItems?.length) {
        navigate("/projects/public/" + id + "/" + pageItems[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute, pageItems]);

  // useEffect(() => {
  //   if (!isPublicRoute && pageId) {
  //     dispatch(requestPageById({ id: pageId }));
  //     dispatch(requestPageWidgets({ pageId, includeData: true }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id, pageId, isPublicRoute]);

  const getPageWidgetsRequest = (page: PageSettingsDTO, suggestions: any) => {
    dispatch(
      requestPageWidgets({
        pageId: page.id!,
        includeData: true,
        callbacks: {
          onSuccess: (widgetItems) => {
            try {
              if (!widgetItems?.count && page.dashType === "storytelling") {
                let barChartRequestData: any[] = [];
                let lineChartRequestData = [];
                let sparkLineChartRequestData = [];
                let sparkAreaChartRequestData = [];
                let lollipopChartRequestData = [];
                let sankeyChartRequestData = [];
                let mapChartRequestData = [];
                let areaChartRequestData = [];
                let matrixChartRequestData = [];
                let polarAreaChartData = [];
                let punchcardChartData = [];
                let radarChartData = [];
                let bubbleChartData = [];
                let scatterplotChartData = [];

                let suggestionsCopy = [...suggestions];
                let chartTypes = suggestionsCopy.map((r: any) => r.chartType);
                for (
                  let i = 2;
                  i < (suggestions.length > 8 ? 10 : suggestions.length + 2);
                  i++
                ) {
                  const chartType =
                    suggestions[i - 2] && suggestions[i - 2].chartType;
                  if (chartType) {
                    const index = chartTypes.findIndex(
                      (c: string) => c === chartType
                    );
                    if (index !== -1) {
                      chartTypes.splice(index, 1);
                    }
                    const chart = suggestionsCopy?.find(
                      (widget: any) => widget.chartType === chartType
                    );
                    if (chart) {
                      const indexSuggestion = suggestionsCopy?.findIndex(
                        (widget: any) => widget.chartType === chartType
                      );
                      if (indexSuggestion !== -1) {
                        suggestionsCopy?.splice(indexSuggestion, 1);
                      }
                      switch (chart?.chartType) {
                        case "mapChart":
                          mapChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i.toString(),
                            display: [],
                            arrangeBy: [],
                            datasetId: chart?.layers[0]?.datasetId,
                            styleId: chart.styleId || "default",
                            dataFormat: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "areaChart":
                          areaChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "lineChart":
                          lineChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "sparkLineChart":
                          sparkLineChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "sparkAreaChart":
                          sparkAreaChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "barChart":
                          barChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "lollipopChart":
                          lollipopChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                          });
                          break;
                        case "sankeyChart":
                          const arrangeBy = [
                            ...(chart?.groupBy || []),
                            ...(chart?.xAxe || []),
                          ];
                          sankeyChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            display: chart.display || chart.yAxe || [],
                            arrangeBy:
                              chart?.arrangeBy && chart?.arrangeBy?.length
                                ? chart.arrangeBy
                                : arrangeBy?.filter((r) => !!r),
                            palette: {
                              type: "sequential",
                              colorType: "discrete",
                              domain: ["Year", "Value"],
                              range: ["red", "blue"],
                              reverse: false,
                            },
                          });
                          break;
                        case "matrixChart":
                          matrixChartRequestData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            palette: {
                              type: "sequential",
                              colorType: "discrete",
                              domain: ["Year", "Value"],
                              range: ["red", "blue"],
                              reverse: false,
                            },
                            xAxe: chart?.xAxe || chart?.arrangeBy || [],
                            yAxe: chart?.yAxe || chart?.display || [],
                          });
                          break;
                        case "polarAreaChart":
                          polarAreaChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "radarChart":
                          radarChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "punchcardChart":
                          punchcardChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "scatterplotChart":
                          scatterplotChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "bubbleChart":
                          bubbleChartData.push({
                            ...chart,
                            ...{
                              ...defaultNewWidget,
                              apparitionConfig: {
                                ...defaultApparitionConfig,
                                textAlignment: i % 2 !== 0 ? "right" : "left",
                              },
                            },
                            description:
                              chart?.description ||
                              defaultNewWidget.description,
                            insights:
                              chart?.insights || defaultNewWidget.insights,
                            blockId: i?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || chart?.arrangeBy || [],
                            yAxe: chart?.yAxe || chart?.display || [],
                          });
                          break;
                        default:
                      }
                    }
                  }
                }

                const existCharts =
                  barChartRequestData?.length +
                  lineChartRequestData?.length +
                  sparkLineChartRequestData?.length +
                  sparkAreaChartRequestData?.length +
                  lollipopChartRequestData?.length +
                  mapChartRequestData?.length +
                  sankeyChartRequestData?.length +
                  areaChartRequestData?.length +
                  matrixChartRequestData?.length +
                  polarAreaChartData?.length +
                  radarChartData?.length +
                  scatterplotChartData?.length +
                  punchcardChartData?.length +
                  bubbleChartData?.length;

                if (existCharts > 0) {
                  dispatch(
                    requestCreateWidgets({
                      barChart: barChartRequestData,
                      lineChart: lineChartRequestData,
                      sparkLineChart: sparkLineChartRequestData,
                      sparkAreaChart: sparkAreaChartRequestData,
                      lollipopChart: lollipopChartRequestData,
                      mapChart: mapChartRequestData,
                      sankeyChart: sankeyChartRequestData,
                      areaChart: areaChartRequestData,
                      matrixChart: matrixChartRequestData,
                      radarChart: radarChartData,
                      scatterplotChart: scatterplotChartData,
                      polarAreaChart: polarAreaChartData,
                      punchcardChart: punchcardChartData,
                      bubbleChart: bubbleChartData,
                      pageId,
                      callbacks: {
                        onSuccess: () => {
                          dispatch(
                            requestWidgetsSuggestions({
                              pageId,
                              projectId: id!,
                              includeData: false,
                            })
                          );
                        },
                      },
                    })
                  );
                }
              }
              if (!widgetItems?.count && page?.dashType === "dashboard") {
                const layout = LAYOUTS.find((l) => l.id === templateId);
                let blocks: any = extractBlockIds(layout?.arranging as LayoutI);

                blocks = adjustArray(blocks, layout?.numWidgets || 1);
                let suggestionsCopy = [...suggestions];

                let barChartRequestData: CreateBarWidgetDto[] = [];
                let lineChartRequestData: CreateLineChartWidgetDto[] = [];
                let sparkLineChartRequestData: CreateSparkLineChartWidgetDto[] =
                  [];
                let sparkAreaChartRequestData: CreateSparkAreaChartWidgetDto[] =
                  [];
                let lollipopChartRequestData: CreateLollipopChartWidgetDto[] =
                  [];
                let sankeyChartRequestData: CreateSankeyChartWidgetDto[] = [];
                let areaChartRequestData: CreateAreaChartWidgetDto[] = [];
                let matrixChartRequestData: CreateMatrixChartWidgetDto[] = [];
                let mapChartRequestData: CreateMapWidgetDto[] = [];
                let scatterplotChartRequestData: CreateScatterplotWidgetDto[] =
                  [];
                let radarChartRequestData: CreateRadarWidgetDto[] = [];
                let punchcardChartRequestData: CreatePunchcardWidgetDto[] = [];
                let polarAreaChartRequestData: CreatePolarAreaWidgetDto[] = [];
                let bubbleChartRequestData: CreateBubbleWidgetDto[] = [];

                let chartTypes = suggestions.map((r: any) => r.chartType);
                for (let i = 0; i < blocks?.length; i++) {
                  const block = blocks[i];
                  const chartType = findFirstCommonElement(
                    chartTypes,
                    block.widgets
                  );
                  const index = chartTypes.findIndex(
                    (c: string) => c === chartType
                  );
                  if (index !== -1) {
                    chartTypes.splice(index, 1);
                  }
                  const chart = suggestionsCopy?.find(
                    (widget: any) => widget.chartType === chartType
                  );
                  if (chart) {
                    const indexSuggestion = suggestionsCopy?.findIndex(
                      (widget: any) => widget.chartType === chartType
                    );
                    if (indexSuggestion !== -1) {
                      suggestionsCopy?.splice(indexSuggestion, 1);
                    }
                    switch (chart?.chartType) {
                      case "mapChart":
                        mapChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          display: [],
                          arrangeBy: [],
                          palette: {},
                          datasetId: chart?.layers[0]?.datasetId,
                          apparitionConfig: {},
                          styleId: chart.styleId || "default",
                          dataFormat: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "areaChart":
                        areaChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: AreaChartDefaultMarkers(chart),
                          formatting: getAreaChartDefaultColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "lineChart":
                        lineChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: getLineChartMarkers(chart),
                          formatting: getLineChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "sparkLineChart":
                        sparkLineChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          formatting: getSparkLineChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "sparkAreaChart":
                        sparkAreaChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          formatting: getSparkAreaChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "barChart":
                        barChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          formatting: getBarChartFormatting(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "lollipopChart":
                        lollipopChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          apparitionConfig: {},
                          markers: getLollipopChartMarkers(chart),
                          formatting: getLollipopChartColors(chart),
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "sankeyChart":
                        const arrangeBy = [
                          ...(chart?.groupBy || []),
                          ...(chart?.xAxe || []),
                        ];

                        sankeyChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          display: chart?.display || chart.yAxe || [],
                          arrangeBy:
                            chart?.arrangeBy && chart?.arrangeBy?.length
                              ? chart.arrangeBy
                              : arrangeBy?.filter((r) => !!r),
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                        });
                        break;
                      case "matrixChart":
                        matrixChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || chart?.arrangeBy || [],
                          yAxe: chart?.yAxe || chart?.display || [],
                        });
                        break;
                      case "polarAreaChart":
                        polarAreaChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "radarChart":
                        radarChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "punchcardChart":
                        punchcardChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "scatterplotChart":
                        scatterplotChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || [],
                          yAxe: chart?.yAxe || [],
                        });
                        break;
                      case "bubbleChart":
                        bubbleChartRequestData.push({
                          ...chart,
                          blockId: block?.blockId?.toString(),
                          dataFormat: {},
                          apparitionConfig: {},
                          uniqueValues: chart?.uniqueValues ?? {},
                          xAxe: chart?.xAxe || chart?.arrangeBy || [],
                          yAxe: chart?.yAxe || chart?.display || [],
                        });
                        break;
                      default:
                    }
                  }
                }

                const existCharts =
                  barChartRequestData?.length +
                  lineChartRequestData?.length +
                  sparkLineChartRequestData?.length +
                  sparkAreaChartRequestData?.length +
                  lollipopChartRequestData?.length +
                  mapChartRequestData?.length +
                  sankeyChartRequestData?.length +
                  areaChartRequestData?.length +
                  matrixChartRequestData?.length +
                  polarAreaChartRequestData?.length +
                  radarChartRequestData?.length +
                  scatterplotChartRequestData?.length +
                  punchcardChartRequestData?.length +
                  bubbleChartRequestData?.length;

                if (existCharts > 0) {
                  dispatch(
                    requestCreateWidgets({
                      barChart: barChartRequestData,
                      lineChart: lineChartRequestData,
                      sparkLineChart: sparkLineChartRequestData,
                      sparkAreaChart: sparkAreaChartRequestData,
                      lollipopChart: lollipopChartRequestData,
                      mapChart: mapChartRequestData,
                      sankeyChart: sankeyChartRequestData,
                      areaChart: areaChartRequestData,
                      matrixChart: matrixChartRequestData,
                      radarChart: radarChartRequestData,
                      scatterplotChart: scatterplotChartRequestData,
                      polarAreaChart: polarAreaChartRequestData,
                      punchcardChart: punchcardChartRequestData,
                      bubbleChart: bubbleChartRequestData,
                      pageId,
                      callbacks: {
                        onSuccess: () => {
                          dispatch(
                            requestWidgetsSuggestions({
                              pageId,
                              projectId: id!,
                              includeData: false,
                            })
                          );
                        },
                      },
                    })
                  );
                }
              }
            } catch (e) {
              console.error(e);
            }
          },
        },
      })
    );
  };

  useEffect(() => {
    if (id && pageId && !isPublicRoute) {
      dispatch(
        requestPageById({
          id: pageId,
          callbacks: {
            onSuccess: (data) => {
              dispatch(
                requestWidgetsSuggestions({
                  pageId,
                  projectId: id,
                  includeData: true,
                  withStorytelling: false,
                  withKpi: true,
                  callbacks: {
                    onSuccess: (suggestions) => {
                      getPageWidgetsRequest(
                        data,
                        suggestions?.suggested_charts
                      );
                    },
                  },
                })
              );
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute]);

  useEffect(() => {
    if (pageId) {
      if (
        widgets.length > 0 ||
        (datasets?.length > 0 && currentPage.dashType === "table")
      ) {
        const delayedScreenshot = async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          downloadScreenshot(ref);
        };

        delayedScreenshot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets?.length, pageId, datasets?.length]);

  const fetchValidation = useCallback(async () => {
    const firstDatasetId = datasets?.[0]?.sourceId;
    if (!firstDatasetId) return;

    const data = await validateDataset({
      datasetId: firstDatasetId,
      projectType,
      selectedColumns: datasetColumnKeys,
    });

    if ("response" in data && data.response?.data) {
      const { valid, message } = data.response.data;
      if (!valid) {
        setWarningNoData(message);
      }
    }
  }, [datasets, projectType, datasetColumnKeys]);

  useEffect(() => {
    if (projectType === "real_estate") {
      return;
    }
    fetchValidation();
  }, [datasets, fetchValidation, projectType]);

  const componentToRender = useMemo(() => {
    if (pages.count && projectType === "real_estate") {
      return <LayoutRealEstate key={currentPage.id} />;
    }
    if (
      pages.count &&
      currentPage.dashType === "dashboard" &&
      currentPage.templateId === "2_3_b"
    ) {
      return <Layout5 />;
    } else if (pages.count && currentPage.dashType === "dashboard") {
      return <Layout key={currentPage.id} />;
    } else if (pages.count && currentPage.dashType === "storytelling") {
      return <StoryTelling key={currentPage.id} />;
    } else if (pages.count && currentPage.dashType === "table") {
      return <TablePage key={currentPage.id} />;
    } else {
      return <Loader fullScreen />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pages.count]);

  const addData = () => {
    dispatch(
      requestGetAllDatasets({
        limit: 1000,
        callbacks: {
          onSuccess(data) {
            const allDatasets = data as DatasetsResponseListDto;
            const datasetId = (datasets && datasets.at(0)?.sourceId) ?? "";

            const fields = allDatasets.items.find(
              (item) => item.id === datasetId
            )?.fields;

            const selectedFiles = [
              {
                id: datasetId,
                fields: fields!,
              },
            ];

            dispatch(
              updateSelectedFiles({
                id: datasetId,
                fields: fields!,
              })
            );

            dispatch(
              setNewProjectDetails({
                name,
                description,
                projectType,
              })
            );

            const newProjectSelectedDatasets = allDatasets.items
              .filter((dataset) =>
                [datasetId].includes(dataset.id || dataset.sourceId || "")
              )
              .map((dataset) => {
                const primaryFields = dataset.fields
                  ?.filter((item) => item.primary)
                  .map((item) => item.key);

                const selectedDataset = selectedFiles.find(
                  (file) => file.id === dataset.id
                );
                if (selectedDataset) {
                  const selectedHeaders = selectedDataset.fields.map((item) => {
                    return {
                      ...item,
                      primary: !!primaryFields?.includes(item.key),
                    };
                  });

                  return {
                    ...dataset,
                    fields: selectedHeaders,
                  };
                }
                return null;
              })
              .filter((dataset) => dataset !== null);

            dispatch(
              setNewProjectSelectedFiles(
                newProjectSelectedDatasets.map((item) => {
                  return {
                    name: item?.name,
                    filePath: item?.filePath,
                    fields: item?.fields,
                    sourceId: item?.id,
                    source: item?.source,
                    fieldsAi: item?.fieldsAi,
                    descriptionAi: item?.descriptionAi,
                    sourceType: item?.sourceType,
                  };
                }) as FileLibraryItem[]
              )
            );

            dispatch(
              setNewProjectSelectedFilesColumns(
                newProjectSelectedDatasets.map((item) => {
                  return {
                    name: item?.name,
                    filePath: item?.filePath,
                    fields: item?.fields,
                    sourceId: item?.id,
                    source: item?.source,
                    fieldsAi: item?.fieldsAi,
                    descriptionAi: item?.descriptionAi,
                    sourceType: item?.sourceType,
                  };
                }) as FileLibraryItem[]
              )
            );

            dispatch(setDatasetPreview(newProjectSelectedDatasets[0]));

            dispatch(
              setActiveModal({
                id: "selectedDatasetOverviewModal",
                data: {
                  deleteAllOnClose: true,
                  closeWarning: setWarningNoData,
                },
              })
            );
          },
        },
      })
    );
  };

  return (
    <>
      {/* {loading ? <Loader blur={false} /> : null} */}

      {warningNoData ? (
        <NoDataWarning
          title={"Insufficient Data"}
          description={[
            "The system is unable to generate a comprehensive analytical dashboard due to the limited amount of data provided. To enhance your dashboard, consider adding more data or connecting to public data sources.",
            "To enhance your dashboard, consider adding more data or connecting to public data sources.",
          ]}
          close={() => setWarningNoData(null)}
          addData={addData}
        />
      ) : (
        <div className="test" ref={ref}>
          {componentToRender}
        </div>
      )}
    </>
  );
};
