import { ArrowLeft, XLg } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SearchDataPageByIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 0;
  min-height: calc(100dvh - 64px);
`;

export const SearchDataPageByIdHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const TemporarBackButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-right: auto;
`

export const TemporarBackButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #5D63F6;  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export const SearchDataPageHeaderByIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SearchDataPageHeaderByIdTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
  display: flex;
  gap: 4px; 
  align-items: center;
`

export const ArrowLeftButton = styled(ArrowLeft)`
  font-size: 24px;
  cursor: pointer;
`

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonsSearchData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const SearchDataPageByIdContentWrapper = styled.div<{ $showDataset: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .15s all;
  max-width: ${({ $showDataset }) => $showDataset ? "480px" : "560px"};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`
export const SearchDataPageByIdContentWrapperFullWidth = styled.div<{ $showDataset: boolean }>`
  display: flex;
  transition: .15s all;
  justify-content: center;
  min-width: ${({ $showDataset }) => $showDataset ? "480px" : "100%"};
`

export const FullContentFlexWrapper = styled.div`
  display: flex;
  transition: .15s all;
  width: 100%;
  height: calc(100dvh - 190px);
  gap:16px;
`
export const DatasetListWrapper = styled.div<{ $showDatasetData: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ $showDatasetData }) => $showDatasetData ? "480px" : "100%"};
  transition: .15s all;
  width: 100%;
  height: calc(100dvh - 188px);
  border: 1px solid #DFE1E4;
  border-radius: 8px;
`

export const DatasetDataPreviewWrapper = styled.div`
  transition: .15s all;
  width: 100%;
  height: calc(100dvh - 185px);
  overflow-y: auto;
  border: 1px solid #DFE1E4;
  /* padding: 8px; */
  border-radius: 8px;
`

export const DatasetHeader = styled.div<{ $showDatasetData: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  padding-right: ${({ $showDatasetData }) => $showDatasetData ? "68px" : "16px"};
`
export const DatasetHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000417;
  margin-right: auto;
`

export const ContentHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 18px 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`

export const ContentHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.neutralText};
  margin-bottom: 10px;
`
export const CloseIcon = styled(XLg)`
  position: absolute;
  right: 16px;
  top: 12px;
  background: #9FA4AD1F;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
`

export const ContentHeaderSubTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-bottom: 8px;
`

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  overflow-y: auto;
`

export const ContentBodyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ContentBodyHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`

export const ContentBodyHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 100%;
  display: flex;
`

export const ContentBodyData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentBodyDataTopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

export const YearChip = styled.div<{ $disabled: boolean }>`
  display: flex;
  padding: 8px;
  border-radius: 100px;
  background: #EFF0F1;
  border: 1px solid #DFE1E4;
  font-size: 12px;
  line-height: 16px;
  transition: .15s all;
  opacity: ${({ $disabled }) => $disabled ? "0.8" : "1"};
  cursor: ${({ $disabled }) => $disabled ? "default" : "pointer"};
  &:hover {
    background: ${({ $disabled }) => $disabled ? "#EFF0F1" : "#DFE1E4"};
    border: 1px solid ${({ $disabled }) => $disabled ? "#DFE1E4" : "#CFD2D6"};
  }
`

export const YearChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  gap: 8px;
`

export const Red = styled.div`
  color: red;
`

export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  gap: 8px;
`

export const Chip = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 100px;
  color: #000417;
  background: ${({ $selected }) => $selected ? "#6F6FE93D" : "#EFF0F1"};
  border: 1px solid ${({ $selected }) => $selected ? "#7D82F8" : "#DFE1E4"};
  font-size: 12px;
  line-height: 16px;
  transition: .15s all;
  cursor: pointer;
  &:hover {
    background: #DFE1E4;
    border: 1px solid #CFD2D6;
  }
`
