import { ReactComponent as MetricValueIcon } from "../../../assets/dimensionIcons/metric-value.svg";
import { ReactComponent as MetricValueGrayIcon } from "../../../assets/dimensionIcons/metric-valueGray.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/dimensionIcons/category.svg";
import { ReactComponent as CategoryGrayIcon } from "../../../assets/dimensionIcons/categoryGray.svg";
import { ReactComponent as DateTimeIcon } from "../../../assets/dimensionIcons/date-time.svg";
import { ReactComponent as DateTimeGrayIcon } from "../../../assets/dimensionIcons/date-timeGray.svg";
import { ReactComponent as LocationIcon } from "../../../assets/dimensionIcons/location.svg";
import { ReactComponent as LocationGrayIcon } from "../../../assets/dimensionIcons/locationGray.svg";
import { ReactComponent as TextGrayIcon } from "../../../assets/dimensionIcons/textGray.svg";
import { ReactComponent as TextIcon } from "../../../assets/dimensionIcons/text.svg";
import { ReactComponent as MetaIcon } from "../../../assets/dimensionIcons/meta.svg";
import { ReactComponent as MetaGrayIcon } from "../../../assets/dimensionIcons/metaGray.svg";
import { ISelect } from "../../Inputs/CustomSelect/types";

import dataJson from "./map_type_subtype.json";

export const settingsItemsData = [
  {
    type: "categorical",
    label: "Category",
    icon: <CategoryIcon />,
    grayIcon: <CategoryGrayIcon />,
  },
  {
    type: "location",
    label: "Location",
    icon: <LocationIcon />,
    grayIcon: <LocationGrayIcon />,
  },
  {
    type: "datetime",
    label: "Date / Time",
    icon: <DateTimeIcon />,
    grayIcon: <DateTimeGrayIcon />,
  },
  {
    type: "metric",
    label: "Metric / Value",
    icon: <MetricValueIcon />,
    grayIcon: <MetricValueGrayIcon />,
  },
  {
    type: "text",
    label: "Text",
    icon: <TextIcon />,
    grayIcon: <TextGrayIcon />,
  },
  {
    type: "meta",
    label: "Meta",
    icon: <MetaIcon />,
    grayIcon: <MetaGrayIcon />,
  },
];

const formatOption = (value: string): string => {
  return value
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const transformedData: {
  [key: string]: { option: string; value: string }[];
} = Object.fromEntries(
  Object.entries(dataJson).map(([key, values]) => [
    key,
    values.map((value: string) => ({
      option: formatOption(value),
      value: value,
    })),
  ])
);

// export const subDimensions: {
//   [key: string]: { option: string; value: string }[];
// } = {
//   location: [
//     { option: "Country", value: "country" },
//     { option: "State", value: "state" },
//     { option: "County", value: "county" },
//     { option: "City", value: "city" },
//     { option: "Village", value: "village" },
//     { option: "ZIP", value: "zip" },
//     { option: "Address", value: "address" },
//     { option: "Longitude", value: "longitude" },
//     { option: "Latitude", value: "latitude" },
//     { option: "Longitude and Latitude", value: "long_lat" },
//   ],
//   datetime: [
//     { option: "Date", value: "date" },
//     { option: "Time", value: "time" },
//     { option: "Timestamp", value: "timestamp" },
//     { option: "Datetime", value: "datetime" },
//     { option: "Season", value: "season" },
//     { option: "Century", value: "century" },
//     { option: "Year", value: "year" },
//     { option: "Month", value: "month" },
//     { option: "Week", value: "week" },
//     { option: "Day of the Month", value: "day" },
//     { option: "Day of the Week", value: "dayOfWeek" },
//     { option: "Day of the Year", value: "dayOfYear" },
//     { option: "Semester", value: "semester" },
//     { option: "Quarter", value: "quarter" },
//     { option: "Quadrimester", value: "quadrimester" },
//     { option: "Hour", value: "hour" },
//     { option: "Minutes", value: "minutes" },
//     { option: "Seconds", value: "seconds" },
//   ],
//   metric: [
//     { option: "Integer", value: "integer" },
//     { option: "Float", value: "float" },
//     { option: "Currency", value: "currency" },
//     { option: "Percentage", value: "percentage" },
//   ],
//   categorical: [
//     { option: "Category", value: "category" },
//     { option: "Status", value: "status" },
//     { option: "Text", value: "text" },
//   ],
//   meta: [
//     { option: "Dimension", value: "dimension" },
//     { option: "Measure Unit", value: "measure_unit" },
//     { option: "Subdivision", value: "subdivision" },
//   ],
//   text: [],
// };

export const getSubDimensions = (key: string): ISelect[] => {
  return transformedData[key] || [];
};
