import React from "react";
import { Barchart } from "./Barchart";
import { SankeyPlot } from "./Sankey";
import { WrapperLineChart } from "./LineChart";
import { LollipopChart } from "./Lollipop";
import { AreaChart } from "./AreaChart";
import { MatrixChart } from "./MatrixChart";
import { WidgetItem } from "../../models/Widgets";
import { UniversalMap } from "./MapBox";
import { RadarChart } from "./RadarChart";
import { PolarAreaChart } from "./PolarAreaChart";
import { PunchcardChart } from "./PunchcardChart";
import { ScatterPlot } from "./ScatterPlot";
import { BubbleChart } from "./BubbleChart";
import { SparkLineChart } from "./SparkLineChart";
import { SparkAreaChart } from "./SparkAreaChart";

export interface WrapperWidget {
  currentWidget: WidgetItem;
  storytelling: boolean;
  recommended: boolean;
  showLegend: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
}

export const WIDGETS: {
  [key: string]: (props: WrapperWidget) => JSX.Element;
} = {
  barChart: (props: WrapperWidget) => <Barchart {...props} />,
  sankey: (props: WrapperWidget) => <SankeyPlot {...props} />,
  lineChart: (props: WrapperWidget) => <WrapperLineChart {...props} />,
  lollipopChart: (props: WrapperWidget) => <LollipopChart {...props} />,
  mapChart: (props: WrapperWidget) => <UniversalMap {...props} />,
  areaChart: (props: WrapperWidget) => <AreaChart {...props} />,
  matrix: (props: WrapperWidget) => <MatrixChart {...props} />,
  radar: (props: WrapperWidget) => <RadarChart {...props} />,
  polarAreaChart: (props: WrapperWidget) => <PolarAreaChart {...props} />,
  punchcardChart: (props: WrapperWidget) => <PunchcardChart {...props} />,
  scatterplot: (props: WrapperWidget) => <ScatterPlot {...props} />,
  bubbleChart: (props: WrapperWidget) => <BubbleChart {...props} />,
  sparkLineChart: (props: WrapperWidget) => <SparkLineChart {...props} />,
  sparkAreaChart: (props: WrapperWidget) => <SparkAreaChart {...props} />,
};
