import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonsWrapper,
  HeaderRow,
  HeadingIcon,
  HeadingText,
  Icon,
  More,
  Primary,
  StarIcon,
  Table,
  TableHeading,
  TableRowCell,
  TableRowDataSet,
  TablesWrapper,
} from "../DatasetsOverview/styles";
import {
  ButtonWrapper,
  Close,
  ContentWrapper,
  FileOverview,
  HeaderItemsWrapper,
  HeaderWrapper,
  Heading,
} from "../UploadedFilePreview/styles";
import {
  requestGetDatasetContents,
  setDatasetPreview,
} from "../../../store/slices/datasets";
import { DimensionSettings } from "../../DimensionSettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import {
  getDatasetContents,
  getSelectedDataset,
} from "../../../store/selectors/datasets";
import { getIconSrc } from "../../../helpers/getIconType";
import { DimensionSettingsInterface } from "../../../models/Files";
import { Button } from "../../Button";
import { DatasetFiledDTO } from "../../../models/Datasets";
import { ILastAction } from "../../../pages/Resources/pages/Datasets/pages/DatasetPreview";
import {
  sortTableAscending,
  sortTableDescending,
} from "../../DimensionSettings/utils/tableActions";
import useMeasure from "react-use-measure";

type DatasetFileOverviewInterface = {
  setLibraryDatasetOverview: Dispatch<SetStateAction<boolean>>;
  dimensionsSelected: any;
};

export const LibraryDatasetOverview = ({
  setLibraryDatasetOverview,
}: DatasetFileOverviewInterface) => {
  const ref = useRef(null);
  const [tableRef, tableBounds] = useMeasure();

  const rowsRef = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  const selectedDataset = useSelector(getSelectedDataset);
  const datasetContents = useSelector(getDatasetContents);

  const [searchInput] = useState<string>("");
  const [limit, setLimit] = useState(30);
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [pinedColumns, setPinedColumns] = useState<string[]>([]);
  const [maxRowHeight, setMaxRowHeight] = useState(0);
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);
  const [selectedDatasetContents, setSelectedDatasetContents] = useState<any>(
    []
  );

  const datasetItems = selectedDataset?.fields?.map((item) => item);

  const filteredItems = datasetItems?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const columnWidth =
    tableBounds.width > 195 * filteredItems?.length!
      ? tableBounds.width / filteredItems?.length!
      : undefined;

  const getDatasetContentsR = (plus?: number) => {
    const count = datasetContents?.count;
    const plusLimit = limit + (plus || 0);
    const newLimit = plusLimit > count ? count : plusLimit;
    dispatch(
      requestGetDatasetContents({
        id: selectedDataset?.id || selectedDataset?.sourceId || "",
        limit: newLimit,
        skip: 0,
      })
    );
    setLimit(newLimit);
  };

  useEffect(() => {
    const heights = rowsRef.current.map((row) => row?.offsetHeight || 0);
    setMaxRowHeight(Math.max(...heights));
  }, [selectedDatasetContents, filteredItems, pinedColumns]);

  useEffect(() => {
    if (selectedDataset?.id!) getDatasetContentsR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDataset]);

  useEffect(() => {
    const newData = datasetContents?.items?.map(
      (contentItem: any) => contentItem
    );

    if (!lastAction || !selectedDatasetContents) {
      return setSelectedDatasetContents(newData);
    }

    if (lastAction.ascending) {
      sortTableAscending({
        key: lastAction.key!,
        subtype: lastAction.subtype || "text",
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else if (lastAction.descending) {
      sortTableDescending({
        key: lastAction.key!,
        subtype: lastAction.subtype || "text",
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else {
      setSelectedDatasetContents(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetContents]);

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  return (
    <ContentWrapper ref={ref}>
      <HeaderWrapper>
        <Heading>
          {selectedDataset?.name.charAt(0).toUpperCase() +
            selectedDataset!.name.slice(1, -4)}
        </Heading>
        <HeaderItemsWrapper>
          {/* <Search
            width="small"
            onChange={(value: string) => setSearchInput(value)}
          />
          <Button
            onClick={() => {
              setLibraryDatasetOverview(false);
              dispatch(requestDatasetRemove(selectedDataset?.id || ""));
              dispatch(removeSelectedFile(selectedDataset?.filePath || ""));
            }}
            variant="danger"
            size="medium"
            name="Remove"
            icon={<Trash fill="#992a4b" />}
          /> */}
        </HeaderItemsWrapper>
        <Close
          onClick={() => {
            setLibraryDatasetOverview(false);
            dispatch(setDatasetPreview({}));
          }}
        />
      </HeaderWrapper>
      {/*key: previewTable - Library Preview Dataset */}
      <FileOverview>
        <TablesWrapper ref={tableRef}>
          {!!pinedColumns.length && (
            <Table $pined={true}>
              {
                <HeaderRow>
                  {datasetItems
                    ?.filter((item) => pinedColumns.includes(item.key))
                    .map((datasetItem: DatasetFiledDTO, idx) => {
                      const iconSrc = getIconSrc({
                        type: datasetItem.type,
                        subtype: "",
                      });

                      return (
                        <TableHeading $width={columnWidth}>
                          {datasetItem.primary && (
                            <Primary>
                              <StarIcon></StarIcon>
                            </Primary>
                          )}
                          <HeadingIcon>
                            <Icon src={iconSrc} />
                          </HeadingIcon>
                          <HeadingText>
                            {datasetItem.label?.charAt(0)?.toUpperCase()! +
                              datasetItem.label?.slice(1)}
                          </HeadingText>
                          <ButtonsWrapper>
                            <More
                              onClick={(e) => {
                                setShowDimensionSettings(
                                  handleColumnClick({
                                    header: datasetItem.label,
                                    event: e,
                                    key: datasetItem.key,
                                    errorsDetected: false,
                                    suggestedType: datasetItem.type,
                                    subtype: datasetItem.subtype,
                                  })
                                );
                              }}
                            />
                          </ButtonsWrapper>
                        </TableHeading>
                      );
                    })}
                </HeaderRow>
              }
              {selectedDatasetContents?.map((item: any, idx: number) => {
                return (
                  <TableRowDataSet
                    key={idx}
                    ref={(el) => (rowsRef.current[idx] = el)}
                    style={{
                      height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                    }}
                  >
                    {filteredItems
                      ?.filter((item) => pinedColumns.includes(item.key))
                      .map((header) => (
                        <TableRowCell $width={columnWidth}>
                          {item[header.key] || "No data"}
                        </TableRowCell>
                      ))}
                  </TableRowDataSet>
                );
              })}
            </Table>
          )}
          <Table>
            {
              <HeaderRow>
                {datasetItems
                  ?.filter((item) => !pinedColumns.includes(item.key))
                  .map((datasetItem: DatasetFiledDTO, idx) => {
                    const iconSrc = getIconSrc({
                      type: datasetItem.type,
                      subtype: "",
                    });

                    return (
                      <TableHeading $width={columnWidth}>
                        {datasetItem.primary && (
                          <Primary>
                            <StarIcon></StarIcon>
                          </Primary>
                        )}
                        <HeadingIcon>
                          <Icon src={iconSrc} />
                        </HeadingIcon>
                        <HeadingText>
                          {datasetItem.label?.charAt(0)?.toUpperCase()! +
                            datasetItem.label?.slice(1)}
                        </HeadingText>
                        <ButtonsWrapper>
                          <More
                            onClick={(e) => {
                              setShowDimensionSettings(
                                handleColumnClick({
                                  header: datasetItem.label,
                                  event: e,
                                  key: datasetItem.key,
                                  errorsDetected: false,
                                  suggestedType: datasetItem.type,
                                  subtype: datasetItem.subtype,
                                })
                              );
                            }}
                          />
                        </ButtonsWrapper>
                      </TableHeading>
                    );
                  })}
              </HeaderRow>
            }

            {selectedDatasetContents?.map((item: any, idx: number) => {
              return (
                <TableRowDataSet
                  key={idx}
                  ref={(el) => (rowsRef.current[idx] = el)}
                  style={{
                    height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                  }}
                >
                  {filteredItems
                    ?.filter((item) => !pinedColumns.includes(item.key))
                    .map((header) => (
                      <TableRowCell $width={columnWidth}>
                        {item[header.key] || "No data"}
                      </TableRowCell>
                    ))}
                </TableRowDataSet>
              );
            })}
          </Table>
        </TablesWrapper>
      </FileOverview>
      <ButtonWrapper>
        <Button
          disabled={datasetContents?.count <= limit}
          onClick={() => {
            getDatasetContentsR(30);
          }}
          size="small"
          variant="primary"
          name="Load More"
        ></Button>
      </ButtonWrapper>

      {showDimensionSettings && (
        <div ref={ref}>
          <DimensionSettings
            filePath={""}
            showDimensionSettings={showDimensionSettings}
            lastAction={lastAction}
            pinedColumns={pinedColumns}
            selectedDatasetContents={selectedDatasetContents}
            setShowDimensionSettings={setShowDimensionSettings}
            setLastAction={setLastAction}
            setPinedColumns={setPinedColumns}
            setSelectedDatasetContents={setSelectedDatasetContents}
            topSpace={60}
          />
        </div>
      )}
    </ContentWrapper>
  );
};
