
import { Check } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalHeaderWrapper,
  ModalTitle,
  ModalWrapper,
  CloseIcon,
  ModalContent,
  ModalContentText,
  ModalFooterWrapper
} from "./styles";
import { Button } from "../../Button";
import LoaderDots from "../ConnectApiModal/components/Loader";
import { ModalOverlay } from "../styles";
import { getDatasetLoading } from "../../../store/selectors/datasets";
import { FinishLoad, FinishLoadWrapper } from "../ConnectApiModal/styles";

import { setSelectedSubTopics } from "../../../store/slices/topics";

export const ProgressModal = ({ onClose }: { onClose: () => void }) => {
  const isLoading = useSelector(getDatasetLoading)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDone = () => {
    dispatch(setSelectedSubTopics(undefined));
    navigate('/resources/public-resources');
    onClose();
  }

  return (
    <ModalOverlay onClick={onClose}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeaderWrapper>
          <ModalTitle>Connect Public Data</ModalTitle>
          <CloseIcon
            onClick={() => {
              onClose();
            }}
          />
        </ModalHeaderWrapper>
        <ModalContent>
          {isLoading ? <LoaderDots disableAbsolute /> : <FinishLoadWrapper><FinishLoad /></FinishLoadWrapper>}
          <ModalContentText>
            {isLoading
              ? <span>
                Establishing a secure connection and retrieving data. <br />Please wait...
              </span>
              : "Connection has been established successfully."
            }
          </ModalContentText>
        </ModalContent>
        <ModalFooterWrapper>
          <Button name="Cancel" onClick={onClose} variant="secondary" size="medium" />
          <Button name="Done" icon={<Check />} disabled={isLoading} onClick={handleDone} variant="secondary" size="medium" />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
