import React, { RefObject, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";

import { ProjectPage } from "../ProjectPage";
import { EmptyWidgets } from "../../components/EmptyWidgets";
import ProjectPageLayout from "./ProjectPageLayout";
import { setActiveModal } from "../../store/slices/modals";
import {
  getAllPages,
  getCurrentProjectData,
  getCurrentProjectId,
} from "../../store/selectors/projects";
import { getProjects } from "../../store/selectors/projects";
import { setIsPublic } from "../../store/slices/appConfig";
import {
  requestProjectById,
  requestPublicProjectById,
  requestUpdateProjectById,
} from "../../store/slices/projects";
import {
  resetCurrentPageData,
  resetPagesDataItems,
  setCurrentProjectId,
} from "../../store/slices/projectPages";
import { Loader } from "../../components/Loader";
import {
  requestDeleteFile,
  requestUploadSimpleFile,
} from "../../store/slices/files";
import RealEstateExplore from "../RealEstateExplore";

export const Project = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id: queryId, pageId: queryPageId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const pages = useSelector(getAllPages);
  const { items } = useSelector(getProjects);
  const curent = useSelector(getCurrentProjectData);
  const curentProjectId = useSelector(getCurrentProjectId);

  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const projectsIds = items?.map((item) => item.id);
  const isPublicRoute = location.pathname.includes("/public");

  const pageItems = useMemo(() => pages?.items, [pages?.items]);
  const pageId = useMemo(() => queryPageId, [queryPageId]);
  const id = useMemo(() => queryId, [queryId]);

  const serverUpload = (image: any) => {
    if (image) {
      if (curent.previewImage) {
        dispatch(
          requestDeleteFile({
            path: curent.previewImage,
          })
        );
      }

      const filename = id + ".jpg";
      const byteString = atob(image.split(",")[1]);

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;

        const maxWidth = 1024;
        const maxHeight = 568;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (compressedBlob) => {
            const compressedFile = new File([compressedBlob!], filename, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            dispatch(
              requestUploadSimpleFile({
                files: [compressedFile],
                callbacks: {
                  onSuccess: (resp: any) => {
                    const uploadedFile = resp?.at(0);

                    if (uploadedFile) {
                      dispatch(
                        requestUpdateProjectById({
                          id: curentProjectId,
                          previewImage: uploadedFile.path,
                        })
                      );
                      setImageLoading(false);
                    }
                  },
                  onError: () => {
                    setImageLoading(false);
                  },
                },
              })
            );
          },
          "image/jpeg",
          1
        );
      };

      img.src = image;
    }
  };

  const downloadScreenshot = (ref: RefObject<HTMLDivElement>) => {
    //Temporar functia ramane pentru a face screen doar odata la crearea de page
    if (curent.previewImage) {
      return;
    }
    //

    if (ref.current) {
      setImageLoading(true);
      takeScreenShot(ref.current).then(serverUpload);
    }
  };

  useEffect(() => {
    dispatch(setIsPublic(isPublicRoute));
    if (isPublicRoute) {
      dispatch(requestPublicProjectById(id ? id : ""));
    } else {
      dispatch(requestProjectById(id ? id : ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(setCurrentProjectId(id!));
    if (isPublicRoute) {
      pageItems &&
        pageItems.length > 0 &&
        pageItems[0].id &&
        !pageId &&
        navigate(
          `/projects/public/${id}/${pageItems[pageItems.length - 1].id}`
        );
    } else {
      pageItems &&
        pageItems.length > 0 &&
        pageItems[0].id &&
        !pageId &&
        navigate(`/projects/${id}/${pageItems[pageItems.length - 1].id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curent.pages, id, pageId, pageItems]);

  useEffect(() => {
    if (!pageItems && !isPublicRoute && curent.projectType !== "real_estate") {
      dispatch(
        setActiveModal({ id: "projectSettingsModal", createOptions: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItems, curent.projectType]);

  useEffect(() => {
    if (items && items.length && !isPublicRoute) {
      !projectsIds?.includes(id!) && !curent && navigate("/404");
    }

    return () => {
      dispatch(resetPagesDataItems());
      dispatch(resetCurrentPageData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNavigation = () => {
      navigate("/projects");
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <ProjectPageLayout>
      {pages.isLoading === false &&
        !pages.count &&
        curent.projectType !== "real_estate" && <EmptyWidgets />}
      {!pages.count && curent.projectType === "real_estate" && (
        <RealEstateExplore />
      )}
      <ProjectPage downloadScreenshot={downloadScreenshot} />
      {(pages.isLoading || imageLoading) && <Loader />}
    </ProjectPageLayout>
  );
};
