import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";

export const AreaChartGroupedData = (
  currentWidgetData?: WidgetItem,
  areaChartSuggestion?: any
) => {
  const data = currentWidgetData?.data || [];
  const groupBy = currentWidgetData?.groupBy?.[0];

  const xAxe = currentWidgetData?.xAxe?.[0] ?? areaChartSuggestion?.xAxe?.[0];

  const yAxe = currentWidgetData?.yAxe?.[0] ?? areaChartSuggestion?.yAxe?.[0];

  const xAxes =
    currentWidgetData?.uniqueValues?.[xAxe] ||
    Array.from(new Set(data.map((d: any) => d[xAxe] as string))) ||
    [];

  const unsortedGroupedData = data?.reduce((acc, obj) => {
    const group = groupBy ? obj[groupBy] : "default";

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push({
      x: xAxe ? obj[xAxe] : obj.year,
      y: yAxe ? obj[yAxe] : obj.value,
    });

    return acc;
  }, {});

  const sortedGroupedData = Object.keys(unsortedGroupedData).reduce(
    (acc: any, curr: string) => {
      const dataMap = new Map(
        unsortedGroupedData[curr].map((d: any) => [d.x, d])
      );

      acc[curr] = xAxes.map((key: string) => dataMap.get(key)).filter(Boolean);

      return acc;
    },
    {}
  );

  return sortedGroupedData;
};

export const AreaChartDefaultMarkers = (data: any): IMarkersType[] => {
  const groupBy = data?.groupBy?.at(0);
  const uniqueValuesKeys =
    (data?.uniqueValues && Object.keys(data?.uniqueValues!)) || [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);
  const groupedData = data?.uniqueValues
    ? data?.uniqueValues[groupByKey]
    : AreaChartGroupedData(data);

  if (data.uniqueValues) {
    const markers: IMarkersType[] = groupedData?.map(
      (item: string, index: number) => ({
        key: item,
        shape: "donut",
      })
    );
    return markers;
  } else {
    const markers: IMarkersType[] = Object.keys(groupedData).map(
      (item: string, index: number) => ({
        key: item,
        shape: "donut",
      })
    );

    return markers;
  }
};

export const getAreaChartDefaultColors = (data: any): IFormatting[] => {
  const groupBy = data?.groupBy?.at(0);
  const uniqueValuesKeys =
    (data?.uniqueValues && Object.keys(data?.uniqueValues!)) || [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);
  const groupedData =
    data.uniqueValues && groupByKey
      ? data.uniqueValues[groupByKey]
      : AreaChartGroupedData(data);

  if (data.uniqueValues) {
    const colors: IFormatting[] = groupedData?.map(
      (item: string, index: number) => ({
        key: item,
        color: String(index + 1),
      })
    );

    return colors;
  } else {
    const colors: IFormatting[] = Object.keys(groupedData).map(
      (item: string, index: number) => ({
        key: item,
        color: String(index + 1),
      })
    );

    return colors;
  }
};
