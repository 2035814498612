import React from "react";
import {
  AssetName,
  Details,
  FilesTable,
  Format,
  HeadingWrapper,
  Limits,
  LimitsHead,
  LimitsText,
  UploadedDate,
} from "./styles";
import { ProgressBar } from "../../../../ProgressBar";
import { useSelector } from "react-redux";
import {
  getFiles,
  getFileUploadProgress,
  getUploadedFilesCount,
} from "../../../../../store/selectors/files";
import { FileLibraryItem } from "../../../../../models/Files";
import FilesRow from "../FilesRow";

type Props = {};

const FilesList = (props: Props) => {
  const files = useSelector(getFiles) as FileLibraryItem[];

  const fileUploadProgress = useSelector(getFileUploadProgress);
  const uploadedFilesCount = useSelector(getUploadedFilesCount);

  const Progress = (count: number, progress: number, order: number): number => {
    const step = 100 / count;

    const currentStep = Math.ceil(progress / step);

    const controlProgres = (progressNumber: number): number => {
      if (progressNumber > step) {
        return controlProgres(progressNumber - step);
      } else {
        return progressNumber;
      }
    };

    if (order === currentStep) {
      return Math.ceil(controlProgres(progress) * count);
    } else if (progress >= step && order < currentStep) {
      return 100;
    } else {
      return 0;
    }
  };

  return (
    <>
      {fileUploadProgress > 0 &&
        Array(uploadedFilesCount)
          .fill("")
          .map((item, index) => (
            <ProgressBar
              key={item + "upload files"}
              index={index + 1}
              progress={Progress(
                uploadedFilesCount,
                fileUploadProgress,
                index + 1
              )}
            />
          ))}

      <Limits>
        <LimitsHead>FuseDash upload limits</LimitsHead>
        <LimitsText>
          Use Upload assets to upload 3D models, geo assets and others. Max 20
          uploads per month.
        </LimitsText>
      </Limits>

      {!!files.length && (
        <FilesTable>
          <HeadingWrapper>
            <AssetName>Name</AssetName>
            <Format>Format</Format>
            <UploadedDate>Uploaded on</UploadedDate>
            <Details></Details>
          </HeadingWrapper>
          {files?.map((item: FileLibraryItem, idx) => {
            return <FilesRow key={`${item?.id}-${idx}`} file={item} />;
          })}
        </FilesTable>
      )}
    </>
  );
};

export default FilesList;
