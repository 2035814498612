import { MarkerContainer, StyledMapContainer, StyledPopup } from "./styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, {
  MapboxEvent,
  MapRef,
  Marker,
  NavigationControl,
  ViewState,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { RealEstateMapLegend } from "./components/Legend";
import { Threebox } from "threebox-plugin";
import { REAL_ESTATE_LEGEND_IDS_ARR } from "./components/Legend/options";
import { ReactComponent as MapPin } from "../../assets/realEstateIcons/mapPin.svg";
import { Button } from "../Button";
import { ArrowRight } from "react-bootstrap-icons";
import { HouseSettingsProps } from "../Modals/PositionHouseModal/components/HouseSettings";
import { IFile } from "../../models/Projects";
import { useMapState } from "./hooks/useMapState";
import { use3DModel } from "./hooks/use3DModel";
import { usePopup } from "./hooks/usePopup";
import { useMapFilters } from "./hooks/useMapFilters";
import {
  DEFAULT_BEARING,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_PITCH,
  DEFAULT_X,
  DEFAULT_Y,
  DEFAULT_Z,
  DEFAULT_ZOOM,
} from "./constants";

declare global {
  interface Window {
    tb: any;
  }
}

const mapStyles = {
  DEFAULT: "mapbox://styles/flc-designers/cm42t5xnm007d01saeberbrvg",
};

export interface IMarker {
  latitude: number;
  longitude: number;
  imgSrc: string;
  price: number;
  streetAddress: string;
  zpid: number;
}

interface HouseSettings extends Omit<HouseSettingsProps, "zpId" | "address"> {}

type RealEstateMapProps = {
  markers?: IMarker[];
  withLegend?: boolean;
  withZoomAction?: boolean;
  rounded?: boolean;
  bordered?: boolean;
  threeDModelPath?: IFile;
  houseSettings?: HouseSettings;
};

export const RealEstateMap = ({
  markers,
  withLegend = true,
  withZoomAction = true,
  rounded = true,
  bordered = true,
  houseSettings,
  threeDModelPath,
}: RealEstateMapProps) => {
  const mapRef = useRef<MapRef | null>(null);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const { isMapLoaded, setIsMapLoaded, viewState, setViewState } =
    useMapState(houseSettings);
  const { modelRef, add3DModel, handleMapMove, updateModelPosition } =
    use3DModel(houseSettings, threeDModelPath);
  const { popupInfo, setPopupInfo, handleClickDetailsView } = usePopup();
  const { applyFilter } = useMapFilters(mapRef);

  useEffect(() => {
    if (houseSettings) {
      setViewState((prevState) => ({
        ...prevState,
        latitude: houseSettings.latitude || DEFAULT_LATITUDE,
        longitude: houseSettings.longitude || DEFAULT_LONGITUDE,
        zoom: prevState.zoom || DEFAULT_ZOOM,
        pitch: DEFAULT_PITCH,
        bearing: DEFAULT_BEARING,
      }));
    }
  }, [houseSettings, setViewState]);

  const handleViewStateChange = useCallback(
    (newViewState: ViewState) => {
      setViewState(newViewState);
    },
    [setViewState]
  );

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) return;

    const map = mapRef.current.getMap();
    map.on("move", handleMapMove);
    map.on("zoom", handleMapMove);

    return () => {
      map.off("move", handleMapMove);
      map.off("zoom", handleMapMove);
    };
  }, [isMapLoaded, handleMapMove]);

  const onMapLoad = useCallback(
    (event: MapboxEvent) => {
      if (!mapRef.current) return;

      const map = mapRef.current.getMap();
      setIsMapLoaded(true);

      map.addLayer({
        id: "custom-threebox-model",
        type: "custom",
        renderingMode: "3d",
        onAdd: function () {
          window.tb = new Threebox(map, map.getCanvas().getContext("webgl"), {
            defaultLights: true,
          });
          add3DModel(threeDModelPath?.urls?.[0]);
        },
        render: function () {
          window.tb.update();
        },
      });
    },
    [add3DModel, setIsMapLoaded, threeDModelPath?.urls]
  );

  useEffect(() => {
    if (!modelRef.current || !houseSettings || !isMapLoaded) return;

    const { rotateX, rotateY, rotateZ, latitude, longitude, scale } =
      houseSettings;

    updateModelPosition(
      modelRef.current,
      longitude || DEFAULT_LONGITUDE,
      latitude || DEFAULT_LATITUDE,
      Math.max(scale || 1, 0.1),
      rotateX || DEFAULT_X,
      rotateY || DEFAULT_Y,
      rotateZ || DEFAULT_Z
    );
  }, [houseSettings, isMapLoaded, modelRef, updateModelPosition]);

  const renderMarkers = useMemo(() => {
    if (!markers?.length) return null;

    return markers.map((marker, index) => (
      <Marker
        style={{ zIndex: 10 }}
        key={`marker-${index}`}
        longitude={marker.longitude}
        latitude={marker.latitude}
        anchor="bottom"
      >
        <MarkerContainer
          onMouseOver={() => {
            setIsPopupOpened(true);
            setPopupInfo({
              latitude: marker.latitude,
              longitude: marker.longitude,
              imgSrc: marker.imgSrc,
              price: marker.price,
              streetAddress: marker.streetAddress,
              zpid: marker.zpid,
            });
          }}
          onMouseLeave={() => setIsPopupOpened(false)}
        >
          <MapPin />
        </MarkerContainer>
      </Marker>
    ));
  }, [markers, setPopupInfo]);

  const renderPopup = useCallback(
    (latitude: number, longitude: number) => {
      return (
        <div
          onMouseOver={() => setIsPopupOpened(true)}
          onMouseLeave={() => setIsPopupOpened(false)}
        >
          <StyledPopup
            latitude={latitude}
            longitude={longitude}
            closeButton={false}
            onClose={() => setPopupInfo(null)}
            anchor="left"
          >
            <div>
              <p className="house-price">${popupInfo?.price}</p>
              <p className="house-address">{popupInfo?.streetAddress}</p>
              <img src={popupInfo?.imgSrc} alt="Popup" />
              <Button
                icon={<ArrowRight />}
                name="Explore"
                variant="neutral"
                onClick={() => {
                  handleClickDetailsView(popupInfo);
                  setIsPopupOpened(false);
                }}
                size="small"
              />
            </div>
          </StyledPopup>
        </div>
      );
    },
    [popupInfo, setPopupInfo, handleClickDetailsView]
  );

  const onSelectionChange = useCallback(
    (selectedItems: string[]) => {
      applyFilter(selectedItems);
    },
    [applyFilter]
  );

  return (
    <StyledMapContainer bordered={bordered} rounded={rounded}>
      <Map
        ref={mapRef}
        {...viewState}
        mapStyle={mapStyles.DEFAULT}
        styleDiffing
        onLoad={onMapLoad}
        onMove={(evt) => {
          handleViewStateChange(evt.viewState);
          handleMapMove();
        }}
        onZoom={(evt) => {
          handleViewStateChange(evt.viewState);
          handleMapMove();
        }}
        style={{
          display: "flex",
          width: "100%",
          height: "unset",
          borderRadius: "8px",
        }}
        mapboxAccessToken={
          process.env.REACT_APP_MAPBOX_REAL_ESTATE_ACCESS_TOKEN
        }
      >
        {renderMarkers}

        {isPopupOpened &&
          renderPopup(popupInfo?.latitude ?? 0, popupInfo?.longitude ?? 0)}

        {withZoomAction && (
          <NavigationControl showZoom={withZoomAction} position="top-right" />
        )}
      </Map>
      {isMapLoaded && withLegend && (
        <RealEstateMapLegend
          defaultSelectedItems={REAL_ESTATE_LEGEND_IDS_ARR}
          onSelectionChange={onSelectionChange}
        />
      )}
    </StyledMapContainer>
  );
};