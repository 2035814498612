import styled from "styled-components";
import { ThreeDots } from "react-bootstrap-icons";

export const RowWrapper = styled.div<{ $active?: boolean, $cursor?: boolean }>`
  display: flex;
  height: max-content;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 8px 0;
  background: ${({ $active }) => $active ? "#DFE0FD" : ""};
  cursor: ${({ $cursor }) => $cursor ? "pointer" : "default"};
  &:hover{
    background: ${({ $active }) => $active ? "#DFE0FD" : "#9FA4AD1F"};
  }
  &:last-child {
    border-bottom: none;
  }
  
`;

export const CheckBoxWrapper = styled.div`
  display:flex; 
  margin: 0 11px 0 16px;
`

export const FileName = styled.div`
  color: ${({ theme }) => theme.neutralText};
  word-wrap: break-word;
  font-weight: 500;
  max-width: 354px;
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
  width: 100%;
`;

export const Dimensions = styled.div`
  display: flex;
  min-height: 20px;
  gap: 4px;
  flex: 5;
  flex-flow: wrap;
  min-width: 250px;
  padding-right: 10px;
  width: 100%;
  color: ${({ theme }) => theme.neutralText};
`;

export const LabelText = styled.div`
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Dimension = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutralBackground};
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  align-self: center;

  img {
    height: 14px;
    width: 14px;
  }
`;

export const DatasetNameSeparator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  height: 16px;
  position: relative;
  align-self: center;
`;

export const UploadedOn = styled.div`
  display: flex;
  gap: 3px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  min-width: 215px;
  padding-right: 12px;
  margin-right: auto;
`;

export const Provider = styled.div`
  display: flex;
  gap: 2px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  min-width: 160px;
  margin-right: auto;
`;

export const UploadedOnTime = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const More = styled(ThreeDots)`
  color: ${({ theme }) => theme.neutralWeak};
  cursor: pointer;
  margin: 0 8px 0 8px;
`;

export const DatasetDimensionsCount = styled.div`
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 4px;
  align-items: center;
  display: flex;
  width: 28px;
  height: 24px;
  align-self: center;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.neutralText};
  cursor: pointer;
`;
