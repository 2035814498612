import { styled } from "styled-components";

export const SVG = styled.svg`
  path {
    transition: 0.3s;
  }
`

export const XPath = styled.path`
`
export const Done = styled.path`
`
export const CentralColumn = styled.path`
`