// TODO: Remove this file after UI datasets are implemented for real estate project
export const  REAL_ESTATE_MOCK_DATASETS = [
    {
        "name": "[Data Agent] LTSS.csv",
        "filePath": "04ec2f5a-74da-4955-acc3-8ce35e1b134f/e69acbce-cefc-48a7-86e7-5074ac950766.csv",
        "fieldsAi": [
            {
                "name": "year",
                "types": [
                    {
                        "type": "datetime",
                        "subtype": "year"
                    }
                ]
            },
            {
                "name": "state",
                "types": [
                    {
                        "type": "location",
                        "subtype": "state"
                    }
                ]
            },
            {
                "name": "county",
                "types": [
                    {
                        "type": "location",
                        "subtype": "county"
                    }
                ]
            },
            {
                "name": "plan-parent",
                "types": [
                    {
                        "type": "group",
                        "childColumns": [
                            {
                                "newName": "eligibility",
                                "originalName": "dual-eligibility-status",
                                "subtype": "status",
                                "type": "categorical"
                            }
                        ],
                        "childColumnsName": "eligibility status",
                        "groupRelationship": {
                            "type": "parent-child",
                            "subtype": "subdivision",
                            "description": "The parent column 'plan-parent' represents different health plans, while the child column 'dual-eligibility-status' categorizes individuals within those plans based on their eligibility status."
                        },
                        "parentColumn": "plan-parent",
                        "pattern": "Health plan and eligibility status",
                        "visualizationRecommendation": "dimension"
                    }
                ]
            },
            {
                "name": "dual-eligibility-status",
                "types": [
                    {
                        "type": "categorical",
                        "subtype": "status"
                    }
                ]
            },
            {
                "name": "measure",
                "types": [
                    {
                        "type": "categorical",
                        "subtype": "category"
                    }
                ]
            },
            {
                "name": "hedis-reporting-county",
                "types": [
                    {
                        "type": "categorical",
                        "subtype": "category"
                    }
                ]
            },
            {
                "name": "dimension",
                "types": [
                    {
                        "type": "group",
                        "childColumns": [
                            {
                                "newName": "detail",
                                "originalName": "dimension-detail",
                                "subtype": "category",
                                "type": "categorical"
                            }
                        ],
                        "childColumnsName": "dimension detail",
                        "groupRelationship": {
                            "type": "parent-child",
                            "subtype": "subdivision",
                            "description": "The parent column 'dimension' represents different demographic or service-related categories, while the child column 'dimension-detail' provides specific details within those categories."
                        },
                        "parentColumn": "dimension",
                        "pattern": "Demographic dimensions and their details",
                        "visualizationRecommendation": "dimension"
                    }
                ]
            },
            {
                "name": "dimension-detail",
                "types": [
                    {
                        "type": "categorical",
                        "subtype": "category"
                    }
                ]
            },
            {
                "name": "value",
                "types": [
                    {
                        "type": "metric",
                        "subtype": "float"
                    }
                ]
            },
            {
                "name": "data-type",
                "types": [
                    {
                        "type": "categorical",
                        "subtype": "category"
                    }
                ]
            }
        ],
        "fields": [
            {
                "label": "Year",
                "type": "datetime",
                "subtype": "year",
                "key": "year",
                "icon": "datetime"
            },
            {
                "label": "State",
                "type": "location",
                "subtype": "state",
                "key": "state",
                "icon": "location"
            },
            {
                "label": "County",
                "type": "location",
                "subtype": "county",
                "key": "county",
                "icon": "location"
            },
            {
                "label": "Health Plan Parent",
                "type": "categorical",
                "subtype": "category",
                "key": "plan-parent",
                "icon": "categorical"
            },
            {
                "label": "Dual Eligibility Status",
                "type": "categorical",
                "subtype": "status",
                "key": "dual-eligibility-status",
                "icon": "categorical"
            },
            {
                "label": "Healthcare Measure",
                "type": "categorical",
                "subtype": "category",
                "key": "measure",
                "icon": "categorical"
            },
            {
                "label": "Reporting Scope",
                "type": "categorical",
                "subtype": "category",
                "key": "hedis-reporting-county",
                "icon": "categorical"
            },
            {
                "label": "Demographic Dimension",
                "type": "categorical",
                "subtype": "category",
                "key": "dimension",
                "icon": "categorical"
            },
            {
                "label": "Dimension Detail",
                "type": "categorical",
                "subtype": "category",
                "key": "dimension-detail",
                "icon": "categorical"
            },
            {
                "label": "Count Value",
                "type": "metric",
                "subtype": "float",
                "key": "value",
                "icon": "metric"
            },
            {
                "label": "Data Type",
                "type": "categorical",
                "subtype": "category",
                "key": "data-type",
                "icon": "categorical"
            }
        ],
        "sourceId": ""
    }
]




export const datasetColumnKeysMockRealEstate = ["data-type", "value", "dimension-detail", "dimension", "hedis-reporting-county", "measure", "dual-eligibility-status", "plan-parent", "county", "state","year"]