import React from "react";
import { PositiveXlinkHref } from "./dataImage";
import { CentralColumn, Done, SVG } from "./styles";

type Props = { active?: boolean };

const PositiveImage = ({ active }: Props) => {
  return (
    <SVG
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M63.8565 14.7348V57.1361C63.8561 57.7998 63.5923 58.4363 63.1229 58.9056C62.6536 59.375 62.0171 59.6388 61.3534 59.6392H13.1809C12.5171 59.6388 11.8806 59.375 11.4113 58.9056C10.9419 58.4363 10.6781 57.7998 10.6777 57.1361V14.7348C10.6789 14.0715 10.943 13.4358 11.4123 12.9671C11.8816 12.4983 12.5176 12.2349 13.1809 12.2345H61.3534C62.0166 12.2349 62.6527 12.4983 63.1219 12.9671C63.5912 13.4358 63.8554 14.0715 63.8565 14.7348Z"
        fill="white"
      />
      <path
        d="M63.9802 14.6503V57.0516C63.9795 57.7156 63.7151 58.3521 63.2452 58.8212C62.7752 59.2904 62.1383 59.5537 61.4743 59.5533H13.3032C12.6397 59.5529 12.0034 59.2893 11.5341 58.8202C11.0648 58.3512 10.8008 57.7151 10.8 57.0516V14.6503C10.8012 13.9871 11.0653 13.3513 11.5346 12.8826C12.0039 12.4139 12.6399 12.1504 13.3032 12.15H61.4743C62.138 12.1497 62.7748 12.4128 63.2447 12.8816C63.7145 13.3504 63.9791 13.9866 63.9802 14.6503Z"
        fill="white"
      />
      <path d="M23.8501 33.3H30.6001V44.55H23.8501V33.3Z" fill="#CFD2D6" />
      <path
        d="M45 26.55H50.85V44.55H45V26.55Z"
        fill={active ? "#162032" : "#2E3849"}
      />
      <CentralColumn
        d="M34.6501 29.7001H40.9501V44.5501H34.6501V29.7001Z"
        fill={active ? "#138660" : "#2E3849"}
      />
      <path
        d="M23.8501 49.5H50.8501V51.3H23.8501V49.5Z"
        fill={active ? "#162032" : "#2E3849"}
      />
      <path
        d="M63.8565 14.7348V17.8075H10.6792V14.7348C10.6794 14.4063 10.7443 14.081 10.8702 13.7776C10.9961 13.4741 11.1805 13.1984 11.4129 12.9662C11.6454 12.7341 11.9213 12.5499 12.2249 12.4244C12.5284 12.2988 12.8538 12.2343 13.1823 12.2345H61.3534C62.0168 12.2345 62.6531 12.4978 63.1224 12.9667C63.5917 13.4355 63.8558 14.0714 63.8565 14.7348Z"
        fill={active ? "#138660" : "#2E3849"}
      />
      <path
        d="M14.4492 16.1045C15.048 16.1045 15.5334 15.6191 15.5334 15.0203C15.5334 14.4215 15.048 13.936 14.4492 13.936C13.8504 13.936 13.365 14.4215 13.365 15.0203C13.365 15.6191 13.8504 16.1045 14.4492 16.1045Z"
        fill="white"
      />
      <path
        d="M18.5513 16.1045C19.1501 16.1045 19.6355 15.6191 19.6355 15.0203C19.6355 14.4215 19.1501 13.936 18.5513 13.936C17.9525 13.936 17.467 14.4215 17.467 15.0203C17.467 15.6191 17.9525 16.1045 18.5513 16.1045Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M46.8138 54.1682C46.9165 51.8912 47.595 49.6785 48.7848 47.7401C49.9746 45.8016 51.6363 44.2016 53.6121 43.092C55.5878 41.9824 57.8123 41.4 60.0742 41.4C61.3822 41.4016 62.6702 41.5974 63.9 41.9716V59.625H47.703C47.0342 57.8926 46.7295 56.0342 46.8138 54.1682Z"
        fill="#9FA4AD"
        fill-opacity="0.16"
      />
      <path
        d="M7.36182 9.51477L7.95835 8.91824L9.6684 10.6283L9.07187 11.2248L7.36182 9.51477Z"
        fill="#7F8692"
      />
      <path
        d="M10.9448 7.24084H11.7886V9.65959H10.9448V7.24084Z"
        fill="#7F8692"
      />
      <path
        d="M5.62085 12.3525H8.0396V13.1963H5.62085V12.3525Z"
        fill="#7F8692"
      />
      <path
        d="M61.3534 60.0611H13.1809C12.4053 60.0603 11.6618 59.7519 11.1134 59.2035C10.565 58.6551 10.2566 57.9116 10.2559 57.1361V14.7348C10.2573 13.9598 10.5661 13.2169 11.1144 12.6692C11.6627 12.1214 12.4058 11.8134 13.1809 11.8126H61.3534C62.1289 11.8134 62.8724 12.1218 63.4208 12.6702C63.9692 13.2185 64.2776 13.9621 64.2784 14.7376V57.1361C64.2776 57.9116 63.9692 58.6551 63.4208 59.2035C62.8724 59.7519 62.1289 60.0603 61.3534 60.0611ZM13.1809 12.6564C12.6291 12.6571 12.1002 12.8766 11.71 13.2668C11.3199 13.6569 11.1004 14.1859 11.0996 14.7376V57.1361C11.1004 57.6878 11.3199 58.2168 11.71 58.6069C12.1002 58.9971 12.6291 59.2166 13.1809 59.2173H61.3534C61.9051 59.2166 62.4341 58.9971 62.8242 58.6069C63.2144 58.2168 63.4339 57.6878 63.4346 57.1361V14.7348C63.4331 14.1835 63.2133 13.6553 62.8232 13.2658C62.4331 12.8762 61.9046 12.6571 61.3534 12.6564H13.1809Z"
        fill={active ? "#005632" : "#2E3849"}
      />
      <path
        d="M60.3 63.9C65.2706 63.9 69.3 59.8706 69.3 54.9C69.3 49.9295 65.2706 45.9 60.3 45.9C55.3295 45.9 51.3 49.9295 51.3 54.9C51.3 59.8706 55.3295 63.9 60.3 63.9Z"
        fill="white"
      />
      <path
        d="M60.3001 64.8C58.3421 64.8 56.4281 64.2194 54.8 63.1316C53.172 62.0437 51.9031 60.4976 51.1537 58.6886C50.4044 56.8796 50.2084 54.889 50.5904 52.9686C50.9724 51.0482 51.9153 49.2842 53.2998 47.8996C54.6843 46.5151 56.4483 45.5722 58.3688 45.1902C60.2892 44.8082 62.2797 45.0043 64.0887 45.7536C65.8977 46.5029 67.4439 47.7718 68.5317 49.3999C69.6195 51.0279 70.2001 52.942 70.2001 54.9C70.1972 57.5247 69.1532 60.0412 67.2973 61.8971C65.4413 63.7531 62.9249 64.7971 60.3001 64.8ZM60.3001 45.9492C58.5298 45.9492 56.7993 46.4741 55.3273 47.4577C53.8554 48.4412 52.7081 49.8391 52.0307 51.4747C51.3532 53.1102 51.176 54.9099 51.5213 56.6462C51.8667 58.3825 52.7192 59.9774 53.971 61.2292C55.2228 62.481 56.8176 63.3335 58.5539 63.6788C60.2902 64.0242 62.0899 63.8469 63.7255 63.1695C65.361 62.492 66.759 61.3448 67.7425 59.8728C68.726 58.4009 69.251 56.6703 69.251 54.9C69.248 52.527 68.3041 50.252 66.6261 48.5741C64.9481 46.8961 62.6732 45.9521 60.3001 45.9492Z"
        fill="#7F8692"
      />
      <Done
        d="M58.7318 59.5927L54.364 55.2263L55.7281 53.8622L58.7318 56.866L64.5776 51.0188L65.9417 52.3829L58.7318 59.5927Z"
        fill={active ? "#138660" : "#5F6877"}
      />
      <Done
        d="M58.7318 59.5927L54.364 55.2263L55.7281 53.8622L58.7318 56.866L64.5776 51.0188L65.9417 52.3829L58.7318 59.5927Z"
        fill={active ? "#138660" : "#5F6877"}
      />
      <path
        d="M6.28879 60.0962C5.97696 60.0962 5.67152 60.0078 5.40793 59.8412C5.14435 59.6746 4.93343 59.4366 4.79969 59.155C4.66594 58.8733 4.61484 58.5594 4.65234 58.2499C4.68984 57.9403 4.81438 57.6477 5.01151 57.4061C5.20865 57.1645 5.47027 56.9838 5.76601 56.8849C6.06175 56.7861 6.37947 56.7732 6.68227 56.8477C6.98506 56.9222 7.26051 57.081 7.47662 57.3058C7.69273 57.5306 7.84064 57.8121 7.90316 58.1176C7.95164 58.357 7.94639 58.6042 7.88778 58.8413C7.82917 59.0785 7.71867 59.2997 7.56424 59.4889C7.40981 59.6782 7.2153 59.8309 6.99474 59.9359C6.77418 60.0409 6.53307 60.0956 6.28879 60.0962ZM6.28879 57.6423C6.1497 57.6423 6.01301 57.6784 5.89205 57.7471C5.77109 57.8157 5.67 57.9146 5.59864 58.034C5.52729 58.1533 5.4881 58.2892 5.48492 58.4282C5.48173 58.5673 5.51465 58.7048 5.58047 58.8273C5.64628 58.9498 5.74274 59.0532 5.86043 59.1273C5.97812 59.2014 6.11301 59.2437 6.25195 59.2502C6.39088 59.2566 6.52911 59.2269 6.65313 59.1639C6.77715 59.101 6.88273 59.007 6.95957 58.891C7.03991 58.7698 7.08589 58.6291 7.0926 58.4838C7.09931 58.3385 7.06651 58.1941 6.99769 58.066C6.92887 57.9379 6.82661 57.8308 6.70178 57.7562C6.57694 57.6816 6.43422 57.6422 6.28879 57.6423Z"
        fill="#7F8692"
      />
      <path
        d="M12.0276 65.1812C11.7869 65.1811 11.5511 65.1127 11.3477 64.9839C11.1443 64.8552 10.9816 64.6714 10.8784 64.454C10.7753 64.2365 10.7359 63.9942 10.7649 63.7552C10.7939 63.5163 10.8901 63.2904 11.0422 63.1039C11.1944 62.9174 11.3964 62.7779 11.6247 62.7016C11.853 62.6252 12.0982 62.6152 12.332 62.6726C12.5657 62.7301 12.7784 62.8526 12.9453 63.0261C13.1122 63.1996 13.2265 63.4168 13.2749 63.6526C13.3124 63.8376 13.3083 64.0286 13.263 64.2118C13.2177 64.395 13.1324 64.5659 13.013 64.7121C12.8937 64.8583 12.7434 64.9763 12.573 65.0574C12.4026 65.1385 12.2163 65.1808 12.0276 65.1812ZM11.6718 63.6709C11.6328 63.7299 11.6089 63.7977 11.6023 63.8682C11.5958 63.9387 11.6067 64.0097 11.6342 64.075C11.6616 64.1402 11.7047 64.1977 11.7597 64.2423C11.8147 64.2869 11.8798 64.3172 11.9493 64.3307C12.0189 64.3441 12.0906 64.3401 12.1582 64.3191C12.2258 64.2982 12.2872 64.2608 12.337 64.2105C12.3867 64.1601 12.4233 64.0982 12.4434 64.0304C12.4635 63.9625 12.4665 63.8907 12.4523 63.8213C12.4351 63.738 12.3937 63.6617 12.3332 63.6019C12.2726 63.5422 12.1958 63.5017 12.1123 63.4856C12.0288 63.4695 11.9423 63.4785 11.864 63.5115C11.7856 63.5444 11.7187 63.5999 11.6718 63.6709Z"
        fill="#7F8692"
      />
      <defs>
        <pattern
          id="pattern0_2528_62320"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2528_62320"
            transform="matrix(0.0011976 0 0 0.00161717 0 -0.00617377)"
          />
        </pattern>
        <image
          id="image0_2528_62320"
          width="835"
          height="626"
          xlinkHref={PositiveXlinkHref}
        />
      </defs>
    </SVG>
  );
};

export default PositiveImage;
