import { useCallback } from 'react';
import { MapRef } from 'react-map-gl';
import { REAL_ESTATE_LEGEND_MAPP_IDS } from '../components/Legend/options';

export const useMapFilters = (mapRef: React.RefObject<MapRef>) => {
  const applyFilter = useCallback((selectedItems: string[]) => {
    if (!mapRef.current) return;

    const map = mapRef.current.getMap();
    
    // Query all building features to inspect available types
    const allBuildingFeatures =
      map.querySourceFeatures("composite", {
        sourceLayer: "building",
      }) || [];

    const specializedTypes = [
      "school",
      "college",
      "university",
      "hospital",
      "supermarket",
      "shop",
      "warehouse",
      "commercial",
      "office",
    ];

    const defaultBuildingTypes = [...new Set(
      allBuildingFeatures
        .map((feature) => feature.properties?.type)
        .filter((type) => type !== undefined && !specializedTypes.includes(type))
    )];

    const buildingTypesSet = new Set(defaultBuildingTypes);
    const poiTypesSet = new Set<string>();

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.police)) {
      buildingTypesSet.add("police");
      poiTypesSet.add("police");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.restaurant)) {
      buildingTypesSet.add("restaurant");
      poiTypesSet.add("restaurant");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.hotel)) {
      buildingTypesSet.add("hotel");
      buildingTypesSet.add("lodging");
      buildingTypesSet.add("motel");
      poiTypesSet.add("hotel");
      poiTypesSet.add("lodging");
      poiTypesSet.add("motel");
    }
    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.office)) {
      buildingTypesSet.add("office");
      poiTypesSet.add("office");
    }

    map.setFilter("Education", ["==", ["get", "type"], ""]);
    map.setFilter("Hospital", ["==", ["get", "type"], ""]);
    map.setFilter("Supermarkets_Shop", ["==", ["get", "type"], ""]);

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.school)) {
      map.setFilter("Education", [
        "any",
        ["==", ["get", "type"], "school"],
        ["==", ["get", "type"], "college"],
        ["==", ["get", "type"], "university"]
      ]);
      poiTypesSet.add("school");
      poiTypesSet.add("college");
      poiTypesSet.add("university");
    }

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.hospital)) {
      map.setFilter("Hospital", ["==", ["get", "type"], "hospital"]);
      poiTypesSet.add("hospital");
    }

    if (selectedItems.includes(REAL_ESTATE_LEGEND_MAPP_IDS.store)) {
      map.setFilter("Supermarkets_Shop", [
        "any",
        ["==", ["get", "type"], "shop"],
        ["==", ["get", "type"], "supermarket"],
        ["==", ["get", "type"], "commercial"]
      ]);
      poiTypesSet.add("shop");
      poiTypesSet.add("supermarket");
    }

    const buildingTypes = Array.from(buildingTypesSet);
    const poiTypes = Array.from(poiTypesSet);

    if (buildingTypes.length > 0) {
      map.setFilter("building base", [
        "any",
        ...buildingTypes.map(type => ["==", ["get", "type"], type])
      ]);
    } else {
      map.setFilter("building base", null);
    }

    if (poiTypes.length > 0) {
      map.setFilter("poi-label copy", [
        "any",
        ...poiTypes.map(type => ["==", ["get", "type"], type])
      ]);
    } else {
      map.setFilter("poi-label copy", null);
    }

  }, [mapRef]);

  return { applyFilter };
};