import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";

import { FileImport } from "../../FileImport";
import {
  Actions,
  BackIcon,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { UploadedFileItem } from "../../NewProject/UploadedFileItem";
import { Button } from "../../Button";
import {
  removeAllSelectedFiles,
  removeSelectedFile,
  requestCreateDataset,
  resetNewProjectSelectedFile,
} from "../../../store/slices/datasets";

import { closeActiveModal, setActiveModal } from "../../../store/slices/modals";
import { UploadedFilePreview } from "../UploadedFilePreview";
import { getFiles } from "../../../store/selectors/files";
import { getSelectedFiles } from "../../../store/selectors/datasets";
import { getModalBackOptions } from "../../../store/selectors/modals";
import { Loader } from "../../Loader";
import { removeFileData } from "../../../store/slices/files";

type ModalProps = {
  onClose: () => void;
};
export const UploadDatasets = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [fileOverviewOpen, setFileOverviewOpen] = useState(false);
  const [dimensionsSelected, setDimensionsSelected] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const files = useSelector(getFiles);
  const selectedFiles = useSelector(getSelectedFiles);

  const filePath = files[0]?.path;
  const navigate = useNavigate();
  const modalBackOption = useSelector(getModalBackOptions);
  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      dispatch(resetNewProjectSelectedFile());
      dispatch(closeActiveModal({ back: true } as any));
      dispatch(removeAllSelectedFiles());
      dispatch(removeSelectedFile(filePath));
      dispatch(removeFileData(filePath));
    }, 400);
  };

  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $fileOverviewOpen={fileOverviewOpen}
      >
        <ModalHeadingWrapper
          $disableSeparator={true}
          $fileOverviewOpen={fileOverviewOpen}
        >
          <ModalContainer>
            {modalBackOption.includes("uploadDatasetModal") && (
              <BackIcon
                onClick={() => {
                  dispatch(removeAllSelectedFiles());
                  dispatch(closeActiveModal());
                  dispatch(setActiveModal({ id: "datasetLibraryModal" }));
                }}
              />
            )}
            <ModalHeading>Upload Dataset</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent $isLoading={isLoading}>
          <ModalContent $fileOverviewOpen={fileOverviewOpen}>
            <FileImport />
            <UploadedFileItem
              setDimensionsSelected={setDimensionsSelected}
              setFileOverviewOpen={setFileOverviewOpen}
            />
          </ModalContent>
          {fileOverviewOpen && (
            <UploadedFilePreview
              dimensionsSelected={dimensionsSelected}
              setFileOverviewOpen={setFileOverviewOpen}
            />
          )}
          {isLoading && <Loader fullScreen={fileOverviewOpen} />}
        </OverviewContent>

        <ModalFooterWrapper $fileOverviewOpen={fileOverviewOpen}>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Save"
              onClick={() => {
                setIsLoading(true);
                const suggested_groups =
                  files?.at(0)?.suggestions?.suggested_groups;
                const descriptionAi = files?.at(0)?.suggestions?.description;

                const isPrimary =
                  files
                    .find((file) => file.path === selectedFiles.at(0)?.id)
                    ?.parsedSuggestions?.filter(
                      (suggestion) => suggestion.primary
                    )
                    .map((suggestion) => suggestion.objectKey) || [];

                const selectedHeaders = selectedFiles[0].fields;

                dispatch(
                  requestCreateDataset({
                    filePath,
                    name: files[0].originalName,
                    fields: selectedHeaders?.map((s) => ({
                      ...s,
                      primary: isPrimary.includes(s.key),
                    })),
                    descriptionAi,
                    fieldsAi: suggested_groups?.length
                      ? suggested_groups?.map((g) => ({
                          ...g,
                          types: g.types?.map((t) => ({
                            type: t.type,
                            subtype: t.subtype,
                            pattern: t.pattern,
                            childColumns:
                              t.child_columns?.map((c) => ({
                                type: c.type,
                                subtype: c.subtype,
                                newName: c.new_name,
                                originalName: c.original_name,
                              })) || [],
                            childColumnsName: t.child_columns_name,
                            fieldType: t.field_type,
                            fieldSubtype: t.field_subtype,
                            countValues: t.count_values,
                            groupRelationship: t.group_relationship,
                            parentColumn: t.parent_column,
                            visualizationRecommendation:
                              t.visualization_recommendation,
                          })),
                        }))
                      : [],
                    navigate,
                    sourceType: "database",
                  })
                );
                dispatch(removeSelectedFile(filePath));
                dispatch(removeFileData(filePath));
              }}
              variant={
                selectedFiles.length && !isLoading ? "secondary" : "disabled"
              }
              disabled={!selectedFiles.length || isLoading}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
