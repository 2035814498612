import { AxiosError, AxiosResponse } from "axios";

import axios from "./axios";
import { DELETE_FILE, GET_FILE_SIGNED, UPLOAD_FILE } from "./constants";
import { FileUploadDto } from "../models/Files";

export const uploadFile = async (
  file: FileUploadDto,
  onUploadProgress?: (progressEvent: any) => void
) => {
  return axios
    .post(UPLOAD_FILE, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
      onUploadProgress,
      timeout: 500000,
    })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const downloadFile = async (path: string) => {
  return axios
    .get(`${GET_FILE_SIGNED}?path=${path}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteFile = async (path: string) => {
  return axios
    .delete(`${DELETE_FILE}?path=${path}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
