import React, { useEffect } from "react";

import Badge from "../Badge";
import {
  DividerDot,
  FileName,
  ItemWrapper,
  RemoveButton,
  TrashIcon,
} from "./styles";
import { useDispatch } from "react-redux";
import {
  removeNewProjectSelectedDataset,
  requestDatasetRemove,
  requestGetAllDatasets,
  setDatasetPreview,
} from "../../store/slices/datasets";
import { FileLibraryItem } from "../../models/Files";
import { getIconSrc } from "../../helpers/getIconType";
import { Arrow, PreviewButton, Slash } from "../DatasetModalListItem/style";
import { setActiveModal } from "../../store/slices/modals";

interface DatasetsItemInterface {
  dataset?: Partial<FileLibraryItem>;
  id?: string;
  filePath?: string;
  hideRemove?: boolean;
  selectedDimensions?: string[] | undefined;
  currentKeys?: string[];
  isDraggable?: boolean;
  disablePreview?: boolean;
  isPreviewSelectedDataset?: boolean;
  selectedDatasetOverview?: boolean;
  selectedColumns?: string[];
}
export const DatasetsItem = ({
  dataset,
  id,
  filePath,
  hideRemove,
  selectedDimensions,
  isDraggable,
  disablePreview,
  isPreviewSelectedDataset,
  selectedDatasetOverview,
  selectedColumns,
}: DatasetsItemInterface) => {
  const dispatch = useDispatch();

  const onPreview = () => {
    dispatch(setDatasetPreview(dataset));
    setTimeout(() => {
      dispatch(
        setActiveModal({
          id: selectedDatasetOverview
            ? "selectedDatasetOverviewModal"
            : "datasetOverviewModal",
          data: {
            selectedColumns,
          },
        })
      );
    }, 1000);
  };

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <ItemWrapper>
      <FileName $previewSelected={isPreviewSelectedDataset}>
        {dataset?.name}
      </FileName>
      {!isPreviewSelectedDataset && <DividerDot />}
      {dataset?.fields?.map((field, idx: number) =>
        selectedColumns ? (
          selectedColumns.includes(field.key) && (
            <Badge
              key={idx}
              name={field.label}
              onClick={() => {}}
              keyName={field.key}
              type={field.type}
              subtype={field.subtype}
              icon={getIconSrc({ type: field.type, subtype: field.subtype })}
              isDraggable={isDraggable}
              selectedItems={selectedDimensions}
            />
          )
        ) : (
          <Badge
            key={idx}
            name={field.label}
            onClick={() => {}}
            keyName={field.key}
            type={field.type}
            subtype={field.subtype}
            icon={getIconSrc({ type: field.type, subtype: field.subtype })}
            isDraggable={isDraggable}
            selectedItems={selectedDimensions}
          />
        )
      )}
      {!disablePreview && (
        <>
          <DividerDot />
          <PreviewButton $disabled={false} onClick={onPreview}>
            <Arrow />
            <Slash />
            Preview
            <Slash />
            <Arrow />
          </PreviewButton>
        </>
      )}
      {(id || filePath) && !hideRemove && (
        <RemoveButton
          onClick={() => {
            if (id) {
              dispatch(requestDatasetRemove(id));
            } else if (filePath) {
              dispatch(removeNewProjectSelectedDataset(filePath));
            }
          }}
        >
          <TrashIcon />
        </RemoveButton>
      )}
    </ItemWrapper>
  );
};
