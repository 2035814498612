import React, { useState } from "react";
import {
  Buttons,
  Container,
  Content,
  DataList,
  DataListHeader,
  DataListRow,
  DataListRowButtons,
  DataListRowName,
  Header,
  HeaderText,
  ImagesIcon,
  LocationIcon,
  PlaceButton,
  TrashIcon,
} from "./style";
import { Button } from "../../../../Button";
import { setActiveModal } from "../../../../../store/slices/modals";
import { PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { getDraftProjectFiles } from "../../../../../store/selectors/datasets";
import { removeNewProjectFile } from "../../../../../store/slices/datasets";
import { ResourcesNoData } from "../../../../../pages/Resources/pages/NoData";

import { Plus } from "react-bootstrap-icons";
import { ReactComponent as EmptyIcon } from "../../../../../assets/resourcesPagesIcons/NoFile.svg";

type Props = {};

const RealEstate = (props: Props) => {
  const dispatch = useDispatch();
  const newProjectFiles = useSelector(getDraftProjectFiles);

  const DatasetsMock = [
    {
      name: "Zillow API",
      onClick: () => {},
    },
  ];

  const placeButtonHandle = () => {
    // Open modal that allows to position house on the map
    dispatch(setActiveModal({ id: "positionHouseModal" }));
  };

  const onDeleteHandle = (url: string) => {
    dispatch(removeNewProjectFile(url));
  };

  const [selectedFile, setSelectedFile] = useState<string>("");

  return (
    <Container>
      <Header>
        <HeaderText>Data Resources</HeaderText>
        <Buttons>
          <Button
            name="Add data"
            onClick={() => {}}
            variant="disabled"
            size="medium"
            disabled
            icon={<PlusLg />}
          />
          {!!newProjectFiles.length && (
            <Button
              name="Add files"
              onClick={() => {
                dispatch(
                  setActiveModal({
                    id: "uploadFilesModal",
                    data: { isNewProject: true },
                  })
                );
              }}
              variant="primary"
              size="medium"
              icon={<PlusLg />}
            />
          )}
        </Buttons>
      </Header>
      <Content>
        <DataList>
          <DataListHeader>
            <ImagesIcon /> Datasets:
          </DataListHeader>
          {DatasetsMock.map((item, index) => (
            <DataListRow key={index}>
              <DataListRowName>{item.name}</DataListRowName>
            </DataListRow>
          ))}
          {/* temporar */}
          {!DatasetsMock.length && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              empty
            </div>
          )}
          {/* temporar */}
        </DataList>

        <DataList>
          <DataListHeader>
            <ImagesIcon /> Files:
          </DataListHeader>

          {newProjectFiles.map((item, idx) => (
            <DataListRow key={`${item.name}-${idx}}`} $active={selectedFile === item.urls[0]}>
              <DataListRowName onClick={() => setSelectedFile(item.urls[0])}>
                {item.name}
              </DataListRowName>
              {selectedFile !== item.urls[0] && (
                <DataListRowButtons>
                  <PlaceButton onClick={placeButtonHandle}>
                    Place on map
                    <LocationIcon />
                  </PlaceButton>
                  <TrashIcon onClick={() => onDeleteHandle(item.urls[0])} />
                </DataListRowButtons>
              )}
            </DataListRow>
          ))}
          {!newProjectFiles.length && (
            <ResourcesNoData
              Icon={EmptyIcon}
              title="No file uploaded"
              subTitle={`Feel free to add files by uploading OBJ, GLB or STL files. Just click the "Add files" button below! `}
              IconButton={Plus}
              name={"Add files"}
              onClick={() => {
                dispatch(
                  setActiveModal({
                    id: "uploadFilesModal",
                    data: { isNewProject: true },
                  })
                );
              }}
            />
          )}
        </DataList>
      </Content>
    </Container>
  );
};

export default RealEstate;
