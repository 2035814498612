import { Property } from "../../../../models/RealEstate";
import { StyledAboutHome } from "./styles";
import {
  Clock,
  HouseDoor,
  Gear,
  BoundingBoxCircles,
  Rulers,
} from "react-bootstrap-icons";

interface Props {
  className?: string;
  data: Property | null;
}

const AboutHome = ({ data, className }: Props) => {
  if (!data) return null;
  const features = (data?.resoFacts.atAGlanceFacts || []).map(fact => 
    `${fact.factLabel}: ${fact.factValue ?? "No Data"}`
  );

  // Calculate price per square foot
  const pricePerSqFt = Math.round(data?.price / data?.livingArea);
  return (
    <StyledAboutHome className={className}>
      <h5 className="about-home-title">About this home</h5>

      <div className="about-home-tags">
        {features?.map((feature, index) => (
          <div key={index} className="about-home-tag">
            {feature}
          </div>
        ))}
      </div>

      <p className="about-home-description">{data?.description}</p>

      <div className="about-home-footer">
        <div className="about-home-footer-item">
          <Clock />
          <span>{data?.daysOnZillow}</span>
        </div>
        <div className="about-home-footer-item">
          <HouseDoor />
          <span>{data?.homeType?.replace(/_/g, ' ')}</span>
        </div>
        <div className="about-home-footer-item">
          <Gear />
          <span>Built in {data.yearBuilt}</span>
        </div>
        <div className="about-home-footer-item">
          <BoundingBoxCircles />
          <span>{data.lotSize?.toLocaleString()} sq ft lot</span>
        </div>
        <div className="about-home-footer-item">
          <Rulers />
          <span>${pricePerSqFt} per sq ft</span>
        </div>
      </div>
    </StyledAboutHome>
  );
};

export default AboutHome;
