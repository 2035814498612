import styled, { keyframes } from "styled-components";
import { AiLoadingIcon } from "../../../../../assets";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const AiChatLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const AiChatLoaderIcon = styled(AiLoadingIcon)`
  animation: ${rotate} 2s linear infinite;
  path {
      fill: ${({ theme }) => theme.neutralTextWeak};
  }
`;