import React from "react";

interface Message {
    action: 'upload' | 'analyze' | 'aboard',
    instructions: Record<string, any>
}

const useChatWS = () => {
    const [socket, setSocket] = React.useState<WebSocket | null>(null);

    const connectWebSocket = React.useCallback(() => {
        const socketInstance = new WebSocket('ws://5.161.74.16:8999/chat');

        socketInstance.onopen = () => {};

        socketInstance.onmessage = (event) => {
            try {
                const response = JSON.parse(event.data);

                console.log(response)
            } catch (error) {
                console.error("Error parsing message:", error);
            }
        };

        socketInstance.onclose = () => {};

        socketInstance.onerror = (error) => {};

        setSocket(socketInstance);

        return () => socketInstance.close();
    }, []);

    React.useEffect(() => {
        connectWebSocket();

        return () => socket?.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectWebSocket]);

    const sendMessage = React.useCallback(async (message: string, file: { path: string }) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            try {
                const textMessage: Message = {
                    action: "upload",
                    instructions: {
                        file_path: file.path
                    }
                };

                socket.send(JSON.stringify(textMessage));
            } catch (e) {
                console.error(e)
            }
        }
    }, [socket]);

    return {
        sendMessage
    }
}

export default useChatWS;