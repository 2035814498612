import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";

import { FileImport } from "../../FileImport";
import {
  Actions,
  BackIcon,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import {
  removeAllSelectedFiles,
  removeSelectedFile,
  requestGetAllDatasets,
  setNewProjectFiles,
} from "../../../store/slices/datasets";

import { closeActiveModal, setActiveModal } from "../../../store/slices/modals";
import { getFiles } from "../../../store/selectors/files";
import { getDraftProjectFiles } from "../../../store/selectors/datasets";
import { getModalBackOptions } from "../../../store/selectors/modals";
import { Loader } from "../../Loader";
import { removeFileData } from "../../../store/slices/files";
import { FilesContentWrapper } from "./styles";
import FilesList from "./components/FilesList";
import { IFile } from "../../../models/Projects";

type ModalProps = {
  onClose: () => void;
};

export const UploadFiles = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const fileOverviewOpen = false;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const files = useSelector(getFiles);
  const newProjectFiles = useSelector(getDraftProjectFiles);
  // const selectedFiles = useSelector(getSelectedFiles);
  const filePath = files.map((item) => item.path);
  const modalBackOption = useSelector(getModalBackOptions);
  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      // dispatch(resetNewProjectSelectedFile());
      // dispatch(closeActiveModal({ back: true } as any));
      dispatch(removeAllSelectedFiles());
      filePath.forEach((item) => {
        dispatch(removeSelectedFile(item));
        dispatch(removeFileData(item));
      });
    }, 400);
  };

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $fileOverviewOpen={fileOverviewOpen}
      >
        <ModalHeadingWrapper
          $disableSeparator={true}
          $fileOverviewOpen={fileOverviewOpen}
        >
          <ModalContainer>
            {modalBackOption.includes("uploadDatasetModal") && (
              <BackIcon
                onClick={() => {
                  dispatch(removeAllSelectedFiles());
                  dispatch(closeActiveModal());
                  dispatch(setActiveModal({ id: "datasetLibraryModal" }));
                }}
              />
            )}
            <ModalHeading>Add files</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent $isLoading={isLoading}>
          <FilesContentWrapper>
            <ModalContent $fileOverviewOpen={fileOverviewOpen}>
              <FileImport
                description="OBJ, GLB or STL, 300 MB per upload"
                accept={{
                  "model/obj": [".obj"],
                  "model/mtl": [".mtl"],
                  "model/glb": [".glb"],
                  "model/stl": [".stl"],
                }}
                rejectMessage="Please upload a OBJ, GLB or STL and less that 300 MB per upload"
              />
              <FilesList />
            </ModalContent>
            {isLoading && <Loader fullScreen={fileOverviewOpen} />}
          </FilesContentWrapper>
        </OverviewContent>

        <ModalFooterWrapper $fileOverviewOpen={fileOverviewOpen}>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Add"
              onClick={() => {
                setIsLoading(true);
                dispatch(
                  setNewProjectFiles([
                    ...newProjectFiles,
                    ...files.map(
                      (item) =>
                        ({
                          type: item.type,
                          name: item.originalName,
                          urls: [item.url],
                          path: item.path,
                          mapBoxConfig: {
                            rotateX: 0,
                            rotateY: 0,
                            rotateZ: 0,
                            address: "",
                            scale: 1,
                            coordinates: [-73.99178, 40.748731],
                            zpId: "",
                          },
                        } as IFile)
                    ),
                  ])
                );
                filePath.forEach((item) => {
                  dispatch(removeSelectedFile(item));
                  dispatch(removeFileData(item));
                });
                handleOnClose();
              }}
              variant={!!files.length || isLoading ? "secondary" : "disabled"}
              disabled={!files.length || isLoading}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
