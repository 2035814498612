import styled from "styled-components";
import { ReactComponent as FinishImg } from "../../assets/finish.svg";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const Finish = styled(FinishImg)`
  width: 96px;
  height: 96px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`
export const Message = styled.p`
  color: ${({theme}) => theme.neutralTextWeak};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`
export const SubMessage = styled.p`
  color: ${({theme}) => theme.neutralTextWeak};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`