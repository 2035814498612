import { StoreInterface } from "../../models";

export const isDraftNewProject = (store: StoreInterface) =>
  !!store.datasets.newDraftProject.selectedDatasets.length;

export const getDraftProjectDetails = (store: StoreInterface) =>
  store.datasets.newDraftProject;

export const getDraftProjectFiles = (store: StoreInterface) =>
  store.datasets.newDraftProject.files;

export const getNewProjectSelectedDatasets = (store: StoreInterface) =>
  store.datasets.newDraftProject.selectedDatasets;

export const getNewProjectSelectedDatasetsColumns = (store: StoreInterface) =>
  store.datasets.newDraftProject.selectedColumns;

export const getSelectedFileView = (store: StoreInterface) =>
  store.datasets.selectedFileView;

export const getSelectedFiles = (store: StoreInterface) =>
  store.datasets.selectedLibraryDatasets;

export const getAllDatasets = (store: StoreInterface) =>
  store.datasets.dataSets;

export const getSelectedDataset = (store: StoreInterface) =>
  store.datasets.selectedDatasetPreview;

export const getDatasetContents = (store: StoreInterface) =>
  store.datasets.datasetContents;

export const getDatasetLoading = (store: StoreInterface) =>
  store.datasets.dataSets.isLoading;
