import { AiLoadingIcon } from "../../../../../assets";
import { Button } from "../../../../Button";

import {
  ResultHeader,
  ResultItem,
  ResultitemDescription,
  ResultItemHeader,
  ResultItemSubHeader,
  ResultsContainer,
} from "./styles";
import { FlexContainer } from "../../../../../styles";

const AiChatResult = () => {
  const mock = [
    {
      title: "Misleading Column Name",
      subtitle: "Columns: Label, XX_XX_XXXX",
      desc: "Please rename columns using clear, descriptive names that accurately reflect the content or purpose of the data.",
    },
    {
      title: "Missing Values",
      subtitle: "Columns: Margin of Error (12), Label (2), Estimate (4)",
      desc: "Missing values can lead to incomplete analyses, biased results, or incorrect conclusions. It is recommended to fill missing values, remove such rows or columns, or leave as missing (if appropriate).",
    },
    {
      title: "Not Important Fileds",
      subtitle: "Columns: Id, Notes, Meaning of type of operation",
      desc: "These types of columns have a negative impact on the quality and knowledge value of a generated plot. It is recommended to remove these columns.",
    },
    {
      title: "Mixed Data Types",
      subtitle: "Column: Date (2), Estimate (4)",
      desc: "Single column in a dataset contains values of different data types. For columns that should contain only numbers, strip out any symbols.",
    },
  ];
  return (
    <ResultsContainer>
      <div>
        <AiLoadingIcon />
      </div>
      <div>
        <ResultHeader>
          The uploaded excel file contains the following errors:
        </ResultHeader>
        <div>
          {mock.map((item) => (
            <ResultItem key={item.title}>
              <FlexContainer justify="space-between">
                <div>
                  <ResultItemHeader>{item.title}</ResultItemHeader>
                  <ResultItemSubHeader>{item.subtitle}</ResultItemSubHeader>
                </div>
                <Button
                  name="View"
                  variant="neutral"
                  size="small"
                  onClick={() => console.log("View")}
                />
              </FlexContainer>
              <ResultitemDescription>{item.desc}</ResultitemDescription>
            </ResultItem>
          ))}
        </div>
      </div>
    </ResultsContainer>
  );
};

export default AiChatResult;