import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeActiveModal } from "../../../store/slices/modals";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ChatPanel from "./components/ChatPanel";
import AIChatHeader from "./components/AIChatHeader";
import AiChatLoader from "./components/AiChatLoader";
import AiChatInitialView from "./components/AiChatInitialView";
import AiChatResult from "./components/AiChatResult";
import { uploadFile } from "../../../api/files";
import useChatWS from "./hooks/useChatWS";

import {
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
} from "../styles";
import {
    ModalFooterContainer,
    ModalTicketLink,
    ModalWrapper,
    ModalContentContainer,
    ModalChatWrapper,
} from "./styles";

interface ModalProps {
  onClose: () => void;
};

const AiChatModal = ({ onClose }: ModalProps) => {
    const { sendMessage } = useChatWS();
    const dispatch = useDispatch();

    const [isClosing, setIsClosing] = useState(false);

    const handleOnClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            dispatch(closeActiveModal());
        }, 400);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isResult, setIsResult] = useState(false);

    const handeSendMessage = async (message: string, files: File[]) => {
        setIsLoading(true);
        if (files?.length) {
             // @ts-ignore
            const { response } = await uploadFile({ files });

            if (response.data.items[0]) {
                sendMessage('', response.data.items[0])
            } 
        }
        setIsLoading(false);
        setIsResult(true);
    };

    const isInititalState = !(isLoading || isResult);

    return (
        <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
            <ModalWrapper $isClosing={isClosing} onClick={(e) => e.stopPropagation()}>
                <ModalHeadingWrapper>
                   <AIChatHeader onClose={handleOnClose} />
                </ModalHeadingWrapper>
                <ModalContentContainer $isInitialState={isInititalState}>
                    { 
                        !isLoading ? isResult ? <AiChatResult /> : <AiChatInitialView /> : null
                    }
                    <ModalChatWrapper $isInitialState={isInititalState}>
                        { 
                            isLoading && <AiChatLoader />
                        }
                        <ChatPanel onMessageSend={handeSendMessage} />
                    </ModalChatWrapper>
                </ModalContentContainer>
                {
                    isInititalState && (
                        <ModalFooterWrapper>
                            <ModalFooterContainer>
                                <QuestionCircleFill />
                                Need help?
                                <ModalTicketLink to="/">
                                    Create a Ticket
                                </ModalTicketLink>
                            </ModalFooterContainer>
                        </ModalFooterWrapper>
                    )
                }
            </ModalWrapper>
        </ModalOverlay>
    );
};

export default AiChatModal;