import { keyframes, styled } from "styled-components"

export const Container = styled.ul`
  list-style: none;
  padding: 8px 12px;
  border-top: 1px solid #DFE1E4;
`

export const HeadNameList = styled.div<{$active: boolean}>`
  color: #2E3849;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 6px;
  user-select: none;
  cursor: pointer;

  svg{
    transition: 0.3s;
    width: 16px;
    height: 16px;
    ${({$active}) => $active && `
      transform: rotateZ(90deg);
    `}
  }
`

const slideDown = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OptionsWrapper = styled.div`
  animation: ${slideDown} 0.3s ease-out;
  padding: 16px 0;
  display:flex;
  flex-direction: column;
  gap: 8px;
`

export const CheckBoxWrapper = styled.div`
  margin-top: 2px;
`

export const ListOption = styled.li`
  padding: 0;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #2E3849;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

` 

export const TextArea = styled.textarea<{$isScroll?: boolean}>`
  width: 100%;
  min-height: 100px;
  max-height: 500px;
  resize: vertical;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #CFD2D6;
  border-radius: 24px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  ${({$isScroll}) => $isScroll && `
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `}

  &::placeholder {
    color: #aaa;
  }
`;

export const TextAreaLabel = styled.p`
  margin-left: 4px; 
  color: #5F6877;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`