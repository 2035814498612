import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import { NumberValue, scaleLinear, scaleOrdinal, select, range, arc } from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { getCurrentColor } from "../utils/getCurrentMarker";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";

export interface PolarAreaChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const axisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

export const PolarAreaChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: PolarAreaChartProps) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const margin = { top: 25, right: 5, bottom: 25, left: 5 };
  const width = bounds.width - margin.left - margin.right;
  const height = bounds.height - margin.top - margin.bottom;
  const innerRadius = 0;
  const outerRadius = Math.min(width, height) / 2;
  const rScaleSteps = 5;

  const chartSuggestion = useMemo(() => {
    return aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "polarAreaChart"
    );
  }, [aiSuggestions]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.length
      ? currentWidget.xAxe[0]
      : chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.length
      ? currentWidget?.yAxe?.at(0)
      : chartSuggestion?.yAxe?.at(0);
  }, [chartSuggestion?.yAxe, currentWidget?.yAxe]);

  const categories: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(currentWidget?.data?.map((d: any) => d[xAxe]))) ||
      []
    );
  }, [currentWidget?.uniqueValues, currentWidget?.data, xAxe]);

  const uniqueValues = useMemo(() => {
    const uniqueValuesKey = Object.keys(currentWidget?.uniqueValues || {})?.[0];

    return currentWidget?.uniqueValues?.[uniqueValuesKey] || [];
  }, [currentWidget?.uniqueValues]);

  const legendValues = useMemo(() => {
    if (!uniqueValues?.length) return [];
    return uniqueValues?.map((key) => ({
      label: key!,
      key: key!,
      color: getCurrentColor(currentWidget, key, styleId),
    }));
  }, [uniqueValues, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const chartData = useMemo(() => {
    const data = currentWidget?.data || chartSuggestion?.data;
    const formatting = currentWidget?.formatting || chartSuggestion?.formatting;

    let unsortedData: any[] = data;

    if (formatting?.length > 1 && currentWidget?.data) {
      const aggregatedData = new Map();

      data.forEach((d: { [x: string]: any }) => {
        const date = d[xAxe];
        const sessionLength = d[yAxe];
        if (aggregatedData.has(date)) {
          aggregatedData.set(date, aggregatedData.get(date) + sessionLength);
        } else {
          aggregatedData.set(date, sessionLength);
        }
      });

      const formattedData = Array.from(
        aggregatedData,
        ([date, totalSessionLength]) => ({
          [xAxe]: date,
          [yAxe]: totalSessionLength,
        })
      );

      unsortedData = formattedData;
    }

    const sortedData = categories.flatMap((key: string) =>
      unsortedData.filter((d: any) => d[xAxe] === key)
    );

    return sortedData;
  }, [
    currentWidget?.data,
    currentWidget?.formatting,
    chartSuggestion?.data,
    chartSuggestion?.formatting,
    categories,
    xAxe,
    yAxe,
  ]);

  const angleSlice = useMemo(() => {
    return (Math.PI * 2) / categories.length;
  }, [categories.length]);

  //* Scales
  const colorScale = useMemo(() => {
    return scaleOrdinal<string, string>()
      .domain(legendValues?.map((item) => item.label))
      .range(legendValues?.map((item) => item.color))
      .unknown(getCurrentColor(currentWidget, "default", styleId));
  }, [currentWidget, legendValues, styleId]);

  const maxValue = useMemo(
    () =>
      chartData && Math.max(...chartData?.map((d: any) => d[yAxe] as number)),
    [chartData, yAxe]
  );

  const rScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([0, maxValue])
      .rangeRound([0, outerRadius])
      .nice();
  }, [outerRadius, maxValue]);

  const xScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([0, chartData?.length])
      .range([0, 2 * Math.PI]);
  }, [chartData]);

  const yScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([0, maxValue])
      .rangeRound([innerRadius, outerRadius])
      .nice();
  }, [maxValue, innerRadius, outerRadius]);

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget?.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: String(datum[xAxe!]),
          data: {
            [yAxe as string]: String(datum[yAxe!]),
          },
          coords,
        });
      }
    },
    [currentWidget?.tooltip, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget?.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget?.tooltip, showTooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!showTooltip && !currentWidget?.tooltip) {
        return;
      }

      svg
        .selectAll(".polar-area-path, .tick-group")
        .transition()
        .duration(200)
        .attr("opacity", 0.2);

      select(self).transition().duration(200).attr("opacity", "1").attr("r", 6);
    },
    [currentWidget?.tooltip, showTooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!showTooltip && !currentWidget?.tooltip) {
        return;
      }

      svg
        .selectAll(".polar-area-path")
        .transition()
        .duration(200)
        .attr("opacity", 0.8);

      svg
        .selectAll(".tick-group")
        .transition()
        .duration(200)
        .attr("opacity", 1);
    },
    [currentWidget?.tooltip, showTooltip]
  );

  //* Chart
  const svgContainer = select(svgRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData?.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr(
        "transform",
        `translate(${(width + margin.left + margin.right) / 2}, ${
          (height + margin.top + margin.bottom) / 2
        })`
      );

    //* Grid
    const grid = svg.append("g").attr("class", "grid");
    grid
      .selectAll(".grid-circle")
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append("circle")
      .attr("class", "grid-circle")
      .attr("r", (d) => rScale((d * maxValue) / rScaleSteps))
      .attr("fill", "none")
      .attr("stroke", "#939ba7")
      .attr("stroke-opacity", (d, i) => (i + 1) * 0.2);

    //* Axes
    const axisGrid = svg.append("g").attr("class", "axis-grid");
    categories.forEach((category: string, i: number) => {
      // INFO: Start from the top: - Math.PI / 2
      const angle = angleSlice * i - Math.PI / 2;
      const labelAngle = angle + angleSlice / 2;
      const x = rScale(maxValue) * Math.cos(angle);
      const y = rScale(maxValue) * Math.sin(angle);

      const textAnchor =
        Math.abs(labelAngle) === Math.PI / 2
          ? "middle"
          : labelAngle < Math.PI / 2 && labelAngle > -Math.PI / 2
          ? "start"
          : "end";

      // Radial axis line
      axisGrid
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", x)
        .attr("y2", y)
        .attr("class", "grid")
        .attr("stroke", "#939ba7")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", "2,2");

      // Radial axis line's cap
      axisGrid
        .append("circle")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 3)
        .attr("class", "axis-cap")
        .attr("fill", "#939ba7");

      // Axis labels
      axisGrid
        .append("text")
        .attr("class", "axis-label")
        .attr("x", (rScale(maxValue) + 12) * Math.cos(labelAngle))
        .attr("y", (rScale(maxValue) + 12) * Math.sin(labelAngle))
        .attr("text-anchor", textAnchor)
        .attr("font-size", "12px")
        .attr("dominant-baseline", "central")
        .attr("fill", "#5f6877")
        .text(category);
    });

    // * DataViz
    // Draw data areas
    svg
      .selectAll("g")
      .data(chartData)
      .join("g")
      .selectAll("path")
      .data(
        (d: any, i: number) => [
          {
            [xAxe]: d[xAxe],
            [yAxe]: d[yAxe],
            pos: i,
          },
        ]
        // For sliced areas
        // range(yTickSpacing, d[yAxe] + 1, yTickSpacing).map((value) => ({
        //   [xAxe]: d[xAxe],
        //   [yAxe]: d[yAxe],
        //   pos: i
        // }))
      )
      .join("path")
      .attr("class", "polar-area-path")
      .attr("fill", (d: any) => colorScale(String(d[xAxe])))
      .attr("opacity", 0.8)
      .attr("stroke", "#fff")
      .attr("stroke-width", 2)
      .attr("d", (d) =>
        arc()
          .innerRadius(innerRadius)
          // For sliced areas
          // .innerRadius(yScale(d[yAxe] - yTickSpacing))
          .outerRadius(yScale(d[yAxe] as number))
          .startAngle(xScale(d.pos))
          .endAngle(xScale(d.pos + 1))(d as any)
      )
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    //* Grid ticks
    const measuredRectHeight = 21;
    const hasChartSufficientHeight =
      height / 2 >= (rScaleSteps - 1) * measuredRectHeight;
    if (hasChartSufficientHeight) {
      const tick = svg.append("g").attr("class", "tick");
      tick
        .selectAll(".tick-group")
        .data(range(1, rScaleSteps + 1))
        .enter()
        .append("g")
        .attr("class", "tick-group")
        .each(function (d, i) {
          const group = select(this);
          const yPosition = -rScale((d * maxValue) / rScaleSteps);

          // Create the text element first to measure its size
          const textNode = group
            .append("text")
            .attr("class", "tick-rect")
            .attr("x", 0)
            .attr("y", yPosition)
            .attr("font-size", "12px")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .text(axisTickFormatter(Math.floor((maxValue / rScaleSteps) * d)));

          const textBBox = textNode.node()?.getBBox() as DOMRect;
          const padding = {
            x: 7,
            y: 3,
          };
          const rectWidth = textBBox.width + padding.x * 2;
          const rectHeight = textBBox.height + padding.y * 2;
          const rectX = textBBox.x - padding.x;
          const rectY = textBBox.y - padding.y;

          // Create the rect element before the text element
          group
            .insert("rect", "text")
            .attr("class", "tick-label")
            .attr("x", rectX)
            .attr("y", rectY)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#f1f4f7");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <></>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {AVAILABLE_WIDGETS["polarAreaChart"]?.length + 1}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
          {legendValues?.length > 1 && showLegend && currentWidget?.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
            />
          )}
        </HeaderWrapper>

        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        ></SVGStyled>

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
