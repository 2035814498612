import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomInput } from "../../../../CustomInput";
import {
  HouseSettingsContainer,
  HouseSettingsHr,
  HouseSettingsRotate,
  HouseSettingsSection,
  HouseSettingsText,
  SuggestionContainer,
  SuggestionItem,
} from "./styles";
import { useAddressSuggestions } from "../../../../../hooks/useAddressSuggestions";

export type HouseSettingsProps = {
  zpId: string;
  address: string;
  latitude: number;
  longitude: number;
  scale: number;
  rotateX: number;
  rotateY: number;
  rotateZ: number;
};

enum InputFieldNameValue {
  LONGITUDE = "longitude",
  LATITUDE = "latitude",
  SCALE = "scale",
  ROTATE_X = "rotateX",
  ROTATE_Y = "rotateY",
  ROTATE_Z = "rotateZ",
}

enum InputFieldDefaultValue {
  SCALE = 1,
  ROTATE_X = 0,
  ROTATE_Y = 0,
  ROTATE_Z = 0,
}

type Props = {
  onSave: (houseSettings: HouseSettingsProps) => void;
  onChange?: (values: HouseSettingsProps) => void;
  initialValues?: Partial<HouseSettingsProps>;
};

const validationSchema = Yup.object({
  zpId: Yup.string(),
  address: Yup.string(),
  latitude: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 0 : Number(value)))
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  longitude: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 0 : Number(value)))
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  scale: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 1 : Number(value)))
    .positive("Scale must be positive"),
  rotateX: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 0 : Number(value))),
  rotateY: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 0 : Number(value))),
  rotateZ: Yup.number()
    .transform((value) => (isNaN(value) || value === '' ? 0 : Number(value))),
});

const DEFAULT_VALUES: HouseSettingsProps = {
  zpId: "",
  address: "",
  latitude: 0,
  longitude: 0,
  scale: 1,
  rotateX: 0,
  rotateY: 0,
  rotateZ: 0,
};

const getInitialValues = (initialValues?: Partial<HouseSettingsProps>): HouseSettingsProps => ({
  ...DEFAULT_VALUES,
  ...initialValues,
});

const processValues = (values: typeof DEFAULT_VALUES): HouseSettingsProps => ({
  ...values,
  latitude: Number(values.latitude) || 0,
  longitude: Number(values.longitude) || 0,
  scale: Number(values.scale) || 1,
  rotateX: Number(values.rotateX) || 0,
  rotateY: Number(values.rotateY) || 0,
  rotateZ: Number(values.rotateZ) || 0,
});

const HouseSettings: React.FC<Props> = ({
  onSave,
  onChange,
  initialValues,
}) => {
  const [scaleValue, setScaleValue] = useState<string>("1");
  const [xRatioValue, setXRatioValue] = useState<string>("0");
  const [yRatioValue, setYRatioValue] = useState<string>("0");
  const [zRatioValue, setZRatioValue] = useState<string>("0");

  const formik = useFormik({
    initialValues: getInitialValues(initialValues),
    validationSchema,
    onSubmit: (values) => {
      const processedValues = processValues(values);
      onSave(processedValues);
    },
  });

  useEffect(() => {
    if (onChange && formik.isValid && !formik.isSubmitting) {
      const debouncedChange = setTimeout(() => {
        const processedValues = processValues(formik.values);
        onChange(processedValues);
      }, 100);
      return () => clearTimeout(debouncedChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, formik.isSubmitting, formik.values]);

  const handleAddressSelect = (
    address: string,
    longitude: number,
    latitude: number
  ) => {
    formik.setFieldValue("address", address);
    formik.setFieldValue("longitude", longitude);
    formik.setFieldValue("latitude", latitude);
  };

  const handleInputChange = (
    name: InputFieldNameValue,
    value: string | number
  ) => {
    return formik.setFieldValue(name, String(value));
  };

  const {
    suggestions,
    showSuggestions,
    suggestionsRef,
    handleAddressChange,
    handleSuggestionSelect,
  } = useAddressSuggestions({
    onSelect: handleAddressSelect,
  });

  return (
    <HouseSettingsContainer>
      <form onSubmit={formik.handleSubmit}>
        <HouseSettingsSection>
          <HouseSettingsText style={{ marginBottom: "6px" }}>
            House Settings
          </HouseSettingsText>
        </HouseSettingsSection>
        <HouseSettingsSection>
          <div ref={suggestionsRef} style={{ position: "relative" }}>
            <CustomInput
              className="input-container"
              heading="Address"
              name="address"
              value={formik.values.address}
              onChange={(value) => {
                formik.setFieldValue("address", value);
                handleAddressChange(value);
              }}
              type="text"
              error={formik.touched.address ? formik.errors.address : ""}
            />
            {showSuggestions && suggestions.length > 0 && (
              <SuggestionContainer>
                {suggestions.map((suggestion) => (
                  <SuggestionItem
                    key={suggestion.id}
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion.fullAddress}
                  </SuggestionItem>
                ))}
              </SuggestionContainer>
            )}
          </div>
          <CustomInput
            className="input-container"
            heading="Longitude"
            name="longitude"
            value={formik.values.longitude}
            onChange={(value) =>
              handleInputChange(InputFieldNameValue.LONGITUDE, value)
            }
            type="number"
            error={formik.touched.longitude ? formik.errors.longitude : ""}
          />
          <CustomInput
            className="input-container"
            heading="Latitude"
            name="latitude"
            value={formik.values.latitude}
            onChange={(value) =>
              handleInputChange(InputFieldNameValue.LATITUDE, value)
            }
            type="number"
            error={formik.touched.latitude ? formik.errors.latitude : ""}
          />
        </HouseSettingsSection>
        <HouseSettingsHr />

        <HouseSettingsSection>
          <CustomInput
            className="input-container"
            heading="Scale"
            name="scale"
            value={formik.values.scale}
            type="number"
            error={formik.touched.scale ? formik.errors.scale : ""}
            onChange={(value) => {
              handleInputChange(InputFieldNameValue.SCALE, value);
              setScaleValue(value);
            }}
            onFocusOut={() =>
              handleInputChange(
                InputFieldNameValue.SCALE,
                scaleValue.length ? scaleValue : InputFieldDefaultValue.SCALE
              )
            }
          />

          <HouseSettingsText style={{ marginBottom: "6px", marginTop: "6px" }}>
            Model position
          </HouseSettingsText>
          <HouseSettingsRotate>
            <CustomInput
              className="input-container"
              heading="X"
              name="rotateX"
              value={formik.values.rotateX}
              type="number"
              onChange={(value) => {
                handleInputChange(InputFieldNameValue.ROTATE_X, value);
                setXRatioValue(value);
              }}
              onFocusOut={() =>
                handleInputChange(
                  InputFieldNameValue.ROTATE_X,
                  xRatioValue.length
                    ? xRatioValue
                    : InputFieldDefaultValue.ROTATE_X
                )
              }
            />
            <CustomInput
              className="input-container"
              heading="Y"
              name="rotateY"
              value={formik.values.rotateY}
              type="number"
              onChange={(value) => {
                handleInputChange(InputFieldNameValue.ROTATE_Y, value);
                setYRatioValue(value);
              }}
              onFocusOut={() =>
                handleInputChange(
                  InputFieldNameValue.ROTATE_Y,
                  yRatioValue.length
                    ? yRatioValue
                    : InputFieldDefaultValue.ROTATE_Y
                )
              }
            />
            <CustomInput
              className="input-container"
              heading="Z"
              name="rotateZ"
              value={formik.values.rotateZ}
              type="number"
              onChange={(value) => {
                handleInputChange(InputFieldNameValue.ROTATE_Z, value);
                setZRatioValue(value);
              }}
              onFocusOut={() =>
                handleInputChange(
                  InputFieldNameValue.ROTATE_Z,
                  zRatioValue.length
                    ? zRatioValue
                    : InputFieldDefaultValue.ROTATE_Z
                )
              }
            />
          </HouseSettingsRotate>
        </HouseSettingsSection>
      </form>
    </HouseSettingsContainer>
  );
};

export default HouseSettings;