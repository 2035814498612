import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";

export const getLineChartData = (
  currentWidget: WidgetItem,
  chartSuggestion?: any
) => {
  const data = currentWidget?.data || [];

  const xAxe = currentWidget?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];

  const xAxes =
    currentWidget?.uniqueValues?.[xAxe] ||
    Array.from(new Set(data.map((d: any) => d[xAxe] as string))) ||
    [];

  const sortedData = xAxes.flatMap((key: string) =>
    data.filter((d: any) => d[xAxe] === key)
  );

  return sortedData;
};

export const getGroupedData = (lineChartData: any, groupKey: any) => {
  return lineChartData?.reduce((acc: any, cur: any) => {
    acc[cur[groupKey[0]]] = [...(acc[cur[groupKey[0]]] || []), cur];
    return acc;
  }, {});
};

export const getLineChartMarkers = (currentWidget: any): IMarkersType[] => {
  const lineChartData = getLineChartData(currentWidget);

  const groupKey = currentWidget?.groupBy;

  if (groupKey) {
    const groupedData = getGroupedData(lineChartData, groupKey);
    if (!groupedData) {
      return [{ key: "default", shape: "donut" }];
    }
    const chartGroupMarkers: IMarkersType[] = Object.keys(groupedData).map(
      (item) => ({ key: item, shape: "donut" })
    );

    return chartGroupMarkers;
  } else {
    return [{ key: "default", shape: "donut" }];
  }
};

export const getLineChartColors = (currentWidget: any): IFormatting[] => {
  const lineChartData = getLineChartData(currentWidget);

  const groupKey = currentWidget?.groupBy;

  if (groupKey) {
    const groupedData = getGroupedData(lineChartData, groupKey);
    if (!groupedData) {
      return [{ key: "default", color: "1" }];
    }
    const chartGroupFormatting: IFormatting[] = Object.keys(groupedData).map(
      (item, index) => ({ key: item, color: String(index + 1) })
    );

    return chartGroupFormatting;
  } else {
    return [{ key: "default", color: "1" }];
  }
};
