import {
    ModalTitle,
    ModalDescription
} from "./styles";

const AiChatInitialView = () => {
    return <>
        <ModalTitle>
            Hi Robert! 
        </ModalTitle>
        <ModalDescription>
            I'm Fuse Assistant, your personal Chat Bot Assistant. 
            <br />
            How can I help you?
        </ModalDescription>
    </> 
};

export default AiChatInitialView;