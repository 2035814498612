import {
  MarkersVisualisationDataDto,
  TimePeriod,
} from "../../../../models/Widgets";

export function getYears(
  layerData: MarkersVisualisationDataDto[] | undefined,
  timePeriod: TimePeriod | undefined,
  setYear: React.Dispatch<React.SetStateAction<string | undefined>>,
  setYears: React.Dispatch<React.SetStateAction<[] | string[]>>
) {
  const field: keyof MarkersVisualisationDataDto =
    (timePeriod?.field as keyof MarkersVisualisationDataDto) || "year";
  const values = timePeriod?.values;
  if (values?.length) {
    setYear(values[0]);
    setYears(values || []);
  }
  const mapedYears = layerData?.reduce(
    (t: string[], l: MarkersVisualisationDataDto) => {
      const value = l[field];
      if (value && !t.includes(value.toString())) {
        return [value.toString(), ...t];
      }
      return t;
    },
    []
  );
  const years = mapedYears?.map((y) => y)?.filter((y) => y && y?.length);
  years?.sort();
  if (years?.length) {
    setYear(years[0]);
    setYears(years || []);
  }
}
