import { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import useMeasure from "react-use-measure";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import {
  calculateLabelLength,
  calculateNumTicks,
  getScaleLinearTickValues,
} from "../widgetHelpers";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { TickLabel } from "../components/LabelTooltip";
import { ChartLegendValue } from "../../ChartLegend";
import { getBarChartKeys } from "../GroupedBarChart/utils/getKeys";
import { createPortal } from "react-dom";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";
import { getActiveModal } from "../../../store/selectors/modals";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";

interface GroupedBarChartInterface {
  storytelling?: boolean;
  recommended?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

export const HorizontalBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: GroupedBarChartInterface) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const [ref, bounds] = useMeasure();
  const [refWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<string | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : barChartSuggestion?.xAxe?.at(0);
  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : barChartSuggestion?.yAxe?.at(0);

  const barchartData = currentWidget?.data;
  const isLongValue = barchartData?.some((el) => el[xAxe].length > 6);
  const margin = {
    top: 10,
    right: 10,
    bottom: barchartData?.length! > 7 ? -4 : 20,
    left: isLongValue ? 80 : 40,
  };

  const legendValues: ChartLegendValue[] = [];

  const groupBy = currentWidget?.groupBy?.at(0);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  for (let i = 0; i < keys?.length; i++) {
    const dataKey = keys?.at(i);
    legendValues.push({
      label: dataKey!,
      color: getCurrentColor(currentWidget, dataKey!, styleId),
    });
  }

  const maxXAxe = useMemo(
    () => Math.max(...(barchartData?.map((d) => d[yAxe]) || [])),
    [barchartData, yAxe]
  );

  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const yAxes: string[] = useMemo(
    () =>
      currentWidget?.uniqueValues?.[xAxe] ||
      barchartData?.map((d: any) => d[xAxe]) ||
      [],
    [barchartData, currentWidget?.uniqueValues, xAxe]
  );

  const xScale = scaleLinear<any>({
    nice: true,
  })
    .domain(barchartData ? [0, roundedMaxXAxe] : [])
    .range([margin.left, width - margin.right]);

  const yScale = scaleBand()
    .domain(yAxes)
    .range([margin.top, height - margin.bottom])
    .padding(0)
    .align(0.5);

  const yScaleNumTicks = calculateNumTicks({ height: height });

  const xTicks = xScale.ticks();

  const xScaleNumTicksCalculated = calculateNumTicks({ width });

  const xScaleNumTicks =
    xScaleNumTicksCalculated <= xTicks.length
      ? xScaleNumTicksCalculated
      : xTicks.length;

  const xScaleTickValues = useMemo(
    () =>
      getScaleLinearTickValues({
        scale: xScale,
        tickCount: xScaleNumTicks,
      }),
    [xScale, xScaleNumTicks]
  );

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: ticksFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);
  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );

  if (!barchartData || !barchartData.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const h = yScale.bandwidth();
  const barHeight = Math.min(h < 16 ? 16 : h, 32);
  const h2 = barchartData.length * (barHeight + 3);
  const svgHeightCalc = h2 < (refHeight || 0) ? refHeight : h2;

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings &&
              !isPublicRoute &&
              !recommended &&
              isEditMode &&
              !storytelling ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {AVAILABLE_WIDGETS["barChart"]?.length}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
        </HeaderWrapper>
        <div
          ref={divRef}
          style={
            barchartData.length > 7
              ? {
                  height: refHeight ? refHeight - 16 * 2 : 160,
                  overflowY: "auto",
                }
              : { height: "100%" }
          }
        >
          <svg
            width="100%"
            height={barchartData.length > 7 ? svgHeightCalc : "100%"}
            ref={ref}
          >
            <Group>
              {/* Right Dashed Line */}
              <line
                x1={width - margin.right}
                x2={width - margin.right}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
              {yAxes.map((d, idx) => (
                <line
                  key={idx}
                  x1={margin.left}
                  x2={width - margin.right}
                  y1={yScale(d)!}
                  y2={yScale(d)!}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
              {xScaleTickValues.map((tick: number, idx: number) => (
                <line
                  key={idx}
                  x1={xScale(tick)}
                  x2={xScale(tick)}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            {!(barchartData.length > 7) && (
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideTicks
                numTicks={calculateNumTicks({ width })}
                tickFormat={(value: any) => {
                  return ticksFormatter(value);
                }}
                tickLabelProps={(_, index, values) => {
                  const isFirstTick = index === 0;
                  const isLastTick = index === values.length - 1;
                  const textAnchor =
                    (isFirstTick && "start") ||
                    (isLastTick && "end") ||
                    "middle";

                  return {
                    fontSize: 10,
                    fill: "#5F6877",
                    textAnchor: textAnchor,
                    dx: 0,
                  };
                }}
                tickValues={xScaleTickValues}
                tickComponent={(props: any) => (
                  <TickLabel
                    {...props}
                    length={xScaleTickLabelMaxLength}
                    offsetX={-10}
                  />
                )}
                axisLineClassName="barchartAxisLine"
              />
            )}
            <AxisLeft
              scale={yScale}
              top={0}
              hideAxisLine
              left={margin.left}
              tickLineProps={{
                stroke: "#939BA7",
              }}
              numTicks={
                barchartData.length > 7 ? barchartData.length : yScaleNumTicks
              }
              tickLabelProps={() => {
                return {
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "start",
                  dominantBaseline: "middle",
                  dx: isLongValue ? -80 : -40,
                };
              }}
              tickComponent={(props) => (
                <TickLabel
                  {...props}
                  x={0}
                  length={isLongValue ? 10 : 4}
                  offsetX={-10}
                />
              )}
            />
            <Group>
              {barchartData?.map((d, idx) => {
                const barWidth = (xScale(d[yAxe]) as any) - margin.left;
                const legend = legendValues.find(
                  (r) => r.label === d[xAxe]
                ) || {
                  color: "#7BA4B7",
                };
                const color = getCurrentColor(
                  currentWidget,
                  d[groupBy!] ?? "default",
                  styleId
                );

                const key = `Bar-${idx}`;
                return (
                  <Bar
                    key={key}
                    x={margin.left}
                    style={{
                      transition: "0.2s",
                      opacity: hoveredElement
                        ? hoveredElement === key
                          ? 1
                          : 0.2
                        : 1,
                    }}
                    y={
                      yScale(d[xAxe])! +
                      (yScale.bandwidth() - barHeight) / 2 +
                      2
                    }
                    width={barWidth > 0 ? barWidth : 0}
                    height={barHeight - 3}
                    fill={color ? hexToRGBA(color, 0.8) : legend.color || ""}
                    onMouseMove={(event: any) => {
                      if (
                        (showTooltip || currentWidget.tooltip) &&
                        !recommended
                      ) {
                        const { pageX, pageY, clientX, clientY } = event;
                        const coords = { pageX, pageY, clientX, clientY };

                        setHoveredElement(key);
                        setTooltip({
                          name: d[groupBy!] ?? undefined,
                          data: d,
                          coords,
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltip(null);
                      setHoveredElement(null);
                    }}
                  />
                );
              })}
            </Group>
          </svg>
        </div>
        {barchartData.length > 7 && (
          <svg height={"25"} width={"100%"}>
            <AxisBottom
              scale={xScale}
              top={1}
              hideTicks
              numTicks={calculateNumTicks({ width })}
              tickFormat={(value: any) => {
                return ticksFormatter(value);
              }}
              tickLabelProps={(_, index, values) => {
                const isFirstTick = index === 0;
                const isLastTick = index === values.length - 1;
                const textAnchor =
                  (isFirstTick && "start") || (isLastTick && "end") || "middle";

                return {
                  fontSize: 10,
                  fill: "#5F6877",
                  textAnchor: textAnchor,
                  dx: 0,
                };
              }}
              tickValues={xScaleTickValues}
              tickComponent={(props: any) => (
                <TickLabel
                  {...props}
                  length={xScaleTickLabelMaxLength}
                  offsetX={-10}
                />
              )}
              axisLineClassName="barchartAxisLine"
            />
          </svg>
        )}

        {tooltip &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
