import { useState } from 'react';
import { ViewState } from 'react-map-gl';
import { HouseSettingsProps } from '../../Modals/PositionHouseModal/components/HouseSettings';
import { DEFAULT_BEARING, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, DEFAULT_PITCH, DEFAULT_ZOOM } from '../constants';

export const useMapState = (houseSettings?: Omit<HouseSettingsProps, "zpId" | "address">) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [viewState, setViewState] = useState<Partial<ViewState>>({
    latitude: houseSettings?.latitude || DEFAULT_LATITUDE,
    longitude: houseSettings?.longitude || DEFAULT_LONGITUDE,
    zoom: DEFAULT_ZOOM,
    pitch: DEFAULT_PITCH,
    bearing: DEFAULT_BEARING,
  });

  return {
    isMapLoaded,
    setIsMapLoaded,
    viewState,
    setViewState,
  };
};
