import { styled } from "styled-components";
import { Done, XPath, CentralColumn } from "./assets/styles";

export const ChartBackground  = styled.div`
  background-color: #ffffff;
  background-image: radial-gradient(#D7D7D8 1px, transparent 1px);
  background-size: 3px 3px; 
  padding: 23px 0 47px;
  /* height: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder}; 
`

export const FeedBackBlock = styled.div`
  padding: 20px;
`

export const FeedBackHeadingWrapper = styled.div`
  margin-bottom: 16px;
`

export const FeedBackHeader = styled.p`
  color: #000417;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
`

export const FeedBackSubHead = styled.p`
  color: #2E3849;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const FeedBackOptions = styled.div`
  display: flex;
  gap: 16px; 
`

export const FeedBackOptionText = styled.p`
  color: #000417;
  text-align: center;
  font-size:16px;
  font-weight: 400;
  line-height: 24px;
`

export const FeedBackOption = styled.div<{$active: boolean}>`
  position: relative;
  transition: 0.3s;
  display: flex;
  flex:1;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  align-items:center;
  align-items: center;
  background-color: #9FA4AD14;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #CFD2D6;
    background: transparent;
    ${Done} {
      fill: #138660;
    }
    ${XPath} {
      fill: #DD2E43;
    }
    ${CentralColumn} {
      fill: #138660;
    }
  }
  ${({$active}) => $active && `
    background-color: #6F6FE90A !important;
    border: 1px solid #6F6FE9 !important;

    ${FeedBackOptionText} {
      color: #141BB1;
      font-weight: 600;
    }

    &::before{
      border-radius: 8px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid #DEDDFE;
      pointer-events: none;
      z-index: 1;
    }
  `}
  

`

export const NegativeOption = styled.div`
  margin-top: 16px;
`

export const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`