import styled from "styled-components";

import { fadeInDown } from "../../../styles";
import {
  ExclamationCircleFill,
  StarFill,
  ThreeDotsVertical
} from "react-bootstrap-icons";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  display: flex;
  align-items: center;
  z-index: 999;
  opacity: 0;
  animation: 0.4s ${fadeInDown} ease forwards;
`;

export const ModalWrapper = styled.div<{$isSelectedDatasetPreview?: boolean}>`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  ${({$isSelectedDatasetPreview}) => !$isSelectedDatasetPreview && `
    padding: 20px 20px 12px 20px;
  `}
  height: 97%;
  position: absolute;
  right: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeadingWrapper = styled.div<{$isSelectedDatasetPreview?: boolean}>`
  ${({$isSelectedDatasetPreview}) => $isSelectedDatasetPreview && `
    padding: 20px 20px 0 20px;
  `}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalHeadingNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
`;
export const ModalHeading = styled.h2`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;

export const SelectedDataSets = styled.div`
  height: 100%;
  width:350px;
  border-right: 1px solid ${({theme}) => theme.neutralBorder};
  display: flex;
  flex-direction: column;  
  gap: 15px;
`
export const SelectedDataSetsList = styled.div`
  height: 100%;
  overflow-y: auto;
  width:350px;
  padding-top: 8px;
  border-right: 1px solid ${({theme}) => theme.neutralBorder};
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  gap: 15px;
`
export const DatasetsButtons = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 12px 12px 0;
`
export const DatasetsItemWrapper = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 5px;
`
export const ContentContainer = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`
export const TablesContainer = styled.div`
  width: 100%; 
  overflow-x: auto;
`

export const Separator = styled.div<{
  $top?: boolean;
  $bottom?: boolean;
}>`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
  position: absolute;
  top: ${({ $top }) => $top && "68px"};
  bottom: ${({ $bottom }) => $bottom && "60px"};
  left: 0;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 20px;
  margin-top: 12px;
  overflow: auto;
  position: relative;
  scrollbar-width: thin;
`;

export const TablesWrapper = styled.div<{$noBorder?: boolean}>`
  ${({$noBorder}) => !$noBorder && `
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #DFE1E4;
  `}
  display: flex;
  width: 100%;
  position: relative;
`

export const Table = styled.div<{$pined?: boolean; $row?: boolean}>`
  position: relative;
  display: flex;
  ${({$row}) => $row? `
    flex-direction: row;
    `:`
    flex-direction: column;
  `}
  scrollbar-width: thin;
  overflow-x: auto;
  ${({$pined}) => !$pined && `
    width:100%
  `}
  ;
  ${({$pined}) => $pined && `
    border-right: 1px solid #DFE1E4; 
    overflow-x: visible
  `};
`

export const TableHeading = styled.div<{$width?: number}>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #2E3849;
  ${({$width}) => $width? `
    width: ${$width}px;
    ` : `
    width: 195px;
  `}
  overflow: hidden;
  padding: 12px 16px;
  background-color: #9FA4AD14;
  position: relative;

  &::before {
    content: "";
    width: 1px;
    height: 17px;
    background-color: #DFE1E4;
    position: absolute;
    right:0;

  }
`;

export const TableRow = styled.div`
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #000417;
`;

export const StarIcon = styled(StarFill)`
  width: 10px;
  height: 10px;
  margin: 2px 0 0 2px;
  fill: #6A1700;
`

export const Primary = styled.div`
  width: 24px;
  height: 24px; 
  background: linear-gradient(135deg, #FFC566 50%, transparent 50%);
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
`;

export const HeadingIcon = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
`;

export const CheckBoxWrapper = styled.div`
  margin-right: 8px;
`

export const HeaderRow = styled.div`
  display: flex;
  width: max-content;
  border-bottom: 1px solid #DFE1E4;
`

export const TableRowDataSet = styled.div`
  display: flex;
  width: max-content;
  flex-direction: row;
  
  &:hover {
    background: #9FA4AD1F;
  }
`

export const TableRowCell = styled.div<{$width?: number}>`
  ${({$width}) => $width? `
    width: ${$width}px;
    ` : `
    width: 195px;
  `}
  padding: 12px 16px;
  border-bottom: 1px solid #DFE1E4;
  font-weight: 400;
  font-size: 14px;
  color: #000417;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height:60px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 3px;
  margin-left: auto;
  align-items: center;
`

export const More = styled(ThreeDotsVertical)`
  cursor: pointer;
`

export const HeadingText = styled.div`
  font-size: 14px;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled.img``;

export const ErrorIcon = styled(ExclamationCircleFill)`
  fill: #EE96A1;
`;

export const ErrorMessage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  color: white;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: #DD2E43;
  border-radius: 32px;
  padding: 0px 4px 0 2px;
`

export const TotalFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.primaryText};
  font-size: 12px;
  font-weight: 500;
`;

export const DatasetOverviewLoaderWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
`;

export const TableColumn = styled.div<{
  $errorDetected?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
`;