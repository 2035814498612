import { styled } from "styled-components";

export const ItemWrapper = styled.div<{$isActive?: boolean}>`
  padding: 12px 20px 12px 20px;
  ${({$isActive}) => $isActive && `
    background-color: #E8E8FB;
  `}
`

export const DatasetName = styled.p <{$isOpen?: boolean}>`
  padding-left: 24px;
  color: #000417;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
  &::before {
    transition: 0.3s;
    content: '';
    position: absolute;
    border-style: solid;
    left: 9px;
    top: 7px;
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #7F8692 transparent;
    width: 0;
    height: 0;
    ${({$isOpen}) => $isOpen ? `
      transform: rotateZ(180deg);
      ` : `
      transform: rotateZ(90deg);
      `
    }
}
`

export const DatasetDimensions = styled.div`
  margin-top:5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
`