import { FC } from "react";
import {
  FLContainer,
  FLContent,
  FLLabel,
  FLLabelDetails,
  FLRow,
  FLTitle,
} from "./styles";
import { Property } from "../../../../models/RealEstate";

interface Props {
  data: Property | null;
}

const formatPrice = (price: number | undefined) => {
  if (!price) return 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

const FinancialAndListing: FC<Props> = ({ data }) => {
  if (!data) return null;

  const pricePerSqFt = Math.round(data?.price / data?.livingArea);
  const financialData = [
    {
      label: "List Price",
      details: formatPrice(data?.price)
    },
    {
      label: "Price/Sq.Ft.",
      details: formatPrice(pricePerSqFt)
    },
    {
      label: "Monthly HOA Fee",
      details: formatPrice(data?.monthlyHoaFee)
    },
    {
      label: "Property Tax Rate",
      details: data?.propertyTaxRate ? `${data.propertyTaxRate}%` : 'N/A'
    },
    {
      label: "Annual Insurance",
      details: formatPrice(data?.annualHomeownersInsurance)
    },
    {
      label: "Zestimate",
      details: formatPrice(data?.zestimate)
    },
    {
      label: "Rent Zestimate",
      details: formatPrice(data?.rentZestimate)
    }
  ];

  const listingData = [
    {
      label: "Status",
      details: data?.homeStatus
    },
    {
      label: "Days on Zillow",
      details: data?.daysOnZillow
    },
    {
      label: "Property Type",
      details: data?.homeType
    },
    {
      label: "Year Built",
      details: data?.yearBuilt
    },
    {
      label: "Living Area",
      details: data?.livingArea ? `${data.livingArea} ${data.livingAreaUnits}` : 'N/A'
    },
    {
      label: "Bedrooms",
      details: data?.bedrooms
    },
    {
      label: "Bathrooms",
      details: data?.bathrooms
    },
    {
      label: "MLS ID",
      details: data?.mlsid
    },
    {
      label: "Agent",
      details: data?.attributionInfo?.agentName
    },
    {
      label: "Brokerage",
      details: data?.attributionInfo?.brokerName
    },
    {
      label: "Listed Date",
      details: data?.datePostedString
    }
  ];

  return (
    <FLContainer>
      <FLTitle>Financial & Listing Details</FLTitle>
      <FLContent>
        <div>
          {financialData.map((data) => (
            <FLRow key={data.label}>
              <FLLabel>{data.label}</FLLabel>
              <FLLabelDetails>{data.details}</FLLabelDetails>
            </FLRow>
          ))}
        </div>
        <div>
          {listingData.map((data) => (
            <FLRow key={data.label}>
              <FLLabel>{data.label}</FLLabel>
              <FLLabelDetails>{data.details}</FLLabelDetails>
            </FLRow>
          ))}
        </div>
      </FLContent>
    </FLContainer>
  );
};

export default FinancialAndListing;