import { PayloadAction } from "@reduxjs/toolkit";
import { ServerResponse } from "../../models";
import { call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { FeedBackState } from "../slices/feedback";
import { createFeedback } from "../../api/feedback";

export function* handleCreateFeedBack({ payload }: PayloadAction<FeedBackState>) {
  
  const { response, error }: ServerResponse = yield call(createFeedback, payload);
  
  if (response?.status === 200 || response?.status === 201) {
    payload?.callbacks?.onSuccess();
    toast.success("Feedback created successfully");
  }
}

export function* watchFeedBackActions() {
  yield takeLatest("feedback/requestCreateFeedBack", handleCreateFeedBack);
}