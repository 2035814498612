import {
  AiChatLoaderWrapper,
  AiChatLoaderIcon
} from "./styles";

const AiChatLoader = () => {
    return (
        <AiChatLoaderWrapper>
            <AiChatLoaderIcon />
            Reading data...
        </AiChatLoaderWrapper>
    )
}

export default AiChatLoader;