import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import {
  CheckBoxWrapper,
  DatasetDimensionsCount,
  DatasetNameSeparator,
  Dimension,
  Dimensions,
  FileName,
  LabelText,
  More,
  Provider,
  RowWrapper,
  UploadedOn,
  UploadedOnTime,
} from "./styles";
import {
  Category,
  DateTime,
  Location,
  Meta,
  MetricValue,
  Text,
} from "../../../../assets";
import { setActiveModal } from "../../../../store/slices/modals";
import {
  requestDatasetRemove,
  setDatasetPreview,
} from "../../../../store/slices/datasets";
import { NewDatasetDTO } from "../../../../models/Datasets";
import { Submenu } from "../../../Submenu";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import { useLocation, useNavigate } from "react-router-dom";

interface NewDataSetDtoCustom extends NewDatasetDTO {
  selectAll?: boolean;
}

export const ResourceRow = (dataset: NewDataSetDtoCustom) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [checked, setChecked] = useState<boolean | undefined>(false);
  const [showAllDimensions, setShowAllDimensions] = useState<boolean>(false);

  const { name, createdAt, fields, id, selectAll } = dataset;

  const openModal = (dataset: NewDatasetDTO) => {
    dispatch(setDatasetPreview(dataset));
    if (pathname.includes("public-resources")) {
      return navigate("/resources/public-resources/" + dataset.id);
    }
    dispatch(setActiveModal({ id: "datasetOverviewModal" }));
  };

  const dimensionsToShow = () => {
    if (showAllDimensions) return fields;
    if (!showAllDimensions) return fields.slice(0, 6);
  };

  useEffect(() => {
    setChecked(selectAll);
  }, [selectAll]);

  return (
    <RowWrapper onMouseLeave={() => setShowAllDimensions(false)}>
      <CheckBoxWrapper>
        <CheckBox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </CheckBoxWrapper>
      <FileName>{name}</FileName>
      <Dimensions>
        {dimensionsToShow()!.map((dimension, idx) => {
          const iconSrc = (d: any) => {
            if (d) {
              switch (true) {
                case d.type === "text":
                  return Text;
                case d.type === "metric":
                  return MetricValue;
                case d.type === "location":
                  return Location;
                case d.type === "datetime":
                  return DateTime;
                case d.type === "categorical":
                  return Category;
                case d.type === "meta":
                  return Meta;
                default:
                  return Category;
              }
            }
          };
          return (
            <Dimension key={idx}>
              <img src={iconSrc(dimension)} alt="dimension icon" />
              <DatasetNameSeparator />
              <LabelText>{dimension.label}</LabelText>
            </Dimension>
          );
        })}
        {Math.sign(fields.length - 6) &&
        fields.length - 6 > 0 &&
        !showAllDimensions ? (
          <DatasetDimensionsCount onClick={() => setShowAllDimensions(true)}>
            +{fields.length - 6}
          </DatasetDimensionsCount>
        ) : (
          ""
        )}
      </Dimensions>
      <UploadedOn>
        {format(new Date(createdAt!), "MM.dd.yyyy")}
        <UploadedOnTime>
          {format(new Date(createdAt!), "HH:mm a")}
        </UploadedOnTime>
      </UploadedOn>
      <Provider>
        {/* svg should be replaced with our design system icons */}
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 2.00195C6.49445 2.00195 6.9778 2.14858 7.38893 2.42328C7.80005 2.69798 8.12048 3.08843 8.3097 3.54524C8.49892 4.00206 8.54843 4.50473 8.45196 4.98968C8.3555 5.47463 8.1174 5.92009 7.76777 6.26972C7.41814 6.61935 6.97268 6.85745 6.48773 6.95392C6.00277 7.05038 5.50011 7.00087 5.04329 6.81165C4.58648 6.62243 4.19603 6.302 3.92133 5.89088C3.64662 5.47976 3.5 4.99641 3.5 4.50195C3.5 3.83891 3.76339 3.20303 4.23223 2.73419C4.70107 2.26535 5.33696 2.00195 6 2.00195ZM6 1.00195C5.30777 1.00195 4.63108 1.20722 4.0555 1.59181C3.47993 1.97639 3.03133 2.52302 2.76642 3.16256C2.50151 3.8021 2.4322 4.50584 2.56725 5.18477C2.7023 5.8637 3.03564 6.48734 3.52513 6.97683C4.01461 7.46631 4.63825 7.79965 5.31718 7.9347C5.99612 8.06975 6.69985 8.00044 7.33939 7.73553C7.97893 7.47062 8.52556 7.02202 8.91014 6.44645C9.29473 5.87088 9.5 5.19419 9.5 4.50195C9.5 3.5737 9.13125 2.68346 8.47487 2.02708C7.8185 1.3707 6.92826 1.00195 6 1.00195Z"
            fill="#2C33C8"
          />
          <path
            d="M11 15.002H10V12.502C10 11.8389 9.73661 11.203 9.26777 10.7342C8.79893 10.2653 8.16304 10.002 7.5 10.002H4.5C3.83696 10.002 3.20107 10.2653 2.73223 10.7342C2.26339 11.203 2 11.8389 2 12.502V15.002H1V12.502C1 11.5737 1.36875 10.6835 2.02513 10.0271C2.6815 9.3707 3.57174 9.00195 4.5 9.00195H7.5C8.42826 9.00195 9.3185 9.3707 9.97487 10.0271C10.6313 10.6835 11 11.5737 11 12.502V15.002Z"
            fill="#2C33C8"
          />
          <path
            d="M12.5 8.09195L11.205 6.79695L10.5 7.50195L12.5 9.50195L16 6.00195L15.295 5.29695L12.5 8.09195Z"
            fill="#2C33C8"
          />
        </svg>
        {/* svg should be replaced with our design system icons */}
        My data
      </Provider>
      <More onClick={() => setMenuOpen((prevState) => !prevState)} />
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(id))}
          onPreview={() => openModal(dataset)}
          preview
          share
          download
        />
      )}
    </RowWrapper>
  );
};
