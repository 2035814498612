import React, { useState } from "react";

import {
  CheckboxLabel,
  CheckboxWrapper,
  EditButton,
  InputField,
  KpiSettingItem,
  KpiSettingItemName,
} from "./styles";
import { AiKpiDto } from "../../models/Widgets";
import { CheckLg, Pencil } from "react-bootstrap-icons";
import { KPI_TYPES, KPIComponentWrapper } from "../KPIComponentWrapper";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";

type Props = {
  selectedKpis: string[];
  kpi: AiKpiDto;
  handleChangeName: (name: string) => void;
  selectedKpi: () => void;
  nrAvailbleKpi: number;
};

export const KpiSettingsItem: React.FC<Props> = ({
  selectedKpis,
  kpi,
  handleChangeName,
  selectedKpi,
  nrAvailbleKpi,
}: Props) => {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(kpi?.name);

  const kpiFormat =
    Number(
      [
        KPI_TYPES.HIGH_LOW?.toString(),
        KPI_TYPES.DISTINCTION?.toString(),
      ].includes(kpi.type)
    ) + 1;

  const isKpiItemSelected = selectedKpis?.includes(kpi.query);
  const isKpiItemActive = kpiFormat > nrAvailbleKpi && !isKpiItemSelected;

  return (
    <KpiSettingItem>
      <KpiSettingItemName>
        <CheckboxWrapper className="interactivity">
          <CheckBox
            onChange={!isKpiItemActive ? selectedKpi : () => {}}
            disabled={isKpiItemActive}
            checked={selectedKpis?.includes(kpi.query)}
          />
          {edit ? (
            <InputField
              autoComplete="off"
              type="text"
              name="name"
              value={name}
              placeholder={"Name this KPI"}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <CheckboxLabel $disabled={isKpiItemActive}>{name}</CheckboxLabel>
          )}
        </CheckboxWrapper>
        {kpi.id ? (
          <>
            {edit ? (
              <EditButton
                onClick={() => {
                  setEdit(!edit);
                  handleChangeName(name);
                }}
              >
                <CheckLg />
              </EditButton>
            ) : (
              <EditButton onClick={() => setEdit(!edit)}>
                <Pencil />
              </EditButton>
            )}
          </>
        ) : null}
      </KpiSettingItemName>
      {selectedKpis?.includes(kpi.query) ? (
        <KPIComponentWrapper
          showNameBlock={false}
          kpi={kpi}
          recommended={false}
          selected={false}
        />
      ) : null}
    </KpiSettingItem>
  );
};
