import { Dispatch } from "react";
import { setActiveModal } from "../../../store/slices/modals";
import { AnyAction } from "redux";
import { WidgetItem } from "../../../models/Widgets";

interface IOpenFeedBackModal  {
  dispatch: Dispatch<AnyAction>; 
  currentWidget: WidgetItem; 
  image: any; 
  setFeedbackState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const openFeedBackModal = ({ dispatch, currentWidget, image, setFeedbackState}: IOpenFeedBackModal) => {
  dispatch(
    setActiveModal({
      id: "feedBackModal",
      data: {
        sizes: [0, 0],
        component: currentWidget,
        photo: image,
      },
    })
  );
  setFeedbackState(false);
};