import React from 'react';
import { XLg } from "react-bootstrap-icons";
import { LocalFile } from '../ChatPanel';

import {
    FileContainer,
    FileItem,
    FileDeleteIconWrapper,
    FileName,
    FileExtension,
} from './styles';

interface Props {
    localFiles: LocalFile[];
    onFileDelete?: (id: number) => void;
}

const LIMIT = 3;

const FileList = (props: Props) => {
    const { localFiles, onFileDelete } = props;

    const [isExpanded, setIsExpanded] = React.useState(false); 

    const formattedFiles = React.useMemo(
        () => localFiles.slice(0, isExpanded ? localFiles.length : LIMIT), [localFiles, isExpanded]
    );

    const isExpandedBtnVisible = (localFiles.length > LIMIT) && !isExpanded;

    return (
        <FileContainer $isExpanded={isExpanded}>
            { 
                formattedFiles.map((formattedFile) => (
                    <FileItem key={formattedFile.meta.id}>
                        <FileExtension>
                            { formattedFile.meta.extension }
                            </FileExtension>
                        <FileName>
                            { formattedFile.file.name }
                        </FileName>
                        {
                            !!onFileDelete && (
                                <FileDeleteIconWrapper 
                                    className="file-close-btn" 
                                    onClick={() => onFileDelete(formattedFile.meta.id)}
                                >
                                    <XLg />
                                </FileDeleteIconWrapper>
                            )
                        }
                    </FileItem>
                )) 
            }
            {
                isExpandedBtnVisible && (
                    <FileItem onClick={() => setIsExpanded(true)}>
                        {`+${localFiles.length - LIMIT}`}
                    </FileItem>
                )
            }
        </FileContainer>
    )
}

export default FileList;