import { AnyAction } from "redux";
import { DatasetFiledDTO } from "../../../models/Datasets";
import { FileLibraryItem, ParsedSuggestions } from "../../../models/Files";
import {
  requestGetAllDatasets,
  requestUpdateDatasetById,
  setDatasetPreview,
  setNewProjectSelectedFiles,
  updateDatasetHeader,
} from "../../../store/slices/datasets";
import { updateFileData } from "../../../store/slices/files";
import {
  IEditDimension,
  IPinColumn,
  IPrimaryColumn,
  IPrimaryHandle,
  ISaveField,
  ISorting,
} from "../types/types";

export const sortTableDescending = ({
  key,
  subtype,
  selectedDatasetContents,
  setSelectedDatasetContents,
  data,
}: ISorting) => {
  setSelectedDatasetContents(() => {
    switch (subtype) {
      case "percentage":
      case "float":
      case "currency":
      case "integer":
        return (data || selectedDatasetContents).sort(
          (a: any, b: any) => Number(a[key]) - Number(b[key])
        );
      case "date":
        return (data || selectedDatasetContents).sort((a: any, b: any) => {
          const first = Math.floor(new Date(a[key]).getTime() / 1000);
          const second = Math.floor(new Date(b[key]).getTime() / 1000);
          return first - second;
        });
      default:
        return (data || selectedDatasetContents).sort((a: any, b: any) =>
          a[key].localeCompare(b[key])
        );
    }
  });
};

export const sortTableAscending = ({
  key,
  subtype,
  selectedDatasetContents,
  setSelectedDatasetContents,
  data,
}: ISorting) => {
  setSelectedDatasetContents(() => {
    switch (subtype) {
      case "percentage":
      case "float":
      case "currency":
      case "integer":
        return (data || selectedDatasetContents).sort(
          (a: any, b: any) => Number(b[key]) - Number(a[key])
        );
      case "date":
        return (data || selectedDatasetContents).sort((a: any, b: any) => {
          const first = Math.floor(new Date(a[key]).getTime() / 1000);
          const second = Math.floor(new Date(b[key]).getTime() / 1000);
          return second - first;
        });
      default:
        return (data || selectedDatasetContents).sort((a: any, b: any) =>
          b[key].localeCompare(a[key])
        );
    }
  });
};

export const tablePinColumn = ({
  key,
  pinedColumns,
  setPinedColumns,
}: IPinColumn) => {
  if (pinedColumns.includes(key)) {
    setPinedColumns(pinedColumns.filter((item) => item !== key));
  } else if (pinedColumns.length < 6) {
    setPinedColumns([...pinedColumns, key]);
  }
};

export const setAsPrimary = ({ key, fields = [] }: IPrimaryColumn) => {
  return fields.map((item: DatasetFiledDTO) => {
    if (item.key === key) {
      return { ...item, primary: !item.primary };
    }
    return item;
  });
};

export const resetAllColumns = ({ fields = [] }: IPrimaryColumn) => {
  return fields.map((item: DatasetFiledDTO) => {
    return { ...item, primary: false };
  });
};

export const handleResetAllColumns = ({
  dispatch,
  selectedDataset,
  key,
  count,
  changeCurrentDataset,
  newProjectSelectedDatasets
}: IPrimaryHandle) => {
  
  if(!selectedDataset) return;

  const id = (selectedDataset.id || selectedDataset.sourceId) ?? ""

  dispatch(
    requestUpdateDatasetById({
      id: id,
      fields: resetAllColumns({
        key,
        fields: selectedDataset.fields,
      }),
      callbacks: {
        onSuccess() {
          if(changeCurrentDataset) {
            dispatch(setDatasetPreview({...selectedDataset, fields: resetAllColumns({
              key,
              fields: selectedDataset.fields,
            })}))
          } else {

            dispatch(
              requestGetAllDatasets({
                orderBy: { createdAt: "DESC" },
                sourceType: "database",
                limit: count,
                callbacks: {
                  onSuccess: (data) => {
                    const updatedDataset = data.items.find(
                      (item: any) => item.id === id
                    );
                    dispatch(setDatasetPreview(updatedDataset));
                  },
                },
              })
            );
          }
        },
      },
    })
  );
};

export const handleSetAsPrimary = ({
  dispatch,
  selectedDataset,
  key,
  count,
  changeCurrentDataset,
  newProjectSelectedDatasets
}: IPrimaryHandle) => {
  
  if(!selectedDataset) return;
  
  const id = (selectedDataset.id || selectedDataset.sourceId) ?? ""

  if(changeCurrentDataset) {    

    dispatch(
      setNewProjectSelectedFiles(
        newProjectSelectedDatasets.map((item) => {
          if(id === item.sourceId) {
            
            return {...item, fields: setAsPrimary({ key, fields: selectedDataset.fields })}
          }
          return item
        }) as FileLibraryItem[]
      )
    );

    dispatch(setDatasetPreview({...selectedDataset, fields: setAsPrimary({ key, fields: selectedDataset.fields })}))
  } else {
  dispatch(
    requestUpdateDatasetById({
      id,
      name: selectedDataset.name,
      fields: setAsPrimary({ key, fields: selectedDataset.fields }),
      callbacks: {
        onSuccess() {   
            dispatch(

              requestGetAllDatasets({
                orderBy: { createdAt: "DESC" },
                sourceType: "database",
                limit: count,
                callbacks: {
                  onSuccess: (data) => {
                    const updatedDataset = data.items.find(
                      (item: any) => item.id === id
                    );
                    dispatch(setDatasetPreview(updatedDataset));
                  },
                },
              })
            );
        },
      },
    })
  )};
};

export const saveFieldChanges = ({
  key,
  fields = [],
  name,
  subtype,
  type,
}: ISaveField) => {
  return fields.map((item: DatasetFiledDTO) => {
    if (item.key === key) {
      return {
        ...item,
        label: name || item.label,
        type: type || item.type,
        subtype: subtype || item.subtype,
      };
    }
    return item;
  });
};

export const handleSaveForAddedDataset = ({
  dispatch,
  selectedDataset,
  key,
  name,
  type,
  subtype,
  closeMenu = () => {},
}: IEditDimension) => {
  dispatch(
    requestUpdateDatasetById({
      id: selectedDataset.id!,
      name: selectedDataset.name,
      fields: saveFieldChanges({
        key,
        fields: selectedDataset.fields,
        name,
        subtype,
        type,
      }),
      callbacks: {
        onSuccess() {
          dispatch(
            requestGetAllDatasets({
              orderBy: { createdAt: "DESC" },
              limit: 1000,
              callbacks: {
                onSuccess: (data) => {
                  const updatedDataset = data.items.find(
                    (item: any) => item.id === selectedDataset.id
                  );

                  console.log(updatedDataset);
                  
                  dispatch(setDatasetPreview(updatedDataset));
                  closeMenu();
                },
              },
            })
          );
        },
      },
    })
  );
};

interface IResetAllColumnsForUploadedFile {
  filePath: string;
  parsedSuggestions: ParsedSuggestions[];
  dispatch: React.Dispatch<AnyAction>;
}

export const handleResetAllColumnsForUploadedFile = ({
  filePath,
  parsedSuggestions,
  dispatch,
}: IResetAllColumnsForUploadedFile) => {
  // eslint-disable-next-line array-callback-return
  parsedSuggestions.map((item) => {
    // eslint-disable-next-line array-callback-return
    if (item.types.length !== 1) return;

    dispatch(
      updateDatasetHeader({
        label: item.label,
        selectedType: item.types.at(0)?.type,
        selectedSubType: item.types.at(0)?.subtype,
        objectKey: item.objectKey,
        primary: false,
      })
    );
    dispatch(
      updateFileData({
        key: item.objectKey,
        newHeader: item.label,
        filePath: filePath,
        type: item.types.at(0)?.type ?? "",
        primary: false,
      })
    );
  });
};

interface IAddPrimaryColumnForUploadedFile {
  dispatch: React.Dispatch<AnyAction>;
  filePath: string;
  parsedSuggestions: ParsedSuggestions[];
  errorsDetected: boolean;
  key: string;
}

export const handleAddPrimaryTableForUploadedFile = ({
  dispatch,
  parsedSuggestions,
  errorsDetected,
  filePath,
  key,
}: IAddPrimaryColumnForUploadedFile) => {
  const selectedField = parsedSuggestions.find(
    (item) => item.objectKey === key
  );

  if (errorsDetected) return;

  dispatch(
    updateDatasetHeader({
      label: selectedField?.label!,
      selectedType: selectedField?.types.at(0)?.type,
      selectedSubType: selectedField?.types.at(0)?.subtype,
      objectKey: key,
      primary: !selectedField?.primary,
    })
  );
  dispatch(
    updateFileData({
      key,
      newHeader: selectedField?.label!,
      filePath: filePath,
      type: selectedField?.types.at(0)?.type!,
      primary: !selectedField?.primary,
    })
  );
};
