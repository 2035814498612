import { useState } from "react";
import {
  CloseIcon,
  CloseIconWrap,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
} from "../styles";
import {
  PositionHouseModalBody,
  PositionHouseModalContent,
  RealEstateMapContainer,
} from "./styles";
import { RealEstateMap } from "../../RealEstateMap";
import HouseSettings, { HouseSettingsProps } from "./components/HouseSettings";
import DataResources from "./components/DataResources";
import { useDispatch, useSelector } from "react-redux";
import { setNewProjectFiles } from "../../../store/slices/datasets";
import { getDraftProjectFiles } from "../../../store/selectors/datasets";
import { IFile } from "../../../models/Projects";

interface Props {
  onClose: () => void;
}

const PositionHouseModal = ({ onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [houseSettings, setHouseSettings] = useState<HouseSettingsProps & { coordinates?: [number, number] }>({
    zpId: "",
    address: "",
    latitude: 0,
    longitude: 0,
    scale: 1,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    coordinates: [0, 0]
  });

  const handleOnClose = () => {
    setIsClosing(true);
  };
  
  const dispatch = useDispatch();
  const newProjectFiles = useSelector(getDraftProjectFiles);

  const handleOnSave = () => {
    if (!selectedFile) return;

    const updatedFiles: IFile[] = newProjectFiles.map((item) => {
      if (item.name === selectedFile.name) {
        return {
          ...item,
          mapBoxConfig: {
            ...houseSettings,
            coordinates: [
              Number(houseSettings.longitude),
              Number(houseSettings.latitude),
            ],
          },
        };
      }
      return item;
    });

    dispatch(setNewProjectFiles(updatedFiles));
    onClose();
  };

  const handleUpdateData = (s: HouseSettingsProps) => {
    setHouseSettings(prev => {
      // Only update if values actually changed
      if (JSON.stringify(prev) !== JSON.stringify(s)) {
        return s;
      }
      return prev;
    });
  };

  const handleFileSelect = (file: IFile) => {
    setSelectedFile(file);
    // Initialize house settings from existing mapBoxConfig if available
    if (file.mapBoxConfig) {
      const { coordinates, ...config } = file.mapBoxConfig;
      setHouseSettings({
        ...config,
        latitude: coordinates ? coordinates[1] : 0,
        longitude: coordinates ? coordinates[0] : 0,
        scale: config.scale || 1,
        rotateX: config.rotateX || 0,
        rotateY: config.rotateY || 0,
        rotateZ: config.rotateZ || 0,
      });
    } else {
      // Reset to default values if no config exists
      setHouseSettings({
        zpId: "",
        address: "",
        latitude: 0,
        longitude: 0,
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
      });
    }
  };
  

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper
        $maxWidth={true}
        onClick={(e) => e.stopPropagation()}
        $isClosing={isClosing}
      >
        <PositionHouseModalContent>
          <PositionHouseModalBody>
            <DataResources
              onSelectFile={handleFileSelect}
              onClose={handleOnClose}
              onDone={handleOnSave}
            />
            <div>
              <ModalHeadingWrapper
                style={{
                  backgroundColor: "#9FA4AD14",
                  borderLeft: "1px solid #9FA4AD14",
                }}
              >
                <ModalHeading style={{ fontSize: "18px" }}>
                  {selectedFile?.name}
                </ModalHeading>
                <CloseIconWrap onClick={handleOnClose}>
                  <CloseIcon />
                </CloseIconWrap>
              </ModalHeadingWrapper>
              <RealEstateMapContainer>
                <RealEstateMap
                  threeDModelPath={selectedFile}
                  houseSettings={houseSettings}
                  bordered={false}
                  rounded={false}
                  withZoomAction={false}
                  withLegend={false}
                />
                <HouseSettings 
                  onSave={handleOnSave}
                  onChange={handleUpdateData}
                  initialValues={houseSettings}
                />
              </RealEstateMapContainer>
            </div>
          </PositionHouseModalBody>
        </PositionHouseModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default PositionHouseModal;