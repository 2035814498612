import styled, { css, keyframes } from "styled-components";

export const blur = keyframes`
  0% {
    backdrop-filter: blur(0px);

  }
  100% {
    backdrop-filter: blur(1px);
  }
`;

export const open = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
    
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const close = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);    
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

export const WarningWrapper = styled.div`
  animation: 0.1s ${blur} ease; 
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content:center;
  align-items: center;
`

export const WarningContainer = styled.div<{$close?: boolean}>`
  animation: 0.3s ${open} ease; 
  ${({ $close }) =>
    $close &&
    css`
      animation: 0.3s ${close} ease;
    `}
  border-radius: 12px;
  flex-direction: column;
  background: white;
  box-shadow: 0px 4px 8px 2px rgba(34, 36, 44, 0.12), 0px 2px 4px 0px rgba(34, 36, 44, 0.08);
`

export const WarningContent = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 40px;
  display: flex;
  gap: 8px;
`

export const WarningTitle = styled.p`
  color: ${({theme}) => theme.neutralText} ;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 398px;

` 

export const WarningDescription = styled.p`
  max-width: 398px;
  color: #2E3849;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
` 

export const WarningFooter = styled.div`
  padding: 16px 20px;
  border-top: 1px solid #DFE1E4;
  display: flex;
  justify-content: space-between;
`