import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const MainSelectionContainer = styled.div`
  position: relative;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid #dfe1e4;
  overflow: hidden;
`;

export const MainImage = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigationButton = styled.button<{ direction: string }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  ${props => props.direction === "left" && css`
    left: 20px;
  `}

  ${props => props.direction === "right" && css`
    right: 20px;
  `}
`;

export const ThumbnailSection = styled.div`
  position: relative;
  margin-top: 16px;
  padding: 0 32px;
`;

export const ThumbnailsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  padding: 4px;
  justify-content: center;


  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Thumbnail = styled.img<{ selected: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  flex-shrink: 0;
  scroll-snap-align: center;
  transition: all 0.3s;
  border: 2px solid ${({ selected }) => (selected ? "#454BDF" : "transparent")};

  &:hover {
    border-color: ${({ selected }) => selected ? "#454BDF" : "#dfe1e4"};
  }
`;

export const ScrollButton = styled(NavigationButton)`
  width: 24px;
  height: 24px;
  padding: 4px;
  top: 50%;
`;