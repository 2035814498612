import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";
import { Group } from "@visx/group";
import { AxisLeft, AxisTop } from "@visx/axis";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { ColorRangeI } from "../../../models/Pages";
import { generateColorRanges } from "./utils/generateColorRanges";
import { getAdditionalKeys } from "./utils/getAdditionalKeys";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { TickLabel } from "../components/LabelTooltip";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { setActiveModal } from "../../../store/slices/modals";
import { createPortal } from "react-dom";
import { Rect } from "./style";
import { scaleBand } from "@visx/scale";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";
import { getActiveModal } from "../../../store/selectors/modals";
import { getSequentialColorsHex } from "../../../constants/utils/getSequentialColors";
import { filledArrayWithZeroData } from "./utils/fillArrayWithZeroData";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";
const rectPadding = 3;

export const MatrixChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
}: {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
}) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const [ref, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [xAxes, setXAxes] = useState<string[]>([]);
  const [yAxes, setYAxes] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [variations, setVariations] = useState<string[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);
  const margin = { top: 20, right: 0, bottom: 10, left: 80 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand({
    range: [0, xMax],
    domain: xAxes,
    padding: 0,
    paddingInner: 0,
  });

  const yScale = scaleBand({
    range: [0, yMax],
    domain: yAxes,
    align: 0.5,
    padding: 0,
    paddingInner: 0,
  });

  const getColor = (value: number) => {
    if (!value) return `url(#noDataCell)`;

    const range = colorRanges.find(
      (range) => value >= range.start && value <= range.end
    );

    if (!value) {
      return colorRanges.at(0)?.color;
    }

    return range ? range.color : colorRanges.at(0)?.color;
  };

  useEffect(() => {
    if (
      currentWidget &&
      ["matrixChart", "matrix"].includes(currentWidget.chartType)
    ) {
      if (currentWidget && currentWidget?.data) {
        const dataMatrix = currentWidget?.data;

        const values = dataMatrix?.map(
          (item) => item.value || item?.[currentWidget?.yAxe?.[0]!] || "0"
        );

        const colorPaletteVariations = getSequentialColorsHex(
          styleId,
          currentWidget.palette?.paletteId
        );

        const xAxe = currentWidget?.xAxe?.[0] || "x";
        const yAxe = currentWidget?.yAxe?.[0] || "y";
        const xAxes =
          dataMatrix?.map((l) =>
            xAxe === "value" ? Number(l?.[xAxe]) : l?.[xAxe]
          ) || [];
        const additionalKeys = getAdditionalKeys(dataMatrix, xAxe, yAxe);
        let additionalKey = additionalKeys[0];
        if (additionalKey === "state") {
          additionalKey = "county";
        }

        const groupBy = currentWidget?.groupBy?.at(0);

        const uniqueValuesKeys =
          (currentWidget?.uniqueValues &&
            Object.keys(currentWidget?.uniqueValues!)) ||
          [];
        const groupByKey =
          groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

        const yAxesNotSorted =
          uniqueValuesKeys?.length && currentWidget?.uniqueValues
            ? currentWidget?.uniqueValues[groupByKey!]
            : dataMatrix?.map((l) => l?.[additionalKeys[0]]) || [];

        const yAxesSorted = yAxesNotSorted?.length
          ? yAxesNotSorted
          : [...new Set(yAxesNotSorted)].sort();

        setXAxes([...new Set(xAxes)].sort());
        setYAxes(yAxesSorted?.slice());
        setXAxe(xAxe);
        setYAxe(additionalKey);
        setValues(values);
        setVariations(colorPaletteVariations?.slice());
        setGroupedData(filledArrayWithZeroData(currentWidget));
      }
    }
  }, [currentWidget, styleId]);

  const rectWidth = useMemo(() => xScale.bandwidth(), [xScale]);
  const rectHeight = useMemo(
    () => Math.min(yScale.bandwidth() < 16 ? 16 : yScale.bandwidth(), 32),
    [yScale]
  );

  const generateColorRangesCallback = useCallback(
    () =>
      generateColorRanges(
        variations,
        values,
        setColorRanges,
        currentWidget.palette?.range
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variations, values]
  );

  useEffect(() => {
    if (variations?.length) {
      generateColorRangesCallback();
    }
  }, [variations?.length, values, generateColorRangesCallback]);

  const xScaleTickLabelMaxLength = useMemo(() => {
    const charSpace = 8;
    return (rectWidth - rectPadding) / charSpace;
  }, [rectWidth]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);
  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );
  const ifRecomendedToMap = currentWidget?.data?.some(
    (d) => d?.county && d?.state
  );
  const recommendedCountAditional = ifRecomendedToMap ? 1 : 0;

  if (Object.keys(groupedData).length === 0) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }
  const h2 = yAxes.length * rectHeight - margin.top - 10;
  const svgHeight = h2 < (refHeight || 0) ? refHeight : h2;
  // const paddingTop = yAxes.length > 7 ? 1 : 0;

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName && <Title>{name}</Title>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {AVAILABLE_WIDGETS["matrix"]?.length +
                      recommendedCountAditional}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}

          {showLegend && currentWidget?.legend && (
            <ChartLegend legendType="palette" colorRanges={colorRanges} />
          )}
        </HeaderWrapper>
        {yAxes.length > 7 && (
          <svg height={"25"} width={"100%"}>
            <AxisTop
              scale={xScale}
              top={margin.top}
              left={margin.left}
              hideTicks
              stroke="transparent"
              tickLabelProps={() => ({
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: "middle",
                fontWeight: 400,
              })}
              tickComponent={(props: any) => (
                <TickLabel
                  {...props}
                  length={xScaleTickLabelMaxLength}
                  offsetX={-10}
                />
              )}
            />
          </svg>
        )}
        <div
          ref={divRef}
          style={
            yAxes.length > 7
              ? {
                  height: refHeight ? refHeight - 16 * 2 : 160,
                  overflowY: "auto",
                }
              : { height: "100%", overflowY: "auto" }
          }
        >
          <svg
            width="100%"
            height={yAxes.length > 7 ? (svgHeight as any) : "100%"}
            ref={ref}
          >
            <defs>
              <pattern
                id={"noDataCell"}
                width="5"
                height="5"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect width="5" height="5" fill="#f5f5f5" />
                <rect width="2" height="5" fill="#ffffff" />
              </pattern>
            </defs>
            <Group top={yAxes.length > 7 ? 0 : margin.top} left={margin.left}>
              {groupedData?.map((d: any, i: number) => {
                const x = xScale(d?.[xAxe || "x"]);
                const y = yScale(d?.[yAxe || "y"]);
                const key = `rect-${i}-${x}-${y}`;
                const value = Number(d.value || d?.[currentWidget?.yAxe?.[0]!]);
                return (
                  <Rect
                    key={key}
                    $tooltip={
                      (showTooltip || currentWidget?.tooltip) && !recommended
                    }
                    x={x}
                    y={y! + rectPadding / 2}
                    width={rectWidth - rectPadding}
                    height={yScale.bandwidth() - rectPadding}
                    fill={getColor(value)}
                    rx={2}
                    onMouseMove={(event: any) => {
                      if (
                        (showTooltip || currentWidget.tooltip) &&
                        !recommended
                      ) {
                        const { pageX, pageY, clientX, clientY } = event;
                        const coords = { pageX, pageY, clientX, clientY };

                        setTooltip({
                          data: {
                            [xAxe as string]: d?.[xAxe || "x"],
                            [yAxe as string]: d?.[yAxe || "y"],
                            value: d.value || d?.[currentWidget?.yAxe?.[0]!],
                          },
                          coords,
                        });
                      }
                    }}
                    onMouseLeave={() => setTooltip(null)}
                  />
                );
              })}
              {!(yAxes.length > 7) && (
                <AxisTop
                  scale={xScale}
                  top={0}
                  hideTicks
                  stroke="transparent"
                  tickLabelProps={() => ({
                    fontSize: 11,
                    fill: "#5F6877",
                    textAnchor: "middle",
                    fontWeight: 400,
                  })}
                  tickComponent={(props: any) => (
                    <TickLabel
                      {...props}
                      length={xScaleTickLabelMaxLength}
                      offsetX={-10}
                    />
                  )}
                />
              )}
              <AxisLeft
                scale={yScale}
                hideTicks
                stroke="transparent"
                tickLabelProps={() => ({
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "start",
                  fontWeight: 400,
                  dominantBaseline: "middle",
                  dx: -80,
                })}
                tickTransform={"translate(0, 3)"}
                numTicks={yScale.domain().length}
                tickComponent={(props) => (
                  <TickLabel {...props} x={0} length={9} offsetX={-10} />
                )}
              />
            </Group>
          </svg>
        </div>

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              coords={tooltip.coords}
              matrix
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
