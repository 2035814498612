import { AxiosError, AxiosResponse } from "axios";
import axios from "./axios";
import { FeedBackState } from "../store/slices/feedback";
import { CREATE_FEEDBACK } from "./constants";

export const createFeedback = ({ feedback } : FeedBackState) => {
  return axios
    .post(CREATE_FEEDBACK, feedback)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};