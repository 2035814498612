import { useState, useEffect, useRef, useCallback } from 'react';
import { GEOCODING_URL } from '../api/constants';

interface MapboxFeature {
  id: string;
  place_name: string;
  center: [number, number]; // [longitude, latitude]
  properties: {
    address?: string;
  };
  geometry: {
    coordinates: [number, number];
  };
}

interface MapboxResponse {
  features: MapboxFeature[];
  type: string;
}

interface AddressSuggestion {
  id: string;
  fullAddress: string;
  longitude: number;
  latitude: number;
}

interface UseAddressSuggestionsProps {
  onSelect: (address: string, longitude: number, latitude: number) => void;
}

interface UseAddressSuggestionsReturn {
  suggestions: AddressSuggestion[];
  showSuggestions: boolean;
  suggestionsRef: React.RefObject<HTMLDivElement>;
  handleAddressChange: (value: string) => Promise<void>;
  handleSuggestionSelect: (suggestion: AddressSuggestion) => void;
}

export const useAddressSuggestions = ({ onSelect }: UseAddressSuggestionsProps): UseAddressSuggestionsReturn => {
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchAddressSuggestions = async (query: string): Promise<void> => {
    if (!query || query.length < 3) {
      setSuggestions([]);
      return;
    }

    const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_REAL_ESTATE_ACCESS_TOKEN;
    const endpoint = `${GEOCODING_URL}/${encodeURIComponent(query)}.json?access_token=${MAPBOX_ACCESS_TOKEN}&types=address`;

    try {
      const response = await fetch(endpoint);
      const data: MapboxResponse = await response.json();
      
      const formattedSuggestions = data.features.map(feature => ({
        id: feature.id,
        fullAddress: feature.place_name,
        longitude: feature.center[0],
        latitude: feature.center[1]
      }));

      setSuggestions(formattedSuggestions);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      setSuggestions([]);
    }
  };

  const handleAddressChange = useCallback(async (value: string): Promise<void> => {
    await fetchAddressSuggestions(value);
  }, []);

  const handleSuggestionSelect = useCallback((suggestion: AddressSuggestion): void => {
    onSelect(suggestion.fullAddress, suggestion.longitude, suggestion.latitude);
    setShowSuggestions(false);
  }, [onSelect]);

  return {
    suggestions,
    showSuggestions,
    suggestionsRef,
    handleAddressChange,
    handleSuggestionSelect
  };
};