import { ReactComponent as PoliceIcon } from "../../../../assets/realEstateIcons/police.svg";
import { ReactComponent as SchoolIcon } from "../../../../assets/realEstateIcons/notebook.svg";
import { ReactComponent as HospitalIcon } from "../../../../assets/realEstateIcons/hospital.svg";
import { ReactComponent as RestaurantIcon } from "../../../../assets/realEstateIcons/police.svg";
import { ReactComponent as OfficeIcon } from "../../../../assets/realEstateIcons/building.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/realEstateIcons/hotel.svg";
import { ReactComponent as StoreIcon } from "../../../../assets/realEstateIcons/store.svg";

export type RealEstateMapLegendItem = 
  | "police"
  | "school"
  | "hospital"
  | "restaurant"
  | "office"
  | "hotel"
  | "store";

export const REAL_ESTATE_LEGEND_MAPP_IDS: Record<RealEstateMapLegendItem, RealEstateMapLegendItem> = {
  police: "police",
  school: "school",
  hospital: "hospital",
  restaurant: "restaurant",
  office: "office",
  hotel: "hotel",
  store: "store"
};

export const REAL_ESTATE_LEGEND_IDS_ARR: RealEstateMapLegendItem[] = [
  "police",
  "school",
  "hospital",
  "restaurant",
  "office",
  "hotel",
  "store"
];

export const REAL_ESTATE_LEGEND_OPTIONS: RealEstateLegendOption[] = [
  {
    icon: <PoliceIcon />,
    label: "Police Stations",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.police,
  },
  {
    icon: <SchoolIcon />,
    label: "Educational",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.school,
  },
  {
    icon: <HospitalIcon />,
    label: "Healthcare",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.hospital,
  },
  {
    icon: <RestaurantIcon />,
    label: "Restaurants",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.restaurant,
  },
  {
    icon: <OfficeIcon />,
    label: "Offices",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.office,
  },
  {
    icon: <HotelIcon />,
    label: "Hotels",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.hotel,
  },
  {
    icon: <StoreIcon />,
    label: "Commercial",
    id: REAL_ESTATE_LEGEND_MAPP_IDS.store,
  }
];

interface RealEstateLegendOption {
  icon: React.ReactNode;
  label: string;
  id: RealEstateMapLegendItem;
}