import { useCallback, useRef } from 'react';
import { HouseSettingsProps } from '../../Modals/PositionHouseModal/components/HouseSettings';
import { IFile } from '../../../models/Projects';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, DEFAULT_X, DEFAULT_Y, DEFAULT_Z } from '../constants';

export const use3DModel = (
  houseSettings?: Omit<HouseSettingsProps, "zpId" | "address">,
  threeDModelPath?: IFile
) => {
  const modelRef = useRef<any>(null);

  const updateModelPosition = useCallback(
    (
      model: any,
      long: number,
      lat: number,
      scale: number,
      rotateX: number,
      rotateY: number,
      rotateZ: number
    ) => {
      if (!model) return;
      
      model.setCoords([long, lat]);
      model.setRotation({ x: rotateX, y: rotateY, z: rotateZ });
      
      const safeScale = Math.max(scale || 1, 0.1);
      if (model.children && model.children.length > 0) {
        model.children.forEach((child: any) => {
          child.scale.set(safeScale, safeScale, safeScale * (2.7/3.2));
        });
      } else {
        model.scale.set(safeScale, safeScale, safeScale * (2.7/3.2));
      }
      
      window.tb.update();
    },
    []
  );

  const add3DModel = useCallback(
    (modelPath: string | undefined, long?: number, lat?: number) => {
      if (!modelPath) return;
      if (modelRef.current) {
        window.tb.remove(modelRef.current);
        modelRef.current = null;
      }

      const options = {
        obj: modelPath,
        type: "glb",
        units: "meters",
        rotation: { x: 90, y: -10, z: 0 },
      };

      window.tb.loadObj(options, (model: any) => {
        modelRef.current = model;
        window.tb.add(model);
        
        const initialScale = Math.max(houseSettings?.scale || 1, 0.1);
        updateModelPosition(
          model,
          long || DEFAULT_LONGITUDE,
          lat || DEFAULT_LATITUDE,
          initialScale,
          DEFAULT_X,
          DEFAULT_Y,
          DEFAULT_Z
        );
      });
    },
    [houseSettings?.scale, updateModelPosition]
  );

  const handleMapMove = useCallback(() => {
    if (!modelRef.current || !houseSettings) return;

    const {
      latitude,
      longitude,
      scale,
      rotateX = DEFAULT_X,
      rotateY = DEFAULT_Y,
      rotateZ = DEFAULT_Z,
    } = houseSettings;

    updateModelPosition(
      modelRef.current,
      longitude || DEFAULT_LONGITUDE,
      latitude || DEFAULT_LATITUDE,
      Math.max(scale || 1, 0.1),
      rotateX,
      rotateY,
      rotateZ
    );
  }, [houseSettings, updateModelPosition]);

  return {
    modelRef,
    add3DModel,
    handleMapMove,
    updateModelPosition,
  };
};