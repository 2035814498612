import { useEffect, useMemo, useRef, useState } from "react";
import { LinePath } from "@visx/shape";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { setActiveModal } from "../../../../store/slices/modals";
import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import {
  calculateLabelLength,
  calculateNumTicks,
  getScaleBandTickValues,
} from "../../widgetHelpers";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LineChartMarker } from "../../utils/getMarker";
import {
  getCurrentColor,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../../constants/widgetRecomended";
import { createPortal } from "react-dom";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../../styles";
import { getActiveModal } from "../../../../store/selectors/modals";
import { TickLabel } from "../../components/LabelTooltip";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../../utils/feedback";

interface LineChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  currentWidget: WidgetItem;
}
export const LineChart = ({
  storytelling,
  currentWidget,
  recommended,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings,
}: LineChartProps) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const widgetRef = useRef(null);

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const margin = { top: 15, right: 5, bottom: 20, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;
  const chartColor = getCurrentColor(currentWidget, "default", styleId);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const data = useMemo(() => currentWidget?.data || [], [currentWidget?.data]);

  const lineChartSuggestion = aiSuggestions?.find(
    (chart: AiSuggestionsDto) => chart.chartType === "lineChart"
  );

  const xAxe = currentWidget?.xAxe?.[0] ?? lineChartSuggestion?.xAxe?.[0];

  const yAxe = currentWidget?.yAxe?.[0] ?? lineChartSuggestion?.yAxe?.[0];

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(data.map((d: any) => d[xAxe]))) ||
      []
    );
  }, [currentWidget?.uniqueValues, xAxe, data]);

  const yAxes: number[] = data?.map((d) => parseInt(d[yAxe])) || [];

  const chartData = useMemo(() => {
    const dataMap = new Map(data.map((d: any) => [d[xAxe], d]));
    return xAxes.map((key: string) => dataMap.get(key));
  }, [xAxes, data, xAxe]);

  const xScale = scaleBand({
    domain: xAxes,
    range: [margin.left, width - margin.right],
    padding: -1,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  const yScaleNumTicks = calculateNumTicks({ height: height });

  const xScaleNumTicksCalculated = calculateNumTicks({ width });

  const isReasonableAmountOfTicks =
    xScaleNumTicksCalculated <= xAxes.length &&
    xScaleNumTicksCalculated > 0 &&
    xAxes.length / xScaleNumTicksCalculated >= 1.5;

  const xScaleNumTicks = isReasonableAmountOfTicks
    ? xScaleNumTicksCalculated
    : xAxes.length;

  const xScaleTickValues = getScaleBandTickValues({
    tickCount: xScaleNumTicks,
    ticks: xAxes,
  });

  const xScaleTickLabelMaxLength = calculateLabelLength({
    width: width - margin.left - margin.right,
    tickValues: xScaleTickValues,
  });

  const handleCircleMouseLeave = () => {
    setTooltip(null);
    setHoveredElement(null);
  };

  if (!chartData.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}

      <WidgetImageWrapper ref={widgetRef}>
        {!storytelling && (
          <HeaderWrapper>
            <HeadingNameAndButton>
              {!hideName && <Title>{name}</Title>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {AVAILABLE_WIDGETS["lineChart"]?.length}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          </HeaderWrapper>
        )}
        <svg width="100%" height={"100%"} ref={ref}>
          {xAxes.map((value) => {
            return (
              <line
                key={`${value}`}
                x1={xScale(value)! + xScale.bandwidth() / 2}
                y1={margin.top}
                x2={xScale(value)! + xScale.bandwidth() / 2}
                y2={height - margin.bottom}
                stroke="#e0e0e0"
                strokeDasharray="1,2"
              />
            );
          })}
          {yScale.ticks().map((tick) => (
            <line
              key={`y-${tick}`}
              x1={margin.left}
              x2={width - margin.right}
              y1={yScale(tick)}
              y2={yScale(tick)}
              stroke="#e0e0e0"
              strokeDasharray="1,2"
            />
          ))}
          {yScale.ticks(yScaleNumTicks).map((value) => (
            <line
              key={value}
              x1={margin.left}
              y1={yScale(value)}
              x2={width - margin.right}
              y2={yScale(value)}
              stroke="#ccc"
              strokeDasharray="1 2"
            />
          ))}
          <line
            x1={width - margin.right}
            y1={margin.top}
            x2={width - margin.right}
            y2={height - margin.bottom}
            stroke="#e0e0e0"
            strokeDasharray="1, 2"
          />
          <LinePath
            data={chartData?.map((d: any) => ({
              x: xScale(d[xAxe])! + xScale.bandwidth() / 2,
              y: yScale(d[yAxe])!,
            }))}
            x={(d: any) => d.x}
            y={(d: any) => d.y}
            opacity={hoveredElement ? 0.2 : 1}
            style={{ transition: "0.2s" }}
            stroke={chartColor ? hexToRGBA(chartColor, 0.8) : ""}
            strokeWidth={2}
            strokeLinecap="round"
          />
          {chartData?.map((d: any, i: number) => {
            const key = "Line" + i;

            const onMouseEnter = (event: any) => {
              if ((showTooltip || currentWidget.tooltip) && !recommended) {
                const { pageX, pageY, clientX, clientY } = event;
                const coords = { pageX, pageY, clientX, clientY };
                setHoveredElement(key);
                setTooltip({
                  data: d,
                  coords,
                });
              }
            };

            return LineChartMarker({
              opacity: hoveredElement ? (hoveredElement === key ? 1 : 0.2) : 1,
              i: i,
              markerType: getCurrentMarker(currentWidget, "default"),
              color: chartColor,
              xScale: xScale(d[xAxe])!,
              xScaleBand: xScale.bandwidth() / 2,
              yScale: yScale(d[yAxe]),
              r: hoveredElement === key ? 5 : 3,
              hovered: hoveredElement === key,
              onMouseEnterHandler: onMouseEnter,
              onMouseLeaveHandler: handleCircleMouseLeave,
            });
          })}

          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            hideTicks
            numTicks={xAxes?.length}
            tickFormat={(tick) => tick.toString()}
            axisLineClassName="barchartAxisLine"
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";
              return {
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: textAnchor,
              };
            }}
            tickValues={xScaleTickValues}
            tickComponent={(props: any) => (
              <TickLabel
                {...props}
                length={xScaleTickLabelMaxLength}
                offsetX={-10}
              />
            )}
          />
          <AxisLeft
            scale={yScale}
            left={margin.left}
            stroke="#ccc"
            strokeDasharray="1, 2"
            tickLineProps={{
              stroke: "#939BA7",
            }}
            numTicks={yScaleNumTicks}
            tickLabelProps={(_, index, values) => ({
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: "end",
              dx: -4,
              dy: index === 0 ? 0 : index < values.length - 1 ? 4 : 7,
            })}
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
          />
        </svg>
        {tooltip &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
