import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IMarker } from '..';
import { requestProperty } from '../../../store/slices/realEstateSlice';
import { setActiveModal } from '../../../store/slices/modals';

export const usePopup = () => {
  const [popupInfo, setPopupInfo] = useState<IMarker | null>(null);
  const dispatch = useDispatch();

  const handleClickDetailsView = useCallback(
    (info: IMarker | null) => {
      if (!info) return;
      dispatch(requestProperty({ zpid: info.zpid }));
      dispatch(setActiveModal({ id: "houseDetailsModal" }));
    },
    [dispatch]
  );

  return {
    popupInfo,
    setPopupInfo,
    handleClickDetailsView,
  };
};