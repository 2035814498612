import styled from "styled-components";

export const ModalHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;

export const ModalHeaderContainerItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    strong {
        margin-right: 4px;
    }
`;