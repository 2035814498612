import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowLeft, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

import * as Styled from "./styles";

import { Dataset, Topic } from "./interfaces/companies.interface";
import { Button } from "../../../../../../components/Button";
import {
  setCurrentTopic,
  setSelectedSubTopics,
} from "../../../../../../store/slices/topics";
import { closeActiveModal, setActiveModal } from "../../../../../../store/slices/modals";
import { TopicSelect } from "../../../../../../components/TopicSelect";
import { getSelectedSubTopics } from "../../../../../../store/selectors/topics";
import { defaultFilters } from "./constants/defaultFilters";
import { requestCreateDataset, requestGetAllDatasets, setIsLoading } from "../../../../../../store/slices/datasets";
import { Search } from "../../../../../../components/SearchBar";
import SwitchComponent from "../../../../../../components/Inputs/CustomSwitch/Switch";
import { FilterDatasetList } from "../../../../../../components/ResourcesPagesComponents/FilterDatasetList/FilterDatasetList";
import { BreadCrumbs } from "../../../../../../components/Breadcrumbs";
import { getDataProvider } from "../../../../../../store/selectors/dataProviders";
import { requestGetDataProvider } from "../../../../../../store/slices/dataProviders";
import { PublicDatasetPreview } from "./PublicDatasetPreview/PublicDatasetPreview";
import { getDataProviderDatasetRequest } from "../../../../../../api/dataProviders";
import { ServerResponse } from "../../../../../../models";
import { toast } from "react-toastify";

interface Props { }

export const SearchDataById: React.FC<Props> = () => {
  const { id, datasetId } = useParams();
  const dispatch = useDispatch();
  const selectedSubTopics = useSelector(getSelectedSubTopics);
  const companyData = useSelector(getDataProvider);

  const [data, setData] = useState<any>();
  const [filters, setFilters] = useState(defaultFilters);
  const [searchFilters, setSearchFilters] = useState(defaultFilters);
  const [searchTopics, setSearchTopics] = useState<Record<string, Dataset[]> | undefined>({ ...selectedSubTopics });
  const [showFilters, setShowFilters] = useState(true);
  const [showDataset, setShowDataset] = useState(datasetId ? true : false);
  const [showDatasetData, setShowDatasetData] = useState(false);

  const selectedDataset = useMemo(
    () =>
      selectedSubTopics &&
      Object.values(selectedSubTopics)?.find((topic) =>
        topic.find((subtopic) => subtopic.id === datasetId)
      )?.find((subtopic) => subtopic.id === datasetId),
    [datasetId, selectedSubTopics]
  );

  const handleSelectAll = (topic: Topic) => {
    if (topic?.id) {
      const selected = {
        ...selectedSubTopics,
        [topic?.id]: selectedSubTopics?.[topic?.id]?.length
          ? []
          : topic?.datasets,
      };
      if (selectedSubTopics?.[topic?.id]) {
        delete selected[topic?.id];
      }
      dispatch(setSelectedSubTopics(selected));
    }
  };

  const handleConnect = async () => {
    dispatch(setIsLoading(true));
    dispatch(setActiveModal({ id: "progressModal" }));
    const variablesRequestParam: string[] = [];
    selectedDataset?.variables?.forEach((variable) => {
      variablesRequestParam.push(String(variable?.name));
    });
    if (!datasetId || !companyData?.id) {
      return;
    }
    const { response, error }: ServerResponse = await getDataProviderDatasetRequest({
      datasets: [{
        id: datasetId,
        variables: variablesRequestParam,
      }],
      locations: [filters?.locations?.[0]?.toLocaleLowerCase() || "us"],
      providerId: companyData?.id,
      topicId: selectedDataset?.topicId!,
      years: filters?.years?.map((y) => Number(y)) || []
    })
    const data = response?.data?.datasets?.[0]
    if (!data) {
      toast.error(error?.message)
      setTimeout(() => { dispatch(closeActiveModal()) }, 1000)
      return;
    } else {
      dispatch(
        requestCreateDataset({
          source: companyData?.id,
          sourceType: "provider",
          requestConfig: {
            datasetId: datasetId!,
            location: filters?.locations?.[0]?.toLocaleLowerCase() || "us",
            topicId: selectedDataset?.topicId!,
            variables: variablesRequestParam,
            years: filters?.years?.map((y) => Number(y)) || [],
          },
          name: data.datasetName,
          filePath: data.datasetName,
          fields: data?.fields,
          fieldsAi: data?.fieldsAi,
          descriptionAi: data?.descriptionAi,
          isProgressModal: true,
        })
      );
    }
  }

  // const handleGoBack = () => {
  //   if (showDatasetData) {
  //     navigate("/resources/public-resources/search/" + companyData?.id)
  //   }
  //   if (showDataset && !showDatasetData) {
  //     setShowDataset(false)
  //   }
  //   if (!showDataset && !showDatasetData) {
  //     navigate('/resources/public-resources/search')
  //     dispatch(setSelectedSubTopics(undefined));
  //   }
  // }

  useEffect(() => {
    if (!companyData) {
      dispatch(requestGetDataProvider(String(id)));
    }
  }, [companyData, dispatch, id]);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  useEffect(() => {
    if (searchTopics && !Object.keys(searchTopics).length) {
      setShowDataset(false);
      setFilters(defaultFilters);
      setSearchFilters(defaultFilters);
    }
  }, [searchTopics]);

  useEffect(() => {
    if (datasetId) {
      if (selectedSubTopics && !Object.values(selectedSubTopics).length) {
        const selected = companyData?.topics.find((el) =>
          el.datasets.find((ell) => ell.id === datasetId)
        );
        const selectedDataset = selected?.datasets?.find(
          (el) => el.id === datasetId
        );
        if (selected?.id && selectedDataset)
          dispatch(setSelectedSubTopics({ [selected?.id]: [selectedDataset] }));
      }
      setShowDatasetData(true);
    } else {
      setShowDatasetData(false);
    }
  }, [companyData, datasetId, dispatch, selectedSubTopics]);

  const isSelectedSubTopics = useMemo(() => {
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);
    const r = keys?.some((key) => {
      return selectedSubTopics?.[key]?.length;
    });
    if (!r) {
      setFilters(defaultFilters);
    }
    return r;
  }, [selectedSubTopics]);

  const years = useMemo(() => {
    const values: string[] | undefined = [];
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);

    if (
      keys &&
      keys?.length &&
      keys.some((key) => {
        return selectedSubTopics?.[key]?.length;
      })
    ) {
      keys.forEach((key) => {
        const data = selectedSubTopics?.[key];
        data.forEach((dataset) => {
          if (
            dataset?.filters?.find((filter) => filter.name === "Years")
              ?.values &&
            Array.isArray(
              dataset?.filters?.find((filter) => filter.name === "Years")
                ?.values
            )
          ) {
            dataset?.filters
              ?.find((filter) => filter.name === "Years")
              ?.values?.forEach((value) => {
                if (values.includes(value)) {
                  return;
                }
                values.push(value);
              });
          }
        });
      });
    } else if (companyData) {
      companyData?.topics?.forEach((topic) => {
        topic.datasets.forEach((dataset) => {
          if (
            dataset?.filters?.find((filter) => filter.name === "Years")
              ?.values &&
            Array.isArray(
              dataset?.filters?.find((filter) => filter.name === "Years")
                ?.values
            )
          ) {
            dataset?.filters
              ?.find((filter) => filter.name === "Years")
              ?.values?.forEach((value) => {
                if (values.includes(value)) {
                  return;
                }
                values.push(value);
              });
          }
        });
      });
    }
    return values;
  }, [companyData, selectedSubTopics]);

  const locations = useMemo(() => {
    const values: string[] | undefined = [];
    const keys = selectedSubTopics && Object.keys(selectedSubTopics);

    if (
      keys &&
      keys?.length &&
      keys.some((key) => {
        return selectedSubTopics?.[key]?.length;
      })
    ) {
      keys.forEach((key) => {
        const data = selectedSubTopics?.[key];
        data.forEach((dataset) => {
          if (
            dataset?.filters?.find((filter) => filter.name === "Location")
              ?.values &&
            Array.isArray(
              dataset?.filters?.find((filter) => filter.name === "Location")
                ?.values
            )
          ) {
            dataset?.filters
              ?.find((filter) => filter.name === "Location")
              ?.values?.forEach((value) => {
                if (values.includes(value)) {
                  return;
                }
                values.push(value);
              });
          }
        });
      });
    } else if (companyData) {
      companyData?.topics?.forEach((topic) => {
        topic.datasets.forEach((dataset) => {
          if (
            dataset?.filters?.find((filter) => filter.name === "Location")
              ?.values &&
            Array.isArray(
              dataset?.filters?.find((filter) => filter.name === "Location")
                ?.values
            )
          ) {
            dataset?.filters
              ?.find((filter) => filter.name === "Location")
              ?.values?.forEach((value) => {
                if (values.includes(value)) {
                  return;
                }
                values.push(value);
              });
          }
        });
      });
    }
    return values;
  }, [companyData, selectedSubTopics]);

  const breadCrumbs = useMemo(
    () => [
      {
        name: "Public Datasets",
        link: "/resources/public-resources",
        Icon: ArrowLeft,
        action: () => { dispatch(setSelectedSubTopics(undefined)) }
      },
      {
        name: "Providers",
        link: "/resources/public-resources/search",
        action: () => { dispatch(setSelectedSubTopics(undefined)) }
      },
      ...(showDatasetData
        ? [
          {
            name: "Filters",
            link: "/resources/public-resources/search/" + companyData?.id,
          },
        ]
        : []),
    ],
    [companyData?.id, dispatch, showDatasetData]
  );

  return (
    <Styled.SearchDataPageByIdWrapper>
      <Styled.SearchDataPageHeaderByIdWrapper>
        <BreadCrumbs breadcrumbs={breadCrumbs} />
        <Styled.HeaderTitleWrapper>
          <Styled.SearchDataPageHeaderByIdTitle>
            {/* <Styled.ArrowLeftButton onClick={handleGoBack}></Styled.ArrowLeftButton> */}
            {companyData?.name} Datasets
          </Styled.SearchDataPageHeaderByIdTitle>
          <Styled.ButtonsSearchData>
            {!showDatasetData && showDataset && (
              <SwitchComponent
                label="Filters"
                checked={showFilters}
                onChange={() => {
                  setShowFilters(!showFilters);
                }}
              />
            )}
            {showDatasetData && (
              <>
                <SwitchComponent
                  label="All datasets"
                  checked={showDataset}
                  onChange={() => {
                    setShowDataset(!showDataset);
                  }}
                />
                <Button
                  onClick={handleConnect}
                  name="Connect"
                  icon={<CheckLg />}
                  disabled={!data}
                  size="medium"
                  variant="primary"
                />
              </>
            )}
          </Styled.ButtonsSearchData>
        </Styled.HeaderTitleWrapper>
      </Styled.SearchDataPageHeaderByIdWrapper>
      <Styled.FullContentFlexWrapper>
        {!showDatasetData && showFilters && (
          <Styled.SearchDataPageByIdContentWrapperFullWidth
            $showDataset={showDataset}
          >
            <Styled.SearchDataPageByIdContentWrapper $showDataset={showDataset}>
              <Styled.ContentHeader>
                <Styled.ContentHeaderTitle>
                  Filter data
                </Styled.ContentHeaderTitle>
                {showDataset && (
                  <Styled.CloseIcon
                    onClick={() => {
                      setShowFilters(false);
                    }}
                  />
                )}
                <Styled.ContentHeaderSubTitle>
                  Select the appropriate filters to reduce the amount of
                  available data, refine the data selection, and speed up the
                  delivery of results.
                </Styled.ContentHeaderSubTitle>
                <Styled.ChipsWrapper>
                  {selectedSubTopics &&
                    Object.keys(selectedSubTopics).map((el) => {
                      const details = companyData?.topics.find(
                        (topic) => topic.id === el
                      );
                      return (
                        <Styled.Chip $selected>
                          {details?.name}
                          <XLg
                            onClick={() => {
                              if (!details) {
                                return;
                              }
                              handleSelectAll(details);
                            }}
                          />
                        </Styled.Chip>
                      );
                    })}
                  {filters.locations.map((location) => {
                    return (
                      <Styled.Chip $selected>
                        {location === "US" ? "United States" : location}
                        <XLg
                          onClick={() => {
                            setFilters({
                              ...filters,
                              locations: filters?.locations?.filter(
                                (loc) => loc !== location
                              ),
                            });
                          }}
                        />
                      </Styled.Chip>
                    );
                  })}
                  {filters.years.map((year) => {
                    return (
                      <Styled.Chip $selected>
                        {year}
                        <XLg
                          onClick={() => {
                            setFilters({
                              ...filters,
                              years: filters?.years?.filter((y) => y !== year),
                            });
                          }}
                        />
                      </Styled.Chip>
                    );
                  })}
                  {(filters?.years?.some((el) => el) ||
                    filters?.locations?.some((el) => el) ||
                    (selectedSubTopics &&
                      Object.keys(selectedSubTopics)?.some((el) => el)
                    )) && (
                      <Button
                        onClick={() => {
                          setShowDataset(false);
                          setFilters(defaultFilters);
                          dispatch(setSelectedSubTopics(undefined));
                        }}
                        variant="danger"
                        size="small"
                        name="Clear all"
                      />
                    )}
                  {(filters?.years?.some((el) => el) ||
                    filters?.locations?.some((el) => el) ||
                    (selectedSubTopics &&
                      Object.keys(selectedSubTopics)?.some((el) => el)
                    )) ? (
                    <Button
                      onClick={() => {
                        setShowDataset(true);
                        setSearchFilters(filters);
                        setSearchTopics(selectedSubTopics)
                      }}
                      variant="primary"
                      size="small"
                      disabled={
                        (!filters?.years?.some((el) => el) ||
                          !filters?.locations?.some((el) => el)) ||
                        (JSON.stringify(filters) === JSON.stringify(searchFilters) &&
                          (
                            JSON.stringify(Object.keys(searchTopics || {}).sort()) === JSON.stringify(Object.keys(selectedSubTopics || {}).sort()) &&
                            JSON.stringify([...(Object.values(searchTopics || {}))?.at(0) || []]?.sort((a, b) => a?.id?.localeCompare(b?.id))) === JSON.stringify([...(Object.values(selectedSubTopics || {}))?.at(0) || []]?.sort((a, b) => a?.id?.localeCompare(b?.id)))
                          )
                        )
                      }
                      name={`Show Datasets`}
                    />
                  ) : (
                    ""
                  )}
                </Styled.ChipsWrapper>
              </Styled.ContentHeader>
              <Styled.ContentBody>
                <Styled.ContentBodyDataWrapper>
                  <Styled.ContentBodyHeader>
                    <Styled.ContentBodyHeaderTitle>
                      Topics <Styled.Red>*</Styled.Red>
                    </Styled.ContentBodyHeaderTitle>
                    {/* <Search width="small" placeholder="Search" /> */}
                  </Styled.ContentBodyHeader>
                  <Styled.ContentBodyData>
                    <Styled.ContentBodyDataTopicsWrapper>
                      {companyData?.topics?.map((topic) => {
                        if (!topic.datasets.length) {
                          return "";
                        }
                        return (
                          <TopicSelect
                            key={topic.id + topic.name + topic.type}
                            selected={
                              topic?.id
                                ? selectedSubTopics?.[topic?.id]?.length ===
                                  topic?.datasets?.length
                                  ? "checked"
                                  : selectedSubTopics?.[topic?.id]?.length
                                    ? "indeterminate"
                                    : "none"
                                : "none"
                            }
                            onClick={() => {
                              dispatch(setCurrentTopic(topic));
                              dispatch(setActiveModal({ id: "topicModal" }));
                            }}
                            onChange={(e) => {
                              handleSelectAll(topic);
                            }}
                            name={topic?.name}
                            count={
                              selectedSubTopics?.[topic?.id]?.length
                                ? selectedSubTopics?.[topic?.id]?.length
                                : selectedSubTopics?.[topic?.id]
                                  ? 1
                                  : undefined
                            }
                          />
                        );
                      })}
                    </Styled.ContentBodyDataTopicsWrapper>
                  </Styled.ContentBodyData>
                </Styled.ContentBodyDataWrapper>
                {selectedSubTopics && Object.keys(selectedSubTopics).length
                  ? (
                    <>
                      <Styled.ContentBodyDataWrapper>
                        <Styled.ContentBodyHeader>
                          <Styled.ContentBodyHeaderTitle>
                            Years <Styled.Red>*</Styled.Red>
                          </Styled.ContentBodyHeaderTitle>
                          {/* <Search width="small" placeholder="Search" /> */}
                        </Styled.ContentBodyHeader>
                        <Styled.ContentBodyData>
                          <Styled.YearChipsWrapper>
                            {years?.sort()?.map((year) => {
                              // if (filters.years.find((y) => y === year)) {
                              //   return "";
                              // }
                              const onClick = () => {
                                if (!isSelectedSubTopics) {
                                  return;
                                }
                                const newFiltersYears = filters?.years?.includes(year)
                                  ? filters?.years?.filter((y) => y !== year)
                                  : [...filters?.years, year];
                                const newFilters = {
                                  ...filters,
                                  years: newFiltersYears,
                                };
                                setFilters(newFilters);
                              };
                              const isSelected = filters.years.find(
                                (el) => el === year
                              );
                              return (
                                <Styled.Chip
                                  // $disabled={Boolean(
                                  //   selectedSubTopics &&
                                  //   !Object.keys(selectedSubTopics)
                                  // )}
                                  onClick={onClick}
                                  $selected={Boolean(isSelected)}
                                >
                                  {year}
                                  {isSelected
                                    ? (
                                      <XLg
                                        onClick={() => {
                                          setFilters({
                                            ...filters,
                                            years: filters?.years?.filter((y) => y !== year),
                                          });
                                        }}
                                      />
                                    )
                                    : ''}

                                </Styled.Chip>
                              );
                            })}
                          </Styled.YearChipsWrapper>
                        </Styled.ContentBodyData>
                      </Styled.ContentBodyDataWrapper>
                      <Styled.ContentBodyDataWrapper>
                        <Styled.ContentBodyHeader>
                          <Styled.ContentBodyHeaderTitle>
                            Location <Styled.Red>*</Styled.Red>
                          </Styled.ContentBodyHeaderTitle>
                          {/* <Search width="small" placeholder="Search" /> */}
                        </Styled.ContentBodyHeader>
                        <Styled.ContentBodyData>
                          {locations?.map((location) => {
                            // if (filters.locations.find((loc) => loc === location)) {
                            //   return "";
                            // }
                            const onChange = () => {
                              if (!isSelectedSubTopics) {
                                return;
                              }
                              const newFiltersLocations =
                                filters?.locations?.includes(location)
                                  ? filters?.locations?.filter(
                                    (loc) => loc !== location
                                  )
                                  : [...filters?.locations, location];
                              const newFilters = {
                                ...filters,
                                locations: newFiltersLocations,
                              };
                              setFilters(newFilters);
                            };

                            const isSelected = filters.locations.find(
                              (el) => el === location
                            )
                              ? "checked"
                              : "none";

                            return (
                              <TopicSelect
                                name={location === "US" ? "United States" : location}
                                onChange={onChange}
                                disableIcon
                                selected={isSelected}
                              />
                            );
                          })}
                        </Styled.ContentBodyData>
                      </Styled.ContentBodyDataWrapper>
                    </>
                  ) : ""
                }
              </Styled.ContentBody>
            </Styled.SearchDataPageByIdContentWrapper>
          </Styled.SearchDataPageByIdContentWrapperFullWidth>
        )}
        {showDataset ? (
          <Styled.DatasetListWrapper $showDatasetData={showDatasetData}>
            <Styled.DatasetHeader $showDatasetData={showDatasetData}>
              <Styled.DatasetHeaderTitle>Datasets</Styled.DatasetHeaderTitle>
              <Search width="xs" />
              {showDatasetData && (
                <Styled.CloseIcon
                  onClick={() => {
                    setShowDataset(false);
                  }}
                />
              )}
            </Styled.DatasetHeader>
            <FilterDatasetList
              setShowDatasetData={setShowDatasetData}
              showDatasetData={showDatasetData}
              data={Object.values(searchTopics!)
                .map((el) => {
                  return el;
                })
                .flat(1)}
              disableBorder
              disableRoundingTop
            />
          </Styled.DatasetListWrapper>
        ) : (
          ""
        )}
        {showDatasetData && selectedSubTopics ? (
          <Styled.DatasetDataPreviewWrapper>
            <PublicDatasetPreview data={data} setData={setData} filters={filters} />
          </Styled.DatasetDataPreviewWrapper>
        ) : (
          ""
        )}
      </Styled.FullContentFlexWrapper>
    </Styled.SearchDataPageByIdWrapper>
  );
};
