import React from "react";
import { NegativeXlinkHref } from "./dataImage";
import { SVG, XPath } from "./styles";

type Props = { active?: boolean };

const NegativeImage = ({ active }: Props) => {
  return (
    <SVG
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ transition: "0.3s" }}
    >
      <path
        d="M61.1565 14.7347V57.1359C61.1562 57.7997 60.8923 58.4362 60.423 58.9055C59.9536 59.3748 59.3172 59.6387 58.6534 59.6391H10.4809C9.81715 59.6387 9.18069 59.3748 8.71134 58.9055C8.242 58.4362 7.97816 57.7997 7.97778 57.1359V14.7347C7.9789 14.0714 8.24307 13.4357 8.71234 12.9669C9.1816 12.4982 9.81764 12.2347 10.4809 12.2344H58.6534C59.3167 12.2347 59.9527 12.4982 60.422 12.9669C60.8912 13.4357 61.1554 14.0714 61.1565 14.7347Z"
        fill="white"
      />
      <path
        d="M61.2803 14.6503V57.0516C61.2795 57.7156 61.0151 58.3521 60.5452 58.8212C60.0753 59.2904 59.4383 59.5537 58.7743 59.5533H10.6032C9.93971 59.5529 9.30346 59.2893 8.83415 58.8202C8.36485 58.3512 8.10084 57.7151 8.1001 57.0516V14.6503C8.10121 13.9871 8.36538 13.3513 8.83465 12.8826C9.30392 12.4139 9.93995 12.1504 10.6032 12.15H58.7743C59.4381 12.1497 60.0748 12.4128 60.5447 12.8816C61.0146 13.3504 61.2791 13.9866 61.2803 14.6503Z"
        fill="white"
      />
      <path
        d="M25.6501 34.4047L30.5597 29.7001L32.2221 31.2931L27.3126 35.9977L25.6501 34.4047Z"
        fill="#7F8692"
      />
      <path
        d="M25.6501 31.2931L27.3126 29.7001L32.2221 34.4047L30.5597 35.9977L25.6501 31.2931Z"
        fill="#7F8692"
      />
      <path
        d="M37.0782 34.4055L41.9877 29.7009L43.6501 31.2939L38.7406 35.9985L37.0782 34.4055Z"
        fill="#7F8692"
      />
      <path
        d="M37.0782 31.2955L38.7406 29.7025L43.6501 34.4071L41.9877 36.0001L37.0782 31.2955Z"
        fill="#7F8692"
      />
      <path
        d="M37.2801 45.4501L34.6491 42.8123L32.0198 45.4501L29.3936 42.8123L26.7999 45.4143L25.2 43.8098L29.3936 39.6001L32.0214 42.2379L34.6491 39.6017L37.2785 42.2379L39.9063 39.6001L44.1 43.8098L42.5 45.4143L39.9063 42.8123L37.2801 45.4501Z"
        fill="#7F8692"
      />
      <path
        d="M61.1563 14.7347V17.8073H7.979V14.7347C7.97919 14.4062 8.04408 14.0809 8.16997 13.7774C8.29587 13.474 8.4803 13.1983 8.71273 12.9661C8.94517 12.7339 9.22106 12.5498 9.52465 12.4243C9.82825 12.2987 10.1536 12.2342 10.4821 12.2344H58.6532C59.3166 12.2344 59.9529 12.4977 60.4222 12.9665C60.8916 13.4353 61.1556 14.0713 61.1563 14.7347Z"
        fill={active ? "#DD2E43" : "#2E3849"}
      />
      <path
        d="M11.7493 16.1045C12.3481 16.1045 12.8335 15.6191 12.8335 15.0203C12.8335 14.4215 12.3481 13.936 11.7493 13.936C11.1505 13.936 10.665 14.4215 10.665 15.0203C10.665 15.6191 11.1505 16.1045 11.7493 16.1045Z"
        fill="white"
      />
      <path
        d="M15.8513 16.1045C16.4501 16.1045 16.9355 15.6191 16.9355 15.0203C16.9355 14.4215 16.4501 13.936 15.8513 13.936C15.2525 13.936 14.7671 14.4215 14.7671 15.0203C14.7671 15.6191 15.2525 16.1045 15.8513 16.1045Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M25.1862 54.0105C25.0835 51.7617 24.405 49.5763 23.2152 47.6618C22.0254 45.7473 20.3637 44.1671 18.3879 43.0712C16.4122 41.9753 14.1877 41.4 11.9258 41.4C10.6178 41.4016 9.32984 41.595 8.09995 41.9646V59.4H24.297C24.9658 57.6889 25.2705 55.8535 25.1862 54.0105Z"
        fill="#9FA4AD"
        fill-opacity="0.16"
      />
      <path
        d="M58.6534 60.0609H10.4809C9.70538 60.0602 8.96183 59.7518 8.41344 59.2034C7.86506 58.655 7.55665 57.9115 7.55591 57.1359V14.7347C7.5574 13.9596 7.86613 13.2168 8.41444 12.669C8.96274 12.1213 9.70587 11.8132 10.4809 11.8125H58.6534C59.4289 11.8132 60.1725 12.1217 60.7209 12.67C61.2693 13.2184 61.5777 13.962 61.5784 14.7375V57.1359C61.5777 57.9115 61.2693 58.655 60.7209 59.2034C60.1725 59.7518 59.4289 60.0602 58.6534 60.0609ZM10.4809 12.6562C9.92916 12.657 9.40021 12.8765 9.01006 13.2667C8.61991 13.6568 8.4004 14.1857 8.39966 14.7375V57.1359C8.4004 57.6877 8.61991 58.2166 9.01006 58.6068C9.40021 58.9969 9.92916 59.2164 10.4809 59.2172H58.6534C59.2052 59.2164 59.7341 58.9969 60.1243 58.6068C60.5144 58.2166 60.7339 57.6877 60.7347 57.1359V14.7347C60.7332 14.1834 60.5133 13.6552 60.1233 13.2657C59.7332 12.8761 59.2047 12.657 58.6534 12.6562H10.4809Z"
        fill={active ? "#AC0015" : "#2E3849"}
      />
      <path
        d="M61.0375 8.35039C60.8146 8.35062 60.5941 8.30423 60.3902 8.2142C60.1863 8.12418 60.0034 7.99251 59.8534 7.82764C59.7034 7.66278 59.5894 7.46836 59.519 7.25688C59.4485 7.0454 59.4231 6.82152 59.4442 6.59961C59.4739 6.29175 59.5923 5.99913 59.7851 5.75726C59.9779 5.51539 60.2367 5.33467 60.5302 5.23705C60.8236 5.13944 61.1391 5.12911 61.4384 5.20732C61.7376 5.28554 62.0077 5.44894 62.2159 5.67769C62.424 5.90643 62.5613 6.19069 62.6111 6.49595C62.6608 6.80121 62.6209 7.11435 62.4961 7.39735C62.3713 7.68035 62.1671 7.92105 61.9082 8.09023C61.6493 8.2594 61.3468 8.34978 61.0375 8.35039ZM60.2838 6.68117C60.2694 6.82956 60.2994 6.97887 60.37 7.11021C60.4405 7.24155 60.5484 7.34903 60.68 7.41906C60.8116 7.48908 60.961 7.5185 61.1094 7.5036C61.2577 7.4887 61.3983 7.43015 61.5133 7.33535C61.6284 7.24055 61.7128 7.11377 61.7558 6.97102C61.7988 6.82828 61.7985 6.67599 61.7549 6.53341C61.7113 6.39084 61.6265 6.26438 61.5111 6.17003C61.3956 6.07568 61.2548 6.01768 61.1064 6.00336C60.9075 5.98454 60.7093 6.04531 60.5551 6.17235C60.4009 6.29939 60.3033 6.48235 60.2838 6.68117Z"
        fill="#7F8692"
      />
      <path
        d="M66.9888 14.4211C66.6852 14.4215 66.3849 14.3584 66.1071 14.2359C65.8294 14.1134 65.5802 13.9342 65.3758 13.7097C65.1714 13.4853 65.0161 13.2206 64.92 12.9326C64.8238 12.6447 64.7889 12.3398 64.8176 12.0375C64.8576 11.6179 65.0186 11.2189 65.2811 10.889C65.5435 10.5591 65.8961 10.3125 66.296 10.1792C66.6959 10.0458 67.1259 10.0315 67.5338 10.1378C67.9418 10.2442 68.31 10.4667 68.5939 10.7783C68.8778 11.09 69.0651 11.4773 69.133 11.8934C69.201 12.3094 69.1466 12.7362 68.9767 13.122C68.8067 13.5078 68.5284 13.8359 68.1755 14.0665C67.8226 14.2971 67.4104 14.4203 66.9888 14.4211ZM65.6571 12.1219C65.6319 12.384 65.685 12.6478 65.8097 12.8797C65.9343 13.1117 66.125 13.3015 66.3575 13.4251C66.59 13.5488 66.854 13.6007 67.116 13.5743C67.378 13.548 67.6263 13.4445 67.8296 13.2771C68.0328 13.1096 68.1818 12.8856 68.2578 12.6335C68.3338 12.3813 68.3333 12.1123 68.2564 11.8605C68.1795 11.6086 68.0297 11.3852 67.8258 11.2185C67.622 11.0517 67.3733 10.9492 67.1112 10.9238C66.7597 10.8908 66.4094 10.9984 66.1369 11.2229C65.8645 11.4474 65.692 11.7706 65.6571 12.1219Z"
        fill="#7F8692"
      />
      <path
        d="M11.7 63.9C16.6705 63.9 20.7 59.8706 20.7 54.9C20.7 49.9295 16.6705 45.9 11.7 45.9C6.72939 45.9 2.69995 49.9295 2.69995 54.9C2.69995 59.8706 6.72939 63.9 11.7 63.9Z"
        fill="white"
      />
      <path
        d="M11.7 64.8C9.74201 64.8 7.82795 64.2194 6.19991 63.1315C4.57186 62.0437 3.30295 60.4976 2.55365 58.6886C1.80434 56.8796 1.60829 54.889 1.99028 52.9686C2.37227 51.0482 3.31516 49.2842 4.6997 47.8996C6.08423 46.5151 7.84825 45.5722 9.76866 45.1902C11.6891 44.8082 13.6796 45.0043 15.4886 45.7536C17.2976 46.5029 18.8438 47.7718 19.9316 49.3999C21.0194 51.0279 21.6 52.942 21.6 54.9C21.5971 57.5247 20.5531 60.0411 18.6972 61.8971C16.8412 63.7531 14.3248 64.7971 11.7 64.8ZM11.7 45.9492C9.92975 45.9492 8.1992 46.4741 6.72724 47.4577C5.25529 48.4412 4.10804 49.8391 3.43058 51.4747C2.75311 53.1102 2.57586 54.9099 2.92122 56.6462C3.26659 58.3825 4.11908 59.9774 5.37087 61.2292C6.62266 62.481 8.21755 63.3335 9.95383 63.6788C11.6901 64.0242 13.4898 63.8469 15.1254 63.1695C16.7609 62.492 18.1588 61.3448 19.1424 59.8728C20.1259 58.4009 20.6509 56.6703 20.6509 54.9C20.6479 52.527 19.704 50.252 18.026 48.5741C16.348 46.8961 14.0731 45.9521 11.7 45.9492Z"
        fill="#7F8692"
      />
      <XPath
        d="M7.17773 52.8055L8.97094 51.0123L15.75 57.7914L13.9568 59.5846L7.17773 52.8055Z"
        fill={active ? "#DD2E43" : "#5F6877"}
      />
      <path
        d="M7.17773 52.8055L8.97094 51.0123L15.75 57.7914L13.9568 59.5846L7.17773 52.8055Z"
        fill="url(#pattern0_2528_10619)"
      />
      <XPath
        d="M7.17773 57.7902L13.9568 51.0111L15.75 52.8043L8.97094 59.5834L7.17773 57.7902Z"
        fill={active ? "#DD2E43" : "#5F6877"}
      />
      <path
        d="M7.17773 57.7902L13.9568 51.0111L15.75 52.8043L8.97094 59.5834L7.17773 57.7902Z"
        fill="url(#pattern1_2528_10619)"
      />
      <path
        d="M64.8887 63.4149L62.9559 61.4822L63.5524 60.8856L65.4852 62.8184L64.8887 63.4149Z"
        fill="#7F8692"
      />
      <path
        d="M64.6931 59.3438L64.6931 58.5L67.4269 58.5L67.4269 59.3437L64.6931 59.3438Z"
        fill="#7F8692"
      />
      <path
        d="M60.75 65.4159L60.75 62.6821L61.5937 62.6821L61.5937 65.4159L60.75 65.4159Z"
        fill="#7F8692"
      />
      <defs>
        <pattern
          id="pattern0_2528_10619"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2528_10619"
            transform="translate(-0.167028) scale(0.00159767)"
          />
        </pattern>
        <pattern
          id="pattern1_2528_10619"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2528_10619"
            transform="translate(-0.167028) scale(0.00159767)"
          />
        </pattern>
        <image
          id="image0_2528_10619"
          width="835"
          height="626"
          xlinkHref={NegativeXlinkHref}
        />
      </defs>
    </SVG>
  );
};

export default NegativeImage;
