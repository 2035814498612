import { ColorRangeI } from "../../../../models/Pages";
import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function getLayerColor(
  colorRanges: ColorRangeI[],
  county: MarkersVisualisationDataDto,
  valueProprety: keyof MarkersVisualisationDataDto
) {
  let color = colorRanges?.at(0)?.color;
  const lastItem = colorRanges[colorRanges?.length - 1].start;
  const countyValue =
    valueProprety && county[valueProprety]
      ? parseInt(county[valueProprety] as string)
      : 0;
  if (countyValue === 0) {
    color = colorRanges[0].color;
  } else if (countyValue > lastItem) {
    color = colorRanges[colorRanges?.length - 1].color;
  } else {
    const localColor = colorRanges.find((c: ColorRangeI, i: number) => {
      const nextItem =
        i === colorRanges?.length - 1 ? colorRanges?.length - 1 : i + 1;
      return (
        countyValue &&
        countyValue >= c.start &&
        countyValue < colorRanges[nextItem].start
      );
    });
    color = localColor ? localColor?.color : colorRanges[0].color;
  }
  return color;
}
