import React, { useState } from "react";

import {
  CheckBoxWrapper,
  DatasetsTable,
  Details,
  Dimensions,
  Format,
  HeadingWrapper,
  Name,
  Owner,
  UploadedOn,
  UploadedOnSort,
  Versions,
} from "./styles";
import { DatasetRow } from "../DatasetRow";
import {
  DatasetsResponseListDto,
  NewDatasetDTO,
} from "../../../../models/Datasets";
import { useSortByCreatedDate } from "../../../../hooks/useSortByDate";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";

export const DatasetsList = ({ items }: Partial<DatasetsResponseListDto>) => {
  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(items ? items : []);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  return (
    <DatasetsTable>
      <HeadingWrapper>
        <CheckBoxWrapper>
          <CheckBox
            checked={checkAll}
            onChange={(e) => setCheckAll(e.target.checked)}
          />
        </CheckBoxWrapper>
        <Name>Name</Name>
        <Format>Format</Format>
        <Dimensions>Dimensions</Dimensions>
        <UploadedOn>
          Uploaded on <UploadedOnSort onClick={handleSortByCreatedDate} />
        </UploadedOn>
        <Versions>Versions</Versions>
        <Owner>Owner</Owner>
        <Details></Details>
      </HeadingWrapper>
      {sortedItems?.map((dataset: NewDatasetDTO) => {
        return (
          <DatasetRow
            id={dataset.id}
            name={dataset.name}
            createdAt={dataset.createdAt}
            fileFormat={dataset?.filePath?.split(".")?.pop()}
            fields={dataset.fields}
            filePath={dataset.filePath}
            updatedAt={dataset.createdAt}
            selectAll={checkAll}
            key={dataset.id}
          />
        );
      })}
    </DatasetsTable>
  );
};
