import styled from "styled-components";

export const ResultsContainer = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
`;

export const ResultHeader = styled.h3`
    font-size: 16px;
    margin-bottom: 12px;
`;

export const ResultItem = styled.div`
    background: #9FA4AD14;
    padding: 12px;
    border: 1px solid #CFD2D6;

    &:not(:last-child) {
        border-bottom: none;
    }
    &:first-child {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    &:last-child {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    &:only-child {
        border-radius: 12px;
    }
`;

export const ResultItemHeader = styled.h4`
    font-size: 14px;
`;

export const ResultItemSubHeader = styled.p`
    font-size: 12px;
    margin-top: 2px;
`;

export const ResultitemDescription = styled.p`
    font-size: 12px;
    margin-top: 8px;
    color: ${({ theme }) => theme.neutralTextWeak};
`;