import React from "react";
import { ResourcesNoData } from "../../../../../pages/Resources/pages/NoData";

import { ReactComponent as EmptyIcon } from "../../../../../assets/resourcesPagesIcons/DeskLamp.svg";
import { Upload } from "react-bootstrap-icons";
import { setActiveModal } from "../../../../../store/slices/modals";
import { useDispatch } from "react-redux";
import { DatasetsResponseListDto } from "../../../../../models/Datasets";
import MyDataList from "./MyDataList";
import { ISelectedItems } from "../..";

type Props = {
  setLibraryDatasetOverviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDimensionsSelected: React.Dispatch<any>;
  allDatasets: DatasetsResponseListDto;
  libraryDatasetOverviewOpen: boolean;
  libraryDatasetsIds: string[];
  searchInput: string;
  selectedDataFromTab: ISelectedItems;
};

const MyData = ({
  allDatasets,
  setLibraryDatasetOverviewOpen,
  searchInput,
  setDimensionsSelected,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen,
  selectedDataFromTab,
}: Props) => {
  const dispatch = useDispatch();

  const datasets = allDatasets.items.filter(
    (item) => item.sourceType === "database"
  );

  return (
    <>
      {!allDatasets.items.length && (
        <ResourcesNoData
          Icon={EmptyIcon}
          title="No datasets were discovered"
          subTitle={`Feel free to add data by uploading CSV or XLS files. Just click the "Upload Dataset" button below! `}
          IconButton={Upload}
          name={"Upload Data"}
          onClick={() => {
            dispatch(setActiveModal({ id: "uploadDatasetModal" }));
          }}
        />
      )}
      <MyDataList
        datasets={datasets}
        setFileOverviewOpen={setLibraryDatasetOverviewOpen}
        searchInput={searchInput}
        setDimensionsSelected={setDimensionsSelected}
        libraryDatasetsIds={libraryDatasetsIds}
        libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
        selectedDataFromTab={selectedDataFromTab}
      />
    </>
  );
};

export default MyData;
