import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "../../SearchBar";

import {
  ButtonsWrapper,
  ErrorIcon,
  ErrorMessage,
  HeaderRow,
  HeadingIcon,
  HeadingText,
  Icon,
  More,
  Primary,
  StarIcon,
  Table,
  TableHeading,
  TableRowCell,
  TableRowDataSet,
  TablesWrapper,
} from "../DatasetsOverview/styles";
import {
  Close,
  ContentWrapper,
  FileOverview,
  HeaderItemsWrapper,
  HeaderWrapper,
  Heading,
} from "./styles";
import {
  removeSelectedFile,
  setOverviewFileData,
} from "../../../store/slices/datasets";
import { Trash } from "react-bootstrap-icons";
import { Button } from "../../Button";
import { removeFileData } from "../../../store/slices/files";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import { DimensionSettings } from "../../DimensionSettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { getSelectedFileView } from "../../../store/selectors/datasets";
import { getIconSrc } from "../../../helpers/getIconType";
import {
  DimensionSettingsInterface,
  ParsedSuggestions,
} from "../../../models/Files";
import { ILastAction } from "../../../pages/Resources/pages/Datasets/pages/DatasetPreview";
import {
  sortTableAscending,
  sortTableDescending,
} from "../../DimensionSettings/utils/tableActions";
import useMeasure from "react-use-measure";

type DatasetFileOverviewInterface = {
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  dimensionsSelected: any;
};

export const UploadedFilePreview = ({
  setFileOverviewOpen,
  dimensionsSelected,
}: DatasetFileOverviewInterface) => {
  const ref = useRef(null);
  const [tableRef, tableBounds] = useMeasure();

  const rowsRef = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  const fileSelected = useSelector(getSelectedFileView);

  const [searchInput, setSearchInput] = useState<string>("");
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [selectedDatasetContents, setSelectedDatasetContents] = useState<any>(
    []
  );
  const [pinedColumns, setPinedColumns] = useState<string[]>([]);
  const [maxRowHeight, setMaxRowHeight] = useState(0);

  const suggestedData = fileSelected.parsedSuggestions;
  const datasetContents = fileSelected?.previewItems;

  const filteredItems = suggestedData?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const columnWidth =
    tableBounds.width > 195 * filteredItems?.length!
      ? tableBounds.width / filteredItems?.length!
      : undefined;

  useEffect(() => {
    if (!datasetContents) return;

    const newData = datasetContents?.map((contentItem: any) => contentItem);

    if (!lastAction || !selectedDatasetContents) {
      return setSelectedDatasetContents(newData);
    }

    if (lastAction.ascending) {
      sortTableAscending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else if (lastAction.descending) {
      sortTableDescending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else {
      setSelectedDatasetContents(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetContents]);

  useEffect(() => {
    const heights = rowsRef.current.map((row) => row?.offsetHeight || 0);
    setMaxRowHeight(Math.max(...heights));
  }, [selectedDatasetContents, filteredItems, pinedColumns]);

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  return (
    <ContentWrapper ref={ref}>
      <HeaderWrapper>
        <Heading>{`${fileSelected.originalName
          ?.charAt(0)
          .toUpperCase()}${fileSelected.originalName?.slice(1, -4)}`}</Heading>
        <HeaderItemsWrapper>
          <Search
            width="small"
            onChange={(value: string) => setSearchInput(value)}
          />
          <Button
            onClick={() => {
              dispatch(removeFileData(fileSelected.path));
              dispatch(removeSelectedFile(fileSelected.path));
              dispatch(setOverviewFileData({}));
              setFileOverviewOpen(false);
            }}
            variant="danger"
            size="medium"
            name="Remove"
            icon={<Trash fill="#992A4B" />}
          />
        </HeaderItemsWrapper>
        <Close
          onClick={() => {
            setFileOverviewOpen(false);
            dispatch(setOverviewFileData({}));
          }}
        />
      </HeaderWrapper>
      {/* key: previewTable - Upload Dataset Preview */}
      <FileOverview>
        <TablesWrapper ref={tableRef}>
          {!!pinedColumns.length && (
            <Table $pined>
              {
                <HeaderRow>
                  {filteredItems
                    ?.filter((item) => pinedColumns.includes(item.objectKey))
                    .map((datasetItem: ParsedSuggestions, idx) => {
                      const iconSrc = getIconSrc(datasetItem.types[0]);
                      const errorDetected = datasetItem.types.length !== 1;

                      return (
                        <TableHeading $width={columnWidth}>
                          {datasetItem.primary && (
                            <Primary>
                              <StarIcon></StarIcon>
                            </Primary>
                          )}
                          <HeadingIcon>
                            <Icon src={iconSrc} />
                          </HeadingIcon>
                          <HeadingText>
                            {datasetItem.label?.charAt(0)?.toUpperCase()! +
                              datasetItem.label?.slice(1)}
                          </HeadingText>
                          <ButtonsWrapper>
                            {errorDetected && (
                              <ErrorMessage
                                onClick={(e) => {
                                  setShowDimensionSettings(
                                    handleColumnClick({
                                      header: datasetItem.label,
                                      event: e,
                                      key: datasetItem.objectKey,
                                      errorsDetected: errorDetected,
                                      suggestedType: datasetItem.types[0].type,
                                      subtype: datasetItem.types[0].subtype,
                                      edit: true,
                                    })
                                  );
                                }}
                              >
                                <ErrorIcon />1
                              </ErrorMessage>
                            )}
                            <More
                              onClick={(e) => {
                                setShowDimensionSettings(
                                  handleColumnClick({
                                    header: datasetItem.label,
                                    event: e,
                                    key: datasetItem.objectKey,
                                    errorsDetected: errorDetected,
                                    suggestedType: datasetItem.types[0].type,
                                    subtype: datasetItem.types[0].subtype,
                                  })
                                );
                              }}
                            />
                          </ButtonsWrapper>
                        </TableHeading>
                      );
                    })}
                </HeaderRow>
              }

              {selectedDatasetContents.map((item: any, idx: number) => {
                return (
                  <TableRowDataSet
                    key={idx}
                    ref={(el) => (rowsRef.current[idx] = el)}
                    style={{
                      height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                    }}
                  >
                    {filteredItems
                      ?.filter((item) => pinedColumns.includes(item.objectKey))
                      .map((header) => (
                        <TableRowCell $width={columnWidth}>
                          {item[header.objectKey] || "No data"}
                        </TableRowCell>
                      ))}
                  </TableRowDataSet>
                );
              })}
            </Table>
          )}
          <Table>
            {
              <HeaderRow>
                {filteredItems
                  ?.filter((item) => !pinedColumns.includes(item.objectKey))
                  .map((datasetItem: ParsedSuggestions, idx) => {
                    const iconSrc = getIconSrc(datasetItem.types[0]);
                    const errorDetected = datasetItem.types.length !== 1;

                    return (
                      <TableHeading $width={columnWidth}>
                        {datasetItem.primary && (
                          <Primary>
                            <StarIcon></StarIcon>
                          </Primary>
                        )}
                        <HeadingIcon>
                          <Icon src={iconSrc} />
                        </HeadingIcon>
                        <HeadingText>
                          {datasetItem.label?.charAt(0)?.toUpperCase()! +
                            datasetItem.label?.slice(1)}
                        </HeadingText>
                        <ButtonsWrapper>
                          {errorDetected && (
                            <ErrorMessage
                              onClick={(e) => {
                                setShowDimensionSettings(
                                  handleColumnClick({
                                    header: datasetItem.label,
                                    event: e,
                                    key: datasetItem.objectKey,
                                    errorsDetected: errorDetected,
                                    suggestedType: datasetItem.types[0].type,
                                    subtype: datasetItem.types[0].subtype,
                                    edit: true,
                                  })
                                );
                              }}
                            >
                              <ErrorIcon />1
                            </ErrorMessage>
                          )}
                          <More
                            onClick={(e) => {
                              setShowDimensionSettings(
                                handleColumnClick({
                                  header: datasetItem.label,
                                  event: e,
                                  key: datasetItem.objectKey,
                                  errorsDetected: errorDetected,
                                  suggestedType: datasetItem.types[0].type,
                                  subtype: datasetItem.types[0].subtype,
                                })
                              );
                            }}
                          />
                        </ButtonsWrapper>
                      </TableHeading>
                    );
                  })}
              </HeaderRow>
            }

            {selectedDatasetContents.map((item: any, idx: number) => {
              return (
                <TableRowDataSet
                  key={idx}
                  ref={(el) => (rowsRef.current[idx] = el)}
                  style={{
                    height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                  }}
                >
                  {filteredItems
                    ?.filter((item) => !pinedColumns.includes(item.objectKey))
                    .map((header) => (
                      <TableRowCell $width={columnWidth}>
                        {item[header.objectKey] || "No data"}
                      </TableRowCell>
                    ))}
                </TableRowDataSet>
              );
            })}
          </Table>
        </TablesWrapper>
      </FileOverview>
      {showDimensionSettings && (
        <div ref={ref}>
          <DimensionSettings
            filePath={fileSelected.path}
            showDimensionSettings={showDimensionSettings}
            lastAction={lastAction}
            pinedColumns={pinedColumns}
            selectedDatasetContents={selectedDatasetContents}
            setShowDimensionSettings={setShowDimensionSettings}
            setLastAction={setLastAction}
            setPinedColumns={setPinedColumns}
            setSelectedDatasetContents={setSelectedDatasetContents}
            topSpace={25}
            edit
            isUploadedDataset
            onlyEdit={showDimensionSettings.edit}
          />
        </div>
      )}
    </ContentWrapper>
  );
};
