import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "react-bootstrap-icons";
import InfiniteScroll from "react-infinite-scroll-component";

import { requestGetAllDatasets } from "../../../../store/slices/datasets";
import { Loader } from "../../../../components/Loader";
import { getAllDatasets } from "../../../../store/selectors/datasets";
import { ResourcesList } from "../../../../components/ResourcesPagesComponents/PublicResources/ResourceList";
import { SearchDataById } from "./pages/SearchDataById";
import { SearchData } from "./pages/SearchData";
import { ResourcesNoData } from "../NoData";
import { ReactComponent as EmptyIcon } from "../../../../assets/resourcesPagesIcons/NoPublic.svg";
import { FileLibraryItem } from "../../../../models/Files";

interface Props { }

const PublicResources: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const allDatasets = useSelector(getAllDatasets);
  const [hasMore, setHasMore] = useState(true);
  const [datasets, setDatasets] = useState<FileLibraryItem[]>([]);
  const [refresh, setRefresh] = useState<number>(0);

  const getData = () => {
    dispatch(
      requestGetAllDatasets({
        limit,
        skip,
        orderBy: { createdAt: "DESC" },
        sourceType: 'provider',
        callbacks: {
          onSuccess: (data) => {
            const newItems = [...datasets, ...data.items];
            setDatasets(newItems);
            setHasMore(data.count > newItems?.length);
          },
        },
      })
    );
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh]);

  const fetchData = () => {
    setLimit(10);
    setSkip(skip + 10);
    setRefresh(refresh + 1);
  };

  return (
    <>
      {allDatasets.isLoading ? (<Loader />) : null}
      {
        !allDatasets.items.length ? (
          <ResourcesNoData
            Icon={EmptyIcon}
            title="No datasets were discovered"
            subTitle={`Start searching and adding datasets by pressing button below.`}
            IconButton={Search}
            name={"Search Public Data"}
            onClick={() => { }}
          />
        ) : (
          <InfiniteScroll
            dataLength={datasets.length}
            next={fetchData}
            hasMore={hasMore}
            loader={allDatasets.isLoading && <h4>Loading...</h4>}
            endMessage={null}
          >
            <ResourcesList items={datasets} />
          </InfiniteScroll>
        )
      }
    </>
  );
};

export { PublicResources as Index, SearchData, SearchDataById }
