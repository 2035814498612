import { ReactNode, useCallback, useEffect, useState, useRef } from "react";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import  * as s  from "./styles";

interface Item {
  image: string;
  component: ReactNode;
}

interface Props {
  items: Item[];
}

const Carousel = ({ items }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const handleThumbnailClick = (index: number): void => {
    setCurrentIndex(index);
    scrollToThumbnail(index);
  };

  const handlePrevClick = useCallback((): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    scrollToThumbnail(currentIndex - 1);
  }, [items.length, currentIndex]);

  const handleNextClick = useCallback((): void => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
    scrollToThumbnail(currentIndex + 1);
  }, [items.length, currentIndex]);

  const scrollToThumbnail = (index: number): void => {
    if (thumbnailsRef.current) {
      const thumbnail = thumbnailsRef.current.children[index];
      if (thumbnail) {
        thumbnail.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
      }
    }
  };


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.key === "ArrowLeft") {
        handlePrevClick();
      } else if (event.key === "ArrowRight") {
        handleNextClick();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handlePrevClick, handleNextClick]);

  return (
    <s.CarouselContainer>
      <s.MainSelectionContainer>
        <s.MainImage>{items[currentIndex]?.component}</s.MainImage>
        <s.NavigationButton direction="left" onClick={handlePrevClick}>
          <ArrowLeft size={24} color="#374151" />
        </s.NavigationButton>
        <s.NavigationButton direction="right" onClick={handleNextClick}>
          <ArrowRight size={24} color="#374151" />
        </s.NavigationButton>
      </s.MainSelectionContainer>

      <s.ThumbnailSection>
        <s.ThumbnailsContainer ref={thumbnailsRef}>
          {items.map((item, index) => (
            <s.Thumbnail
              key={index}
              src={item.image}
              alt={`Thumbnail ${index + 1}`}
              selected={index === currentIndex}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </s.ThumbnailsContainer>
      </s.ThumbnailSection>
    </s.CarouselContainer>
  );
};

export default Carousel;