import React from "react";
import SelectPalette from "./SelectPalette";
import { Colors, MapSettingsStyleFormatingColors } from "../styles";

type Props = {
  selectedFormatting?: string;
  currentColors: any;
  paletteIndex: number;
  handlePickColor: (color: number) => void;
  handleColorChangePalette: (
    state: "left" | "right",
    paletteId: string
  ) => void;
  type: "sequential" | "qualitative";
};

const ChartColorsSelect = ({
  selectedFormatting,
  currentColors,
  paletteIndex,
  handleColorChangePalette,
  handlePickColor,
  type,
}: Props) => {
  return (
    <>
      {type === "qualitative" ? (
        Array.isArray(currentColors) ? (
          <SelectPalette
            handlePickColor={(color: number) => handlePickColor(color)}
            colorsArray={currentColors}
            paletteIndex={paletteIndex}
            handleColorChangePalette={handleColorChangePalette}
            type={type}
          ></SelectPalette>
        ) : (
          <MapSettingsStyleFormatingColors>
            {currentColors.colors.map((item: any, index: number) => (
              <Colors
                key={index}
                $active={selectedFormatting === item.key}
                onClick={() => handlePickColor(item.key)}
                $color={item.hex}
                $left={currentColors.colors.length > 4 ? "-10px" : "0px"}
              />
            ))}
          </MapSettingsStyleFormatingColors>
        )
      ) : (
        <>
          <SelectPalette
            handlePickColor={(color: number) => handlePickColor(color)}
            colorsArray={currentColors}
            paletteIndex={paletteIndex}
            handleColorChangePalette={handleColorChangePalette}
            type={type}
          ></SelectPalette>
        </>
      )}
    </>
  );
};

export default ChartColorsSelect;
