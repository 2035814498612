import React, { useState } from "react";
import {
  CloseIcon,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  OverviewContent,
} from "../styles";

import {
  Authorization,
  ConnectAPIModal,
  FinishLoad,
  FinishLoadWrapper,
  Info,
  InfoText,
  LoaderText,
  LoaderWrapper,
  OverviewContentWrapper,
  OverviewLoaderWrapper,
  SelectAuthTypeWrapper,
} from "./styles";
import { Button } from "../../Button";
import { CheckCircleFill, InfoCircle } from "react-bootstrap-icons";
import { Formik } from "formik";
import { connectApiValidationSchema } from "./utils/validationSchema";
import { Select } from "../../Inputs/CustomSelect/Select";
import { AuthTypes, initialValues } from "./data/mock";
import { FormConnectAPIGroup } from "./components/FormGroup";
import NoAuth from "./components/NoAuth";
import BasicAuth from "./components/BasicAuth";
import BearerToken from "./components/BearerToken";
import JWTBearer from "./components/JWTBearer";
import DigestAuth from "./components/DigestAuth";
import LoaderDots from "./components/Loader";

type Props = {
  onClose: () => void;
};
const ConnectApiModal = ({ onClose }: Props) => {
  const [loadingApi, setLoadingApi] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleConnect = () => {
    setLoadingApi(true);

    setTimeout(() => setProgress(100), 3000);
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ConnectAPIModal onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>Connect API</ModalHeading>
          <CloseIcon onClick={onClose} />
        </ModalHeadingWrapper>

        <Formik
          initialValues={initialValues}
          validationSchema={connectApiValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => {
            // to do
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                <OverviewContent>
                  {loadingApi ? (
                    <OverviewLoaderWrapper>
                      {progress < 100 ? (
                        <>
                          <LoaderWrapper>
                            <LoaderDots></LoaderDots>
                          </LoaderWrapper>
                          <LoaderText>
                            <span>
                              Establishing a secure connection and retrieving
                              data.
                            </span>

                            <span>Please wait...</span>
                          </LoaderText>
                        </>
                      ) : (
                        <>
                          <FinishLoadWrapper>
                            <FinishLoad></FinishLoad>
                          </FinishLoadWrapper>
                          <LoaderText>
                            <span>
                              Connection has been established successfully.
                            </span>
                          </LoaderText>
                        </>
                      )}
                    </OverviewLoaderWrapper>
                  ) : (
                    <OverviewContentWrapper>
                      <SelectAuthTypeWrapper>
                        <FormConnectAPIGroup
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder={"Enter URL or paste text"}
                          type={"text"}
                          name={"url"}
                          values={values.url}
                          id={"url"}
                          errors={errors["url"]}
                        />
                        <Authorization>Authorization</Authorization>
                        <Select
                          label="Auth Type"
                          options={AuthTypes}
                          value={values.authType}
                          handleChange={(value) =>
                            setFieldValue("authType", value)
                          }
                        />
                        <Info>
                          <InfoCircle />
                          <InfoText>
                            The authorization header will be automatically
                            generated when you send the request.
                          </InfoText>
                        </Info>
                      </SelectAuthTypeWrapper>

                      {values.authType === "basicAuth" && (
                        <BasicAuth
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                        />
                      )}
                      {values.authType === "noAuth" && <NoAuth />}
                      {values.authType === "bearerToken" && (
                        <BearerToken
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                        />
                      )}
                      {values.authType === "JWTBearer" && (
                        <JWTBearer
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )}
                      {values.authType === "digestAuth" && (
                        <DigestAuth
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </OverviewContentWrapper>
                  )}
                </OverviewContent>

                <ModalFooterWrapper>
                  <Button
                    name="Cancel"
                    onClick={onClose}
                    variant="neutral"
                    size="medium"
                  />
                  <ModalFooterButtons>
                    <Button
                      name={!loadingApi ? "Connect" : "Done"}
                      onClick={handleConnect}
                      type="submit"
                      variant={
                        !loadingApi || progress === 100
                          ? "secondary"
                          : "disabled"
                      }
                      size="medium"
                      disabled={false}
                      icon={<CheckCircleFill />}
                    />
                  </ModalFooterButtons>
                </ModalFooterWrapper>
              </>
            );
          }}
        </Formik>
      </ConnectAPIModal>
    </ModalOverlay>
  );
};

export default ConnectApiModal;
